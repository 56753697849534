import React from 'react';
import { IonButton, IonInput, IonItem, IonList, IonIcon, IonAlert } from '@ionic/react';

import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import {
  Title,
  Spacer,
  FieldError,
  SmallText,
  NormalText,
  StrongText,
} from '../../components/common';
import { validateForm } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import {
  deleteReferredFriend,
  getReferredFriends,
  resendRefer,
  sendRefer,
} from '../../store/actions';
import SwipableTabs from '../../components/swipeableTabs';
import moment from '../../lib/moment';

import { trashOutline, personOutline } from 'ionicons/icons';
import './index.css';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';

class ReferAFriend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      formErrors: {},
      referredFriends: [],
      removeReferredFriendModal: false,
      refferedFriendId: null,
      isEmailValid: false,
    };
    this.formConfig = {
      email: { type: 'email', required: true },
    };
  }

  handleInput(key, val) {
    this.isEmailValid(val)
      ? this.setState({ [key]: val, isEmailValid: true })
      : this.setState({ [key]: val, isEmailValid: false });
  }
  componentDidMount() {
    const { dispatch, __ } = this.props;
    dispatch(getReferredFriends());
  }
  handleRefer = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { email } = this.state;
      const data = { email: email };
      this.setState({ email: '' });
      dispatch(sendRefer(data));
    }
  };

  getExpireDate = (unformattedDate) => {
    const date = moment(unformattedDate);
    const expireDate = date.add('days', 11);
    const currentDate = moment();
    const daysLeft = expireDate.diff(currentDate, 'DAYS');
    return daysLeft;
  };
  removeReferredFriend = () => {
    const data = this.state.refferedFriendId;
    const { dispatch } = this.props;
    dispatch(deleteReferredFriend(data));
  };
  resendRefer = (email) => {
    const { dispatch } = this.props;
    const data = { email };
    dispatch(resendRefer(data));
  };
  isEmailValid = (email) => {
    if (!email || email === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))
      return false;
    return true;
  };
  render() {
    const { __, profile } = this.props;
    const { email, isEmailValid } = this.state;
    const referredFriends = profile.referredFriends;
    return (
      <Loading>
        <Layout hideSecondToolbar={true} headerTitle={__('Refer and Earn')} color="transparent">
          <SwipableTabs
            tabs={[
              {
                label: __('REFER AND EARN'),
                tabContent: (
                  <>
                    <div className="scrollable-y refer-and-earn-wrapper">
                      <Title>{__('Refer and Earn')}</Title>
                      <NormalText>{__('You will be rewarded with ')}</NormalText>
                      <NormalText className="normal-text-bold">{`${
                        getConfig().general.referAndEarnPoints
                      } ${__('points ')}`}</NormalText>
                      <NormalText>
                        {__(
                          'for every £1 they spend. Make sure they sign up with the same email address to get rewarded.',
                        )}
                      </NormalText>
                      <Spacer size={2} />
                      <IonItem lines="none" className="input-field-wrapper">
                        <IonInput
                          className="refer-fiend-email"
                          onIonChange={(e) => this.handleInput('email', e.target.value)}
                          required
                          placeholder={__("Enter your friend's email")}
                          type="email"
                          pattern="email"
                          inputmode="email"
                          value={email}
                        ></IonInput>
                      </IonItem>
                      <FieldError className="field-error" value={__(this.state.formErrors.email)} />
                      <Spacer size={1} />
                      <IonButton
                        expand="block"
                        color="secondary"
                        className={`uppercase  send-invitation-btn${isEmailValid ? '-active' : ''}`}
                        strong={true}
                        onClick={() => this.handleRefer()}
                      >
                        {__('Send Invitation')}
                      </IonButton>
                    </div>
                  </>
                ),
              },
              {
                label: __('ACTIVITY'),
                tabContent: (
                  <>
                    <IonList class="refered-friends-list">
                      {referredFriends
                        .filter((ref) => this.getExpireDate(ref.date_referred) > 0 || ref.is_active)
                        .map((referredFriend) => {
                          return (
                            <IonItem className="box-wrapper" lines="none" id={referredFriend.id}>
                              <div>
                                <IonIcon color="primary" icon={personOutline}></IonIcon>
                                <div className="user-info-wrapper">
                                  <StrongText className="ellipsis">
                                    {referredFriend.email}
                                  </StrongText>
                                  {referredFriend.is_active ? (
                                    <SmallText className="refered-friend-success-message">
                                      {__('Active')}
                                    </SmallText>
                                  ) : (
                                    <SmallText>
                                      {__('Invite expires')}:{' '}
                                      {this.getExpireDate(referredFriend.date_referred)}{' '}
                                      {__('days')}
                                    </SmallText>
                                  )}
                                </div>
                                <IonButton
                                  className="resend-invite-btn uppercase"
                                  disabled={referredFriend.is_active}
                                  color="secondary"
                                  onClick={() => {
                                    this.resendRefer(referredFriend.email);
                                  }}
                                >
                                  {__('Resend invite')}
                                </IonButton>
                                <IonButton
                                  className="delete-refered-friend-btn"
                                  color="danger"
                                  // onClick={() => { this.deleteReferredFriend(referredFriend.id) }}
                                  onClick={() => {
                                    this.setState({
                                      removeReferredFriendModal: true,
                                      refferedFriendId: referredFriend.id,
                                    });
                                  }}
                                >
                                  <IonIcon icon={trashOutline} color="white"></IonIcon>
                                </IonButton>
                              </div>
                            </IonItem>
                          );
                        })}
                    </IonList>
                  </>
                ),
              },
            ]}
          />
        </Layout>
        {this.state.removeReferredFriendModal && (
          <IonAlert
            isOpen={true}
            onDidDismiss={() =>
              this.setState({ removeReferredFriendModal: false, refferedFriendId: null })
            }
            header={__('Confirm')}
            message={__('Do  you want to remove this referred friend?')}
            buttons={[
              {
                text: __('Cancel'),
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: __('Remove'),
                handler: () => this.removeReferredFriend(),
              },
            ]}
          />
        )}
      </Loading>
    );
  }
}
const mapStateToProps = (store) => {
  const profile = store.profile;

  return {
    profile,
  };
};
export default connect(mapStateToProps)(withTranslation(ReferAFriend));
