import React from 'react';
import { withRouter } from 'react-router';
import { IonButton, isPlatform } from '@ionic/react';
import { getConfig } from '../../appConfig';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import { addToWallet } from '../../store/actions';
const googleWalletIcon = require('../../assets/images/google-wallet-button.png');
const appleWalletIcon = require('../../assets/images/apple-wallet.png');

class AddToWallet extends React.Component {
  onClickHandler = () => {
    const { client, defaultGoogleLoyaltyObject } = getConfig();
    const { profile } = this.props;
    const loyaltyObject = {
      classId: client.client_id,
      ...defaultGoogleLoyaltyObject,
      email: this.props.profile.email,
      accountName: `${profile.first_name} ${profile.last_name}`,
      barcode: {
        ...defaultGoogleLoyaltyObject.barcode,
        type: 'QR_CODE',
        value: profile.qr_code,
      },
    };
    this.props.dispatch(addToWallet(loyaltyObject));
  };

  formatAppleWalletUrl = () => {
    const addToAppleWallet = getConfig().api.addToAppleWallet;
    const urlObject = {
      name: this.props.profile?.first_name,
      brand: this.props.clientProfile?.buisiness_name,
      qr: this.props.profile?.qr_code,
      bgColor: getConfig().passkitConfig?.bgColor || 'rgb(200,225,251)',
      color: getConfig().passkitConfig?.color || 'rgb(0,0,0)',
    };
    const urlArray = [];
    Object.keys(urlObject).forEach((key) => {
      if (!urlObject[key]) delete urlObject[key];
    });
    for (var p in urlObject)
      if (urlObject.hasOwnProperty(p)) {
        urlArray.push(`${encodeURIComponent(p)}=${encodeURIComponent(urlObject[p])}`);
      }
    return `${addToAppleWallet}?${urlArray.join('&')}`;
  };

  render() {
    const { hasAddToAppleWallet, hasAddToGoogleWallet } = getConfig().flags;
    if (hasAddToAppleWallet && isPlatform('ios')) {
      return (
        <a className="wallet-button" href={this.formatAppleWalletUrl()}>
          <img src={appleWalletIcon} />
        </a>
      );
    } else if (hasAddToGoogleWallet && isPlatform('android')) {
      return (
        <IonButton className="wallet-button" onClick={() => this.onClickHandler()}>
          <img src={googleWalletIcon} />
        </IonButton>
      );
    }
    return null;
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { clientProfile } = state.common;
  return {
    profile: profile,
    clientProfile: clientProfile,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(AddToWallet)));
