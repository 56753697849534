import { isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Account from './screens/account';
import Feedback from './screens/feedback';
import ReferAFriend from './screens/refer-a-friend';
import ReferAndEearn from './screens/referAndEarn';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
import ItemDetails from './screens/itemDetails';
import DeliveryOptions from './screens/delivery-options';
import Delivery from './screens/delivery';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import DeliveryTime from './screens/delivery-time';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import CreateAccount from './screens/createAccount';
// import AllergensInfo from './screens/allergensInfo'
import OrderToTable from './screens/orderToTable';
import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';
import { ServiceCharge } from './screens/service-charge';
import GiftVouchers from './screens/giftVouchers';
import api from './lib/api';
import ApplyLoyalty from './screens/applyLoyalty';
import MyMembership from './screens/myMembership';
import MembershipCompleted from './screens/membershipCompleted';
import CheckoutMembership from './screens/checkoutMembership';
import CardAddMembersip from './screens/cardAddMembership';
import ChooseSubscriptionMenu from './screens/chooseSubscriptionMenu';
import ChooseSubscription from './screens/chooseSubscription';
import TermsAndConditions from './screens/terms-subscription-package';
import PickUpPoint from './screens/pick-up-point';
import PickUpPointCheck from './screens/pick-up-point-check';
import PickUpPointTime from './screens/pick-up-point-time';

const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;

const home = require('./assets/images/home.svg');
const loyalty = require('./assets/images/loyalty.svg');
const myAccount = require('./assets/images/account.svg');
const feedback = require('./assets/images/feedback.svg');
const referAFriend = require('./assets/images/refer.svg');
const history = require('./assets/images/history.svg');
const locations = require('./assets/images/locations.svg');
const social = require('./assets/images/social-media.svg');
const login = require('./assets/images/login.svg');
const logout = require('./assets/images/logout.svg');
const settings = require('./assets/images/settings.svg');
const startNewOrder = require('./assets/images/order.svg');
const menu = require('./assets/images/menu.svg');
const terms = require('./assets/images/terms.svg');
const privacy = require('./assets/images/privacy.svg');
const qm = require('./assets/images/qm.svg');
const gift = require('./assets/images/gift.svg');
const subscription = require('./assets/images/club.svg');

let navConfig;

export const initRouter = async () => {
  const config = await api.getFrontEndAppConfig();
  const singleDeliveryOption = await getSingleDeliveryOption();
  const {
    hasReferAndEarn,
    hasReferAFriend,
    hasGiftVouchers,
    hasMembership,
    hasLoyalty,
    hasOrdering,
    defaultRoute,
  } = config.front_end_app_config.flags;
  navConfig = {
    routes: [
      isWebConfig()
        ? []
        : {
            label: 'Home',
            path: '/dashboard',
            component: Dashboard,
            icon: home,
            exact: true,
            default: true,
            notInDrawer: true,
          },
      ...(hasOrdering
        ? [
            {
              label: 'Menu',
              path: '/order',
              component: Order,
              icon: menu,
              exact: true,
              default: isWebConfig() && hasOrdering,
              group: 'button',
            },
          ]
        : []),

      {
        label: 'Apply Loyalty',
        path: '/apply-loyalty',
        component: ApplyLoyalty,
        icon: menu,
        protected: true,
        notInDrawer: true,
      },
      ...(hasOrdering ? [{ path: '/pick-up-point', component: PickUpPoint, icon: settings }] : []),
      ...(hasOrdering
        ? [{ path: '/pick-up-point-check', component: PickUpPointCheck, icon: settings }]
        : []),
      ...(hasOrdering
        ? [{ path: '/pick-up-time', component: PickUpPointTime, icon: settings }]
        : []),

      ...(hasOrdering
        ? [
            {
              label: singleDeliveryOption ? singleDeliveryOption.label : '',
              path: '/click-and-collect',
              component: ClickAndCollect,
              icon: settings,
              protected: false,
              notInDrawer: false,
            },
            {
              label: singleDeliveryOption ? singleDeliveryOption.label : '',
              path: '/click-and-collect-scheduled',
              component: ClickAndCollect,
              icon: settings,
              protected: false,
              notInDrawer: !singleDeliveryOption,
              scheduled: true,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Start New Order',
              path: '/delivery-options',
              component: DeliveryOptions,
              icon: startNewOrder,
              protected: false,
              notInDrawer: true,
            },
          ]
        : []),
      ...(hasLoyalty
        ? [
            {
              label: 'Loyalty',
              path: '/loyalty',
              component: Loyalty,
              icon: loyalty,
              protected: true,
              default: isWebConfig() && defaultRoute === 'loyalty',
              state: { tab: 'points' },
              group: 1,
            },
          ]
        : []),
      ...(hasMembership
        ? [
            {
              label: 'Membership',
              path: '/get-membership',
              component: ChooseSubscriptionMenu,
              icon: menu,
              protected: false,
              group: 1,
              default: isWebConfig() && defaultRoute === 'membership',
            },
            {
              label: 'Membership',
              path: '/membership',
              component: ChooseSubscription,
              icon: home,
              notInDrawer: true,
              protected: false,
            },
          ]
        : []),
      ...(hasReferAndEarn
        ? [
            {
              label: 'Refer And Earn',
              path: '/refer-and-earn',
              component: ReferAndEearn,
              icon: referAFriend,
              protected: true,
              group: 1,
            },
          ]
        : []),
      ...(hasReferAFriend
        ? [
            {
              label: 'Refer A Friend',
              path: '/refer-a-friend',
              component: ReferAFriend,
              icon: referAFriend,
              protected: true,
              group: 1,
            },
          ]
        : []),

      {
        label: 'TermsAndConditions',
        path: '/membership-terms',
        component: TermsAndConditions,
        notInDrawer: true,
        protected: false,
      },
      {
        label: 'Checkout Membership',
        path: '/checkout-membership',
        component: CheckoutMembership,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'MyMembership',
        path: '/my-membership',
        component: MyMembership,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'Membership Completed',
        path: '/membership-completed',
        component: MembershipCompleted,
        notInDrawer: true,
        protected: true,
      },
      {
        label: 'Add card Membership',
        path: '/add-card-membership',
        component: CardAddMembersip,
        notInDrawer: true,
        protected: true,
      },
      ...(hasOrdering
        ? [
            {
              label: 'Checkout',
              path: '/checkout',
              component: Checkout,
              notInDrawer: true,
              protected: false,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Apply Points',
              path: '/apply-points',
              component: ApplyPoints,
              notInDrawer: true,
              protected: true,
            },
          ]
        : []),
      ...(hasOrdering
        ? [
            {
              label: 'Service Charge',
              path: '/service-charge',
              component: ServiceCharge,
              notInDrawer: true,
            },
          ]
        : []),
      {
        label: 'My Account',
        path: '/account',
        component: Account,
        icon: myAccount,
        protected: true,
        group: 3,
      },
      ...(hasGiftVouchers
        ? [
            {
              label: 'eGift Vouchers',
              path: '/gift-vouchers',
              component: GiftVouchers,
              icon: gift,
              protected: false,
              default: isWebConfig() && defaultRoute === 'gift-vouchers',
              group: 2,
            },
          ]
        : []),
      ...(hasOrdering || hasLoyalty
        ? [
            {
              label: 'History',
              path: '/history',
              component: History,
              icon: history,
              protected: true,
              group: 3,
            },
          ]
        : []),

      {
        label: 'Leave Feedback',
        path: '/feedback',
        component: Feedback,
        icon: feedback,
        protected: true,
        group: 3,
      },
      {
        label: 'Locations',
        path: '/locations',
        component: Locations,
        icon: locations,
        protected: false,
        group: 4,
      },
      {
        label: 'Social Media',
        path: '/social',
        component: Social,
        icon: social,
        protected: false,
        group: 4,
      },
      {
        label: 'Contact Details',
        path: '/contact-details',
        component: ContactDetails,
        protected: false,
        notInDrawer: true,
      },
      {
        label: 'FAQ',
        path: '/faq',
        component: Faq,
        icon: qm,
        protected: false,
        notInDrawer: false,
        group: 4,
      },
    ],
    authRoutes: [
      { label: 'Login', path: '/login', component: Login, icon: logout, fn: 'login' },
      { label: 'Logout', path: '/logout', icon: login, fn: 'logout' },
    ],
    additionalRoutes: [
      { label: 'T&Cs', path: '/terms', component: Terms },
      { label: 'Privacy Policy', path: '/privacy', component: Privacy },
      // { label: 'FAQ', path: '/faq', component: Faq },
    ],
    notInMenuRoutes: [
      { path: '/register', component: Register },
      { path: '/create-account', component: CreateAccount },
      { path: '/reset-password', component: ResetPassword },
      ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
      ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
      ...(hasOrdering || hasGiftVouchers || hasMembership
        ? [{ path: '/cards', component: Cards }]
        : []),
      ...(hasOrdering || hasGiftVouchers || hasMembership
        ? [{ path: '/card-add', component: CardAdd }]
        : []),
      ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
      ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
      ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
      ...(hasOrdering ? [{ path: '/order-to-table', component: OrderToTable }] : []),
      ...(hasOrdering ? [{ path: '/delivery', component: Delivery }] : []),
      ...(hasOrdering
        ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }]
        : []),
      ...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
      ...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
    ],
  };

  return navConfig;
};

export default navConfig;
