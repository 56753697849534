import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Mobiscroll from '../../components/mobiscroll';
import Basket from '../../lib/basket';
import {
  Title,
  FieldError,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
} from '../../components/common';
import {
  IonButton,
  IonItem,
  IonInput,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonCheckbox,
} from '@ionic/react';
import {
  isDefined,
  checkForDeliveryOption,
  deepCopy,
  forwardTo,
  isObject,
  parseAllergenData,
  goBack,
  isWebConfig,
  getDefaultRoute,
} from '../../lib/utils';
import moment from '../../lib/moment';
import {
  setCommonModal,
  getRestaurants,
  getIkentooMenu,
  getIkentooMenusForLocation,
  setDeliveryOption,
  showToast,
} from '../../store/actions';
import { SET_RESTAURANT_PROP, SHOW_TOAST } from '../../store/constants';
import NoData from '../../components/noData';
import { formatDataForTime, getMenusForSelectedTime } from '../clickAndCollect';
import './index.css';
import { getConfig } from '../../appConfig';
import api from '../../lib/api';
import { filterMenu } from '../../store/restaurants/sagas';

const { SelectOption } = Mobiscroll;
const restaurantSelectPlaceholder = 'Select location';
const tableSelectPlaceholder = 'Select table';

class OrderToTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRestaurant:
        props.location.state && props.location.state.selectedRestaurant
          ? props.location.state.selectedRestaurant.restaurant_id
          : null,
      error: '',
      isLocationAllowed: false,
      tableNumber:
        props.location.state && props.location.state.selectedRestaurant
          ? props.location.state.selectedRestaurant.table_name
          : getConfig().flags.tableNumberPicker
          ? null
          : '',
      showTableNumber: true,
      totalTableNumbers: [],
      tableNumberPicker: getConfig().flags.tableNumberPicker || false,
      selectedRestaurantFull: null,
    };
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    if (Basket.getDeliveryOption()?.id == 'gift-vouchers') {
      Basket.reset();
    }
    this.position();
    if (searchParams.get('bl_id')) {
      const deliveryOptions = getConfig().delivery.filter(
        (deliveryOption) => deliveryOption.id == 'table',
      );
      this.props.dispatch(setDeliveryOption(deliveryOptions[0]));
      Basket.setDeliveryOption(deliveryOptions[0]);
      this.setState({ useSearchParams: true, showTableNumber: true });
    }
    const { restaurants, profile } = this.props;
    Basket.setOrderType('table');
    if (this.state.selectedRestaurant) {
      Basket.setRestaurant(
        restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant),
      );
      this.selectRestaurant(
        restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant).id,
      );

      Basket.setServicePercentage(
        this.props.location.state.selectedRestaurant.service_charge_percentage,
      );
      Basket.setCollectionTime(null);
      const tableNumbers = restaurants.find(
        (restaurant) => restaurant.id === this.state.selectedRestaurant,
      ).table_plan_json;
      this.setState({
        totalTableNumbers: tableNumbers,
      });
    } else if (searchParams.get('bl_id')) {
      const selectedRestaurant = this.props.restaurants.filter(
        (restaurant) => restaurant.business_location_id == searchParams.get('bl_id'),
      )[0];
      if (selectedRestaurant) {
        Basket.setRestaurant(selectedRestaurant);
        this.selectRestaurant(selectedRestaurant.id);
        Basket.setServicePercentage(selectedRestaurant.service_charge_percentage);
        Basket.setCollectionTime(null);
        const tableNumbers = restaurants.find(
          (restaurant) => restaurant.id === selectedRestaurant.id,
        ).table_plan_json;
        if (searchParams.get('t_id') && this.state.tableNumberPicker) {
          const tableNumberFromUrl = searchParams.get('t_id');
          const tableNumberValid = !!tableNumbers.find((number) => {
            if (number.value) {
              return number.value == tableNumberFromUrl;
            } else {
              return number.includes(tableNumberFromUrl);
            }
          });
          if (tableNumberValid) {
            this.setState({
              tableNumber: tableNumberFromUrl,
              totalTableNumbers: tableNumbers,
              pickTime: moment(Date.now()).format('HH:mm'),
            });
          } else {
            this.props.dispatch(showToast('Please select different table', 'warning'));
          }
        }
      } else {
        const defaultRoute = getDefaultRoute(this.props.navConfig);
        forwardTo(defaultRoute.path);
        this.props.dispatch(showToast('Restaurant you selected is not available', 'warning'));
      }
    } else {
      const filteredRestaurants = restaurants.filter(
        (store) =>
          isDefined(store.can_table_order) &&
          isDefined(store.is_published) &&
          store.can_table_order &&
          store.is_published,
      );
      if (filteredRestaurants.length > 0) {
        Basket.setRestaurant(filteredRestaurants[0]);
        this.selectRestaurant(filteredRestaurants[0].id);
      } else {
        this.setState({
          noTableRestaurants: true,
          selectedRestaurant: null,
          showTableNumber: false,
          tableNumber: null,
          tableNumber: null,
          totalTableNumbers: [],
        });
      }
    }
    checkForDeliveryOption(
      this.props.deliveryOption ? this.props.deliveryOption : Basket.getDeliveryOption(),
      '/order-to-table',
    ).then((deliveryOption) => {
      if (deliveryOption) {
        this.props.dispatch(getRestaurants());
        this.props.dispatch(setDeliveryOption(deliveryOption));
      }
      if (Basket.getMenu()) {
        this.setState({ selectedIkentooMenu: Basket.getMenu() });
      }
      const enabledRestaurants = restaurants.filter((store) => {
        if (isDefined(store.can_table_order) && isDefined(store.is_published)) {
          if (store.can_table_order && store.is_published) {
            return store;
          }
        }
      });
      if (enabledRestaurants.length === 1) {
        this.initValueOfSelectedRestaurant(enabledRestaurants, profile, deliveryOption);
      }
    });
  }

  componentDidUpdate(prevProps) {
    checkForDeliveryOption(
      this.props.deliveryOption ? this.props.deliveryOption : Basket.getDeliveryOption(),
      '/order-to-table',
    ).then(() => {
      if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
        if (this.props.ikentooMenusForLocation[0]) {
          this.setState({
            selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId,
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { restaurants } = this.props;
    if (
      this.state.selectedRestaurant !== prevState.selectedRestaurant &&
      this.state.selectedRestaurant
    ) {
      const selectedRestaurant = restaurants.find(
        (restaurant) => restaurant.id === this.state.selectedRestaurant,
      );

      const tableNumbers = selectedRestaurant.table_plan_json;
      this.setState({
        totalTableNumbers: tableNumbers,
      });
    }
  }

  initValueOfSelectedRestaurant = (restaurants, profile, deliveryOption) => {
    const stores = (restaurants || []).forEach((itm) => {
      if (itm?.id && itm?.name) {
        if (isDefined(itm.can_table_order) && isDefined(itm.is_published)) {
          if (itm.can_table_order && itm.is_published) {
            this.setState(
              { selectedRestaurant: itm.id, pickTime: moment(Date.now()).format('HH:mm') },
              () => {
                Basket.reset(profile?.cardToken);
                Basket.setRestaurant(restaurants.find((restaurant) => restaurant.id === itm.id));
                if (getConfig().general.hasServiceCharge) {
                  if (getConfig().flags.applyAutomaticServiceCharge) {
                    if (Basket.getRestaurantServiceCharge()) {
                      const service_charge_original = Basket.getRestaurantServiceCharge();
                      Basket.setServicePercentage(parseInt(service_charge_original[1] * 100));
                    } else {
                      const defaultServiceCharge = JSON.parse(
                        getConfig().general.defaultServiceCharge,
                      );
                      Basket.setServicePercentage(parseInt(defaultServiceCharge[1] * 100));
                    }
                  } else {
                    Basket.setServicePercentage(0);
                  }
                } else {
                  Basket.setServicePercentage(0);
                }
                Basket.setCollectionTime(null);
                Basket.setOrderType('table');
                Basket.setDeliveryOption(deliveryOption);
              },
            );
          }
        }
      }
      return null;
    });

    return stores;
  };

  selectRestaurant = (data) => {
    const { restaurants, profile } = this.props;
    const selectedRestaurantId = data;
    this.setState(
      {
        selectedRestaurant: selectedRestaurantId,
        pickTime: moment(Date.now()).format('HH:mm'),
        showTableNumber: true,
        selectedRestaurantFull: restaurants.find(
          (restaurant) => restaurant.id === selectedRestaurantId,
        ),
      },
      () => {
        Basket.reset(profile.cardToken);
        Basket.setRestaurant(
          restaurants.find((restaurant) => restaurant.id === selectedRestaurantId),
        );
        if (Basket.getRestaurant()) {
          if (getConfig().general.hasServiceCharge) {
            if (getConfig().flags.applyAutomaticServiceCharge) {
              if (Basket.getRestaurantServiceCharge()) {
                const service_charge_original = Basket.getRestaurantServiceCharge();
                Basket.setServicePercentage(parseInt(service_charge_original[1] * 100));
              } else {
                const defaultServiceCharge = JSON.parse(getConfig().general.defaultServiceCharge);
                Basket.setServicePercentage(parseInt(defaultServiceCharge[1] * 100));
              }
            } else {
              Basket.setServicePercentage(0);
            }
          } else {
            Basket.setServicePercentage(0);
          }
        }
        Basket.setCollectionTime(null);
        Basket.setDeliveryOption(this.props.deliveryOption);
        Basket.setOrderType('table');
      },
    );
  };

  selectTableNumber = (event, data) => {
    const tableId = data.getVal();
    this.setState({
      tableNumber: tableId,
    });
  };

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (
        isDefined(store.can_table_order) &&
        isDefined(store.is_published) &&
        store.can_table_order &&
        store.is_published
      ) {
        const currentDT = moment();
        let minDT = currentDT;
        if (store && isDefined(store.order_slot_lead_time)) {
          minDT.add(store.order_slot_lead_time, 'minutes');
        }
        const timeData = formatDataForTime(store, minDT, store.id, false, false, []);
        store.opened = timeData.length === 1 && timeData[0].text === 'closed' ? 'Closed' : 'Open';
        arrForSelect.push(store);
      }
    });
    return arrForSelect;
  };

  formatTableNumberForSelect = (selectedRestaurantId) => {
    const { __ } = this.props;
    let arrForSelect = [];
    if (selectedRestaurantId) {
      (this.state.totalTableNumbers || []).map((table, index) => {
        arrForSelect.push({ text: table, value: table });
      });
    }
    return [{ text: __(tableSelectPlaceholder), value: null }, ...arrForSelect];
  };

  getMenuForReorder = (menus, reorderItems) => {
    let menuForReorder = [];
    for (let i = 0; i < menus.length; i++) {
      let foundItems = 0;
      const categoryItems = menus[i].menuEntry || menus[i].menuEntryGroups || null;
      let items = Basket.flattenMenuItems(deepCopy(categoryItems));
      menus[i].flattenMenuItems = items;
      if (items.length > 0) {
        for (let j = 0; j < reorderItems.length; j++) {
          let foundItem = items.find((i) => i.sku === reorderItems[j].item.sku);
          if (foundItem) {
            foundItems = foundItems + 1;
          }
        }
      }
      menus[i].foundItems = foundItems;
      menuForReorder.push(menus[i]);
    }
    menuForReorder.sort((a, b) => b.foundItems - a.foundItems);
    if (menuForReorder[0] && menuForReorder[0].foundItems > 0) {
      return filterMenu(menuForReorder[0]);
    } else {
      return false;
    }
  };
  getKitchenStatusColor(status) {
    let color = '';
    switch (status) {
      case 0:
        color = 'success';
        break;
      case 1:
        color = 'warning';
        break;
      case 2:
        color = 'danger';
    }
    return color;
  }
  chooseMenusForLocation = async () => {
    const { __, restaurants, dispatch } = this.props;
    const { selectedRestaurant, tableNumber, pickTime } = this.state;
    this.setState({ continueButtonClicked: true }, async () => {
      if (selectedRestaurant && tableNumber && pickTime) {
        const choosenRestaurant = restaurants.find(
          (restaurant) => restaurant.id === selectedRestaurant,
        );
        const businessLocationId = choosenRestaurant.business_location_id;
        let cutoffTimeRes = moment().unix();
        Basket.setCutoffTime(cutoffTimeRes);
        Basket.setTableNumber(tableNumber);
        if (this.props.location.state && this.props.location.state.selectedRestaurant) {
          let ikentooMenusForLocation = await api.getIkentooMenusForLocation(businessLocationId);
          ikentooMenusForLocation = getMenusForSelectedTime(
            ikentooMenusForLocation,
            pickTime,
            choosenRestaurant.table_json_time_selector,
          );
          if (ikentooMenusForLocation && isObject(ikentooMenusForLocation[0])) {
            let ikentooMenus = [];
            try {
              for (let i = 0; i < ikentooMenusForLocation.length; i++) {
                ikentooMenus.push(
                  await api.getIkenooMenu(
                    ikentooMenusForLocation[i].ikentooMenuId,
                    businessLocationId,
                  ),
                );
              }
            } catch (error) {
              this.setState({ error: 'Get restaurant menu error.' });
              this.setState({ continueButtonClicked: false });
            }
            const reorderItems = this.props.location.state.selectedRestaurant.items;
            const menu = this.getMenuForReorder(ikentooMenus, reorderItems);
            if (menu) {
              dispatch({
                type: SET_RESTAURANT_PROP,
                key: 'ikentooMenu',
                value: menu,
              });
              if (menu.flattenMenuItems.length > 0) {
                let validationStatus = { notBasketEmpty: false, validationError: false };
                reorderItems.map((newBasketItem, index) => {
                  let foundItem = menu.flattenMenuItems.find(
                    (i) => i.sku === newBasketItem.item.sku,
                  );
                  if (foundItem && Basket.isProductEnabled(foundItem)) {
                    if (newBasketItem.selectedChoices[0]) {
                      newBasketItem.selectedChoices[0] = newBasketItem.selectedChoices[0].filter(
                        (el) => Basket.isProductEnabled(el),
                      );
                    }
                    newBasketItem.item = foundItem;
                    Basket.addToBasket(newBasketItem);
                    let item = newBasketItem.item;
                    let allergens = this.props.allergens;
                    let profile = this.props.profile;
                    let newArr = parseAllergenData(profile, item, allergens);
                    let allergensCodes =
                      newBasketItem.item?.itemRichData?.allergenCodes?.length > 0
                        ? newBasketItem.item.itemRichData.allergenCodes
                        : [];
                    if (allergensCodes.length > 0) {
                      let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
                      Basket.setAllergen(allergensData);
                    }
                    validationStatus.notBasketEmpty = true;
                  } else {
                    validationStatus.validationError = true;
                  }
                });
                if (validationStatus.notBasketEmpty) {
                  if (validationStatus.validationError) {
                    dispatch(
                      showToast(
                        'Some items were not added to your basket as they are currently unavailable',
                        'warning',
                      ),
                    );
                    this.setState({ continueButtonClicked: false });
                  }
                  forwardTo('/order-summary', { skipBackToThePreviousPage: false });
                  this.setState({ continueButtonClicked: false });
                } else {
                  dispatch(
                    showToast(
                      'Some items were not added to your basket as they are currently unavailable',
                      'warning',
                    ),
                  );
                  this.setState({ continueButtonClicked: false });
                }
              }
            } else {
              dispatch(showToast('Menu not found', 'warning'));
              this.setState({ continueButtonClicked: false });
            }
          } else {
            dispatch(showToast('Menu not found', 'warning'));
            this.setState({ continueButtonClicked: false });
          }
        } else {
          dispatch(
            getIkentooMenusForLocation(businessLocationId, {
              pickTime,
              json_time_selector: choosenRestaurant
                ? choosenRestaurant.table_json_time_selector
                : [],
            }),
          );
          this.setState({ continueButtonClicked: false });
        }
      } else if (!selectedRestaurant) {
        this.setState({ error: __('Please select location') });
        this.setState({ continueButtonClicked: false });
      } else {
        this.setState({ error: __('Please enter table number') });
        this.setState({ continueButtonClicked: false });
      }
    });
  };

  position = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) =>
        this.setState({
          isLocationAllowed: true,
        }),
      (err) => console.log(err),
    );
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });

  continueOnMenu = () => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant } = this.state;
    if (selectedRestaurant && selectedIkentooMenu) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };
  backHandler = () => {
    if (!this.state.showTableNumber) {
      this.setState({ showTableNumber: true });
    } else {
      goBack();
    }
  };
  render() {
    const {
      __,
      deliveryOption,
      restaurants,
      isChooseMenuModalOpen,
      ikentooMenusForLocation,
    } = this.props;
    const {
      error,
      selectedIkentooMenu,
      pickTime,
      selectedRestaurant,
      showTableNumber,
    } = this.state;
    const stores = restaurants || [];
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const store = restaurants.find((restaurant) => restaurant.id === selectedRestaurant) || null;
    const menus = getMenusForSelectedTime(
      ikentooMenusForLocation,
      pickTime,
      store ? store.table_json_time_selector : [],
    );
    return (
      <Loading transparent>
        <Layout
          headerWithTitle={true}
          backHandler={this.backHandler}
          headerTitle={__(deliveryOption ? deliveryOption.label : '')}
          color="transparent"
        >
          <div className="flex-row-wrapper absolute-content">
            {!showTableNumber && (
              <>
                <div className="click-and-collect-title">
                  <Title className="web-only">{__('Order To Table')}</Title>
                  <NormalText>{__('Select a restaurant to collect your order')}.</NormalText>
                </div>
                <div className="click-and-collect-locations">
                  <IonList className="box-wrapper">
                    {this.formatDataForSelect(stores).map((store) => (
                      <div className="click-collect-items">
                        <IonItem
                          disabled={store.opened === 'Closed'}
                          onClick={() => this.setState({ selectedRestaurant: store.id })}
                          lines="none"
                          className=" default-padding"
                        >
                          <IonLabel className="break-space">
                            <StrongText>{__(store.name)}</StrongText>
                            <SmallText className="break-space block">{__(store.address)}</SmallText>
                            <SmallText
                              className="bold"
                              color={this.getKitchenStatusColor(store.kitchen_status.status)}
                            >
                              {store.kitchen_status.text}
                            </SmallText>
                          </IonLabel>
                          <IonCheckbox
                            checked={selectedRestaurant && store.id === selectedRestaurant}
                            slot="start"
                            color="secondary"
                          />
                        </IonItem>
                      </div>
                    ))}
                  </IonList>
                </div>
                <div className="click-and-collect-button-wrapper">
                  <IonButton
                    disabled={!selectedRestaurant}
                    expand="block"
                    color="secondary"
                    onClick={() => this.selectRestaurant(selectedRestaurant)}
                  >
                    {__('Continue')}
                  </IonButton>
                </div>
              </>
            )}
            {showTableNumber && (
              <div>
                <div className="click-and-collect-title">
                  <Title className="web-only">
                    {deliveryOption ? `${__(deliveryOption.label)}` : ''}
                  </Title>
                  <NormalText className="delivery-option-description">
                    {__('Confirm your location and table.')}
                  </NormalText>
                </div>
                <div className="order-to-table-locations">
                  <IonList className="shadowed-box-light no-padding">
                    <IonItem
                      className="unclicked box-wrapper default-padding"
                      key={selectedRestaurant}
                      lines="none"
                    >
                      <div tabIndex="-1"></div>
                      <IonCheckbox color="secondary" slot="start" checked />
                      <IonLabel className="break-space" color="dark">
                        <StrongText>
                          {this.state.selectedRestaurantFull
                            ? this.state.selectedRestaurantFull.name
                            : Basket.getRestaurant()?.name}
                        </StrongText>
                        <SmallText className="break-space block">
                          {this.state.selectedRestaurantFull
                            ? this.state.selectedRestaurantFull.address
                            : Basket.getRestaurant()?.address}
                        </SmallText>
                        <SmallText
                          className="bold"
                          color={this.getKitchenStatusColor(
                            this.state.selectedRestaurantFull
                              ? this.state.selectedRestaurantFull.kitchen_status.status
                              : Basket.getRestaurant()?.kitchen_status.status,
                          )}
                        >
                          {this.state.selectedRestaurantFull
                            ? this.state.selectedRestaurantFull.kitchen_status.text
                            : Basket.getRestaurant()?.kitchen_status.text}
                        </SmallText>
                      </IonLabel>
                    </IonItem>
                  </IonList>
                  <IonButton
                    expand="block"
                    fill="clear"
                    color="secondary"
                    className="link underlined"
                    onClick={() =>
                      this.setState({
                        selectedRestaurant: null,
                        showTableNumber: false,
                        tableNumber: null,
                        tableNumber: null,
                        totalTableNumbers: [],
                      })
                    }
                  >
                    {__('At a different location?')}
                  </IonButton>

                  <div className="table-picker-wrapper">
                    <StrongText
                      className="time-picker-label okx-font-secondary"
                      htmlFor="demo-non-form"
                    >
                      {__('Table Number')}
                    </StrongText>
                    {this.state.tableNumberPicker ? (
                      <div className="box-wrapper time-picker-wrapper click-and-collect-timepicker-wrapper">
                        <SelectOption
                          display="inline"
                          label="Table Number"
                          inputStyle="box"
                          setText={__('OK')}
                          cancelText={__('Cancel')}
                          data={this.formatTableNumberForSelect(this.state.selectedRestaurant)}
                          value={this.state.tableNumber}
                          onSet={(e, a) => this.selectTableNumber(e, a)}
                          disabled={(this.state.totalTableNumbers || []).length <= 1 ? true : false}
                        />
                      </div>
                    ) : (
                      <>
                        <IonItem lines="none" className="input-field-wrapper">
                          <IonInput
                            className="table-number-input"
                            onIonChange={(e) => this.setState({ tableNumber: e.target.value })}
                            value={this.state.tableNumber}
                            type="text"
                            placeholder={__('Enter table number')}
                          />
                        </IonItem>
                        {error ? (
                          <IonItem>
                            <div tabIndex="-1"></div>
                            <FieldError className="field-error" value={__(error)} />
                          </IonItem>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
                <Spacer size={1} />

                <div className="click-and-collect-button-wrapper">
                  <IonButton
                    disabled={this.state.tableNumber ? false : true}
                    expand="block"
                    color="secondary"
                    className={this.state.continueButtonClicked ? 'unclicked' : ''}
                    onClick={() => this.chooseMenusForLocation()}
                  >
                    {__('Continue')}
                  </IonButton>
                </div>
              </div>
            )}
          </div>

          {!menus.length ? null : (
            <>
              <div
                className="click-collect-pickers-backdrop"
                style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
                onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
              ></div>
              <div className={`click-collect-dialog ${animationMenuClass}`}>
                {/* <Loading transparent> {null} </Loading> */}
                <div className="click-collect-dialog-layout sc-ion-modal-md">
                  <div className="click-collect-dialog-header">
                    <Title className="centered">{__('Choose menu')}</Title>
                  </div>
                  <div
                    className="click-collect-dialog-closer"
                    style={{ position: 'absolute', right: 0, top: 0 }}
                    onClick={() =>
                      this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))
                    }
                  >
                    <ion-icon
                      name="close"
                      role="img"
                      className="md hydrated"
                      aria-label="close"
                    ></ion-icon>
                  </div>
                  <div className="click-collect-dialog-content">
                    <IonList className="box-wrapper" lines="none">
                      <IonRadioGroup
                        onIonChange={this.changeIkentooMenus}
                        value={selectedIkentooMenu}
                      >
                        {menus.length === 0 ? (
                          <NoData />
                        ) : (
                          menus.map((menu) => {
                            const { ikentooMenuId, menuName } = menu;
                            return (
                              <IonItem key={ikentooMenuId} lines="none">
                                <IonLabel className="ion-text-wrap" color="dark">
                                  {menuName}
                                </IonLabel>
                                <IonRadio
                                  color={isWebConfig() ? 'secondary' : 'white'}
                                  slot="start"
                                  value={ikentooMenuId}
                                />
                              </IonItem>
                            );
                          })
                        )}
                      </IonRadioGroup>
                    </IonList>
                  </div>
                  <div className="click-collect-dialog-action">
                    {error ? (
                      <IonItem>
                        <div tabIndex="-1"></div>
                        <FieldError className="field-error" value={__(error)} />
                      </IonItem>
                    ) : null}
                    <IonButton
                      disabled={pickTime && menus.length > 0 ? false : true}
                      expand="block"
                      color="secondary"
                      className="uppercase okx-font-secondary"
                      onClick={() => this.continueOnMenu()}
                    >
                      {__('Continue')}
                    </IonButton>
                  </div>
                </div>
              </div>
            </>
          )}
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { deliveryOption } = state.orders;
  const { restaurants, ikentooMenusForLocation } = state.restaurants;
  const { isChooseMenuModalOpen, navConfig } = state.common;
  return {
    navConfig,
    deliveryOption,
    restaurants: restaurants || [],
    profile: state.profile.profile,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    ikentooMenusForLocation: ikentooMenusForLocation || [],
  };
};

export default connect(stateToProps)(withTranslation(OrderToTable));
