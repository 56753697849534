import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { IonButton, IonIcon } from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { checkmarkCircle, closeCircleSharp } from 'ionicons/icons';
import './index.css';
import { Title, NormalText } from '../../components/common';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import { Capacitor } from '@capacitor/core';
import { loading } from '../../store/actions';

const isWeb = () => Capacitor.platform === 'web';

const MembershipCompleted = ({ __, isMenuFlow, navConfig }) => {
  const handleMembership = () => {
    forwardTo('/my-membership');
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loading(false));
  }, []);

  const handleCheckout = () => {
    const path = isWeb ? '/order' : '/order-summary';
    forwardTo(path);
  };

  const handleHome = () => {
    const path = getDefaultRoute(navConfig).path;
    forwardTo(path);
  };

  return !window.location.href.includes('canceled=true') ? (
    <Loading transparent>
      <Layout hideSecondToolbar={true} color="transparent" headerTitle={__('Membership Completed')}>
        <div className="absolute-content flex-row-wrapper">
          <div className="scrollable-y ion-text-center">
            <IonIcon
              expand="block"
              color="success"
              icon={checkmarkCircle}
              className="success-icon"
            />
            <Title>{__('Success')}</Title>
            <NormalText tag="div" className="description">
              {__('Thanks for joining the Club!')}
            </NormalText>
          </div>
          <div className="flex-min">
            {isMenuFlow ? (
              <IonButton
                color="secondary"
                expand="block"
                className={'checkout-btn '}
                onClick={handleMembership}
              >
                {__('View My Membership')}
              </IonButton>
            ) : (
              <IonButton
                color="secondary"
                expand="block"
                className={'checkout-btn '}
                onClick={handleHome}
              >
                {__('Back to Checkout')}
              </IonButton>
            )}
          </div>
        </div>
      </Layout>
    </Loading>
  ) : (
    <Loading transparent>
      <Layout hideSecondToolbar={true} color="transparent" headerTitle={__('Membership Canceled')}>
        <div className="absolute-content flex-row-wrapper">
          <div className="scrollable-y ion-text-center">
            <IonIcon
              expand="block"
              color="danger"
              icon={closeCircleSharp}
              className="success-icon"
            />
            <Title>{__('Payment Canceled')}</Title>
          </div>
          <div className="flex-min">
            <IonButton
              color="secondary"
              expand="block"
              className={'checkout-btn '}
              onClick={handleHome}
            >
              {__('Go back')}
            </IonButton>
          </div>
        </div>
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  isMenuFlow: store.subscription.flow === 'menu_flow',
  navConfig: store.common.navConfig,
});

export default connect(mapStateToProps)(withTranslation(MembershipCompleted));
