import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonList, IonItem, IonTextarea } from '@ionic/react';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer, NormalText, StrongText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { validateForm, forwardTo } from '../../lib/utils';
import { addDeliveryAddress, storeDeliveryAddress } from '../../store/actions';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';

import './index.css';

const { getDeliveryAddress, getDeliveryPrice, getMinOrder } = Basket;

class DeliveryAddressAdd extends React.Component {
  state = {
    form: {
      addressLine1: getDeliveryAddress().addressLine1 || '',
      addressLine2: getDeliveryAddress().addressLine2 || '',
      place: getDeliveryAddress().place || '',
      postalCode: getDeliveryAddress().postalCode || '',
      notes: '',
      orderType: Basket.getOrderType().toLowerCase(),
    },
    formErrors: {},
  };
  formConfig = {};

  handleInput = (key, val) => {
    const form = {
      ...this.state.form,
      [key]: val,
      orderType: this.state.orderType,
    };
    this.setState({
      form,
      formErrors: validateForm(this.formConfig, form),
    });
  };
  save = () => {
    const { auth, profile } = this.props;
    const orderType = Basket.getOrderType();
    if (auth && auth.loggedIn) {
      let found = !!(orderType === 'charter-delivery'
        ? profile.charter_delivery_address_list
          ? profile.charter_delivery_address_list
          : []
        : profile.address_list
        ? profile.address_list
        : []
      ).find(
        (al) =>
          this.state.form.addressLine1 + this.state.form.place + this.state.form.postalCode ===
          al.addressLine1 + al.place + al.postalCode,
      );
      if (!found) {
        this.props.dispatch(
          addDeliveryAddress({
            ...this.state.form,
            orderType: Basket.getOrderType().toLowerCase(),
          }),
        );
      }
      Basket.setDeliveryAddress(this.state.form);
    } else {
      this.props.dispatch(storeDeliveryAddress(this.state.form));
      Basket.setDeliveryAddress(this.state.form);
    }
    forwardTo('/delivery-time', {
      selectedRestaurant: this.props.location?.state?.selectedRestaurant,
    });
  };

  componentDidMount() {
    this.setState({
      formErrors: validateForm(this.formConfig, this.state.form),
    });
  }

  render() {
    const { __ } = this.props;
    const { form } = this.state;
    const deliveryAddress = getDeliveryAddress();
    const deliveryPrice = getDeliveryPrice();
    const minOrder = getMinOrder();
    const restaurantPostalCode =
      Basket.getRestaurant() !== null
        ? Basket.getRestaurant().postcode
        : deliveryAddress.postalCode;
    return (
      <Loading transparent>
        <Layout headerWithTitle={true} headerTitle={__('Confirm address')} color="transparent">
          <div className="flex-row-wrapper absolute-content">
            <div className="flex-min">
              <Title className="web-only">{__('Confirm address')}</Title>
              <NormalText>{__('Please confirm your full address')}</NormalText>

              <div className="input-field-two-columns-wrapper input-field-wrapper">
                <div>
                  <StrongText className="block">{__('Address 1')}</StrongText>
                </div>
                <div>
                  <NormalText>{deliveryAddress.addressLine1} </NormalText>
                </div>
              </div>
              {deliveryAddress.addressLine2 && (
                <div className="input-field-two-columns-wrapper input-field-wrapper">
                  <div>
                    <StrongText className="block">{__('Address 2')}</StrongText>
                  </div>
                  <div>
                    <NormalText>{deliveryAddress.addressLine2} </NormalText>
                  </div>
                </div>
              )}

              <div className="input-field-two-columns-wrapper input-field-wrapper">
                <div>
                  <StrongText className="block">{__('Town')}</StrongText>
                </div>
                <div>
                  <NormalText>{deliveryAddress.place} </NormalText>
                </div>
              </div>
              <div className="input-field-two-columns-wrapper input-field-wrapper">
                <div>
                  <StrongText className="block">{__('Postcode')}</StrongText>
                </div>
                <div>
                  <NormalText>{deliveryAddress.postalCode} </NormalText>
                </div>
              </div>
              <Spacer size={1} />

              <div className="input-field-container">
                <NormalText>{__('Driver Notes')}</NormalText>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonTextarea
                    value={form.notes}
                    onIonChange={(e) => this.handleInput('notes', e.target.value)}
                    type="text"
                    clearInput
                  />
                </IonItem>
              </div>
            </div>

            <div className="flex-min">
              <Spacer size={1} />
              <IonButton expand="block" color="secondary" onClick={this.save}>
                {__('Continue ')}
              </IonButton>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile, auth } = state.profile;
  const { deliveryAddress, storedDeliveryAddress } = state.orders;
  return {
    profile,
    auth,
    deliveryAddress,
    storedDeliveryAddress,
    isLoggedIn: profile.auth && profile.auth.loggedIn,
  };
};

export default connect(stateToProps)(withTranslation(DeliveryAddressAdd));
