import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonItem, IonLabel, IonList, IonRadioGroup, IonRadio } from '@ionic/react';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer, NormalText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import moment from '../../lib/moment';
import {
  forwardToDeliveryOption,
  isEmptyObject,
  isObject,
  isArray,
  cutoffTime,
  isDefined,
  parseAllergenData,
  forwardTo,
  deepCopy,
  isWebConfig,
  groupBy,
} from '../../lib/utils';
import { setDeliveryTime, setDeliveryOption, loading } from '../../store/actions';
import { setCommonModal, showToast } from '../../store/common/actions';
import {
  getIkentooMenu,
  getIkentooMenusForLocation,
  passedTheOrder,
} from '../../store/restaurants/actions';
import Mobiscroll from '../../components/mobiscroll';
import Basket from '../../lib/basket';
import Loading from '../../components/spinner';
import NoData from '../../components/noData';
import {
  formatDataForTime,
  getMenusForSelectedTime,
  checkSnoozedTimes,
} from '../../screens/clickAndCollect';
import api from '../../lib/api';
import mobiscroll from '@mobiscroll/react';

import './index.css';
import { SET_COMMON_MODAL, SET_RESTAURANT_PROP } from '../../store/constants';
import { filterMenu } from '../../store/restaurants/sagas';

const { SelectOption } = Mobiscroll;
//const customTime = '2020-06-09T05:59:00.000'

class PickUpPointTime extends React.Component {
  pickerRef = React.createRef();
  pickerRefCharter = React.createRef();
  state = {
    selectedTime: null,
    pickTime: null,
    formatted: null,
    selectedTimeOption: null,
  };

  checkDelivery = () => {
    if (!Basket.getDeliveryOption() || (!Basket.getDeliveryAddress() && !Basket.getPickUpPoint())) {
      forwardToDeliveryOption();
    }
  };

  getMenus = () => {
    this.props.dispatch(
      getIkentooMenusForLocation(
        Basket.getRestaurant() ? Basket.getRestaurant().business_location_id : null,
        {},
        true,
      ),
    );
  };

  componentDidMount() {
    this.checkDelivery();
    this.props.dispatch(
      getIkentooMenusForLocation(
        Basket.getRestaurant() ? Basket.getRestaurant().business_location_id : null,
        {},
        true,
      ),
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.deliveryOption !== prevProps.deliveryOption) {
      this.getMenus();
    }
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState({ selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId });
      }
    }
    if (this.props.isChooseMenuModalOpen) {
      this.props.dispatch(loading(false));
    }
  }
  confirmTime = () => {
    const { selectedTime } = this.state;
    if (selectedTime) {
      // this.props.dispatch(setDeliveryTime(selectedTime));
      Basket.setCollectionTime(selectedTime.formattedDT);
      let restaurant = Basket.getRestaurant();
      let slots = restaurant?.delivery_times_json?.slots;
      let cutoffTimeRes = cutoffTime(selectedTime.formattedDT, slots);
      Basket.setCutoffTime(cutoffTimeRes);
      this.continueOnMenu();
    }
  };
  changeIkentooMenus = (event) => {
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' });
    Basket.setMenu(event.detail.value);
  };
  formatDayName = (name, key) => {
    if (name.includes('Today')) {
      name = 'Today';
    } else if (name.includes('Tomorrow')) {
      name = 'Tomorrow';
    } else {
      name = key;
    }
    return name;
  };

  formatTimes = (restaurant) => {
    let deliveryTimesJson;
    const disabledDates = null;
    if (!restaurant) {
      deliveryTimesJson = null;
    } else {
      deliveryTimesJson = restaurant.delivery_times_json;
    }
    // if (orderType === 'charter-delivery') {
    //   return this.formatCharterDeliveryTimes(deliveryTimesJson, restaurant);
    // }
    const formatSelectOptionDays = [];
    const daysAhead = deliveryTimesJson ? deliveryTimesJson.days_ahead : null;

    let picker = [];
    if (deliveryTimesJson) {
      Array(daysAhead)
        .fill('')
        .forEach((day, i) => {
          let formatDay = moment().add(i, 'days');
          let formatDayName = formatDay.format('dddd');
          formatSelectOptionDays.push({ formatDayName, formattedDT: formatDay });
        });
      formatSelectOptionDays.forEach((option, i) => {
        const formatedOptionDate = option.formattedDT.format('DD-MM-YYYY');
        let daySlot = [];
        if (isDefined(disabledDates) && disabledDates.includes(formatedOptionDate)) {
          daySlot = [];
        } else {
          daySlot = deliveryTimesJson.slots[option.formatDayName];
        }
        daySlot.forEach((slot) => {
          let h = parseInt(slot.start_time.split(':')[0]);
          let m = parseInt(slot.start_time.split(':')[1]);
          let prepTime = moment().add(slot.prep_time, 'hours').add(m, 'minutes');
          const formattedDT = moment(option.formattedDT.hours(h).minutes(m));
          if (prepTime.isBefore(formattedDT)) {
            picker.push({
              formattedDT: formattedDT,
              value: formattedDT.toDate(),
              date: formattedDT.format('ddd, DD MMM'),
              time: formattedDT.format('h:mm A'),
              menuIds: slot.menu_ids || null,
            });
          }
        });
      });
      const formattedTreeDataSource = this.formatTimesForTree(picker);
      return formattedTreeDataSource;
    }
    return [];
  };

  formatTimesForTree = (times) => {
    const groupByMonth = groupBy(['date']);
    const grouped = groupByMonth(times);
    return grouped;
  };

  getMenuForReorder = (menus, reorderItems) => {
    let menuForReorder = [];
    for (let i = 0; i < menus.length; i++) {
      let foundItems = 0;
      const categoryItems = menus[i].menuEntry || menus[i].menuEntryGroups || null;
      let items = Basket.flattenMenuItems(deepCopy(categoryItems));
      menus[i].flattenMenuItems = items;
      if (items.length > 0) {
        for (let j = 0; j < reorderItems.length; j++) {
          let foundItem = items.find((i) => i.sku === reorderItems[j].item.sku);
          if (foundItem) {
            foundItems = foundItems + 1;
          }
        }
      }
      menus[i].foundItems = foundItems;
      menuForReorder.push(menus[i]);
    }
    menuForReorder.sort((a, b) => b.foundItems - a.foundItems);
    if (menuForReorder[0] && menuForReorder[0].foundItems > 0) {
      return filterMenu(menuForReorder[0]);
    } else {
      return false;
    }
  };

  continueOnMenu = async () => {
    const { dispatch, __ } = this.props;
    dispatch(loading(true));
    const { selectedIkentooMenu, selectedTime } = this.state;
    const { ikentooMenusForLocation } = this.props;
    if (Basket.getRestaurant() && selectedIkentooMenu) {
      const choosenRestaurant = Basket.getRestaurant();
      const businessLocationId = choosenRestaurant.business_location_id;
      Basket.setCollectionTime(selectedTime.formattedDT);
      if (this.props.location?.state?.selectedRestaurant?.items.length > 0) {
        let ikentooMenusForLocation = await api.getIkentooMenusForLocation(businessLocationId);

        if (ikentooMenusForLocation && isObject(ikentooMenusForLocation[0])) {
          let ikentooMenus = [];
          try {
            for (let i = 0; i < ikentooMenusForLocation.length; i++) {
              ikentooMenus.push(
                await api.getIkenooMenu(
                  ikentooMenusForLocation[i].ikentooMenuId,
                  businessLocationId,
                ),
              );
            }
          } catch (error) {
            this.setState({ error: 'Get restaurant menu error.' });
          }
          const reorderItems = this.props.location.state.selectedRestaurant.items;
          const menu = this.getMenuForReorder(ikentooMenus, reorderItems);
          if (menu) {
            dispatch({
              type: SET_RESTAURANT_PROP,
              key: 'ikentooMenu',
              value: menu,
            });
            Basket.setMenu(menu.menu_id);
            if (menu.flattenMenuItems.length > 0) {
              let validationStatus = { notBasketEmpty: false, validationError: false };
              reorderItems.map((newBasketItem, index) => {
                let foundItem = menu.flattenMenuItems.find((i) => i.sku === newBasketItem.item.sku);
                if (foundItem && Basket.isProductEnabled(foundItem)) {
                  if (newBasketItem.selectedChoices[0]) {
                    newBasketItem.selectedChoices[0] = newBasketItem.selectedChoices[0].filter(
                      (el) => Basket.isProductEnabled(el),
                    );
                  }
                  newBasketItem.item = foundItem;
                  Basket.addToBasket(newBasketItem);
                  let item = newBasketItem.item;
                  let allergens = this.props.allergens;
                  let profile = this.props.profile;
                  let newArr = parseAllergenData(profile, item, allergens);
                  let allergensCodes =
                    newBasketItem.item?.itemRichData?.allergenCodes?.length > 0
                      ? newBasketItem.item.itemRichData.allergenCodes
                      : [];
                  if (allergensCodes.length > 0) {
                    let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
                    Basket.setAllergen(allergensData);
                  }
                  validationStatus.notBasketEmpty = true;
                } else {
                  validationStatus.validationError = true;
                }
              });
              if (validationStatus.notBasketEmpty) {
                if (validationStatus.validationError) {
                  dispatch(
                    showToast(
                      'Some items were not added to your basket as they are currently unavailable',
                      'warning',
                    ),
                  );
                }
                forwardTo('/order-summary', { skipBackToThePreviousPage: false });
              } else {
                dispatch(
                  showToast(
                    'Some items were not added to your basket as they are currently unavailable',
                    'warning',
                  ),
                );
              }
            }
          } else {
            dispatch(showToast('Menu not found', 'warning'));
          }
        }
      } else {
        const menus = this.getMenusForDeliverySelectedTime(ikentooMenusForLocation, selectedTime);
        if (menus.length == 0) {
          dispatch(showToast(__('Get restaurant menu error.'), 'warning'));
        } else if (menus.length === 1) {
          Basket.setMenu(menus[0].ikentooMenuId);
          dispatch(getIkentooMenu(menus[0].ikentooMenuId, businessLocationId));
        } else {
          dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
        }
      }
    } else {
      dispatch(showToast('Please select location menu', 'warning'));
      this.setState({ error: 'Please select location menu' });
    }
    dispatch(loading(false));
  };
  getMenusForDeliverySelectedTime = (menusForLocation = [], selectedTime) => {
    if (isObject(menusForLocation) && !isArray(menusForLocation)) {
      menusForLocation = [menusForLocation];
    }
    return menusForLocation.filter((menu) => {
      const ikentooMenuId = menu.ikentooMenuId;
      if (!isEmptyObject(selectedTime)) {
        if (selectedTime !== 'asap') {
          const target_menu = selectedTime.menuIds.find((i) => i === ikentooMenuId);
          if (target_menu) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    });
  };

  setTime = (e, deliverytimes, isPicker) => {
    const { deliveryOption } = this.props;
    const charterDelivery = deliveryOption
      ? deliveryOption.id === 'charter-delivery'
        ? true
        : false
      : false;
    const findPickedDT = isPicker ? deliverytimes.find((dt) => e.valueText === dt.text) : e;
    if (charterDelivery) {
      this.setState({ selectedTime: findPickedDT });
    } else {
      let restaurant = Basket.getRestaurant();
      let slots = restaurant?.delivery_times_json?.slots;
      let cutoffTimeRes = cutoffTime(findPickedDT.formattedDT, slots);
      this.setState({ selectedTime: findPickedDT }, () => {
        if (findPickedDT.menuIds.length > 1) {
          this.props.dispatch(setCommonModal('isChooseMenuModalOpen', true));
        } else if (findPickedDT.menuIds.length === 1) {
          this.setState({ selectedIkentooMenu: findPickedDT.menuIds[0] });
        }
      });
      Basket.setCutoffTime(cutoffTimeRes);
    }
  };

  changeTime = (selectedTime, minDT) => {
    if (selectedTime && minDT) {
      let h = parseInt(selectedTime.split(':')[0]);
      let m = parseInt(selectedTime.split(':')[1]);
      const formattedDT = moment(minDT).hours(h).minutes(m);
      this.setState({ pickTime: selectedTime, selectedTime, formatted: formattedDT }, () => {
        Basket.setCollectionTime(formattedDT);
        Basket.setOrderType('charter-delivery');
      });
    } else {
      this.setState({ pickTime: null });
    }
  };

  setPickTime = (inst, minDT) => {
    if (inst && inst.getVal()) {
      this.changeTime(inst.getVal(), minDT);
    } else {
      this.setState({ pickTime: null });
    }
  };

  changeTimeOption = (option, timePickerOptions, minDT) => {
    const time = option.value === 1 ? 'asap' : timePickerOptions[0]?.value;
    this.setState({ selectedTimeOption: option, pickTime: time, selectedTime: time });
    this.changeTime(time, minDT);
  };
  parseSelectedTime(dataSource, selected) {
    const [selectedDateIndex, selectedTimeIndex] = selected.split(' ');
    const selectedDate = Object.keys(dataSource)[selectedDateIndex];
    const selectedTime = dataSource[selectedDate][selectedTimeIndex];
    this.setState({ selectedTime: selectedTime });
  }
  render() {
    const { __, deliveryOption, isChooseMenuModalOpen, ikentooMenusForLocation } = this.props;
    const { selectedTime, selectedIkentooMenu } = this.state;
    // const snoozedTimes = checkSnoozedTimes(restaurant, 'charter-delivery');
    const restaurant = Basket.getRestaurant();
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const timePickerTimes = this.formatTimes(restaurant);
    const menus = this.getMenusForDeliverySelectedTime(ikentooMenusForLocation, selectedTime);

    console.log({ menus });

    return (
      <Loading>
        <Layout headerTitle={__('Pick Up Point')} headerWithTitle={true} color="transparent">
          <div className="flex-row-wrapper absolute-content">
            <Title>{__('Select Drop-off Time')}</Title>
            <NormalText>{__('Select your preferred drop-off time')}</NormalText>
            <Spacer />
            <div className="time-picker-wrapper box-wrapper click-and-collect-timepicker-wrapper">
              <mobiscroll.Treelist
                display="inline"
                layout="liquid"
                showInput={false}
                circular={false}
                onChange={(e) => this.parseSelectedTime(timePickerTimes, e.valueText)}
                onInit={() => this.parseSelectedTime(timePickerTimes, '0 0')}
              >
                {Object.keys(timePickerTimes).map((date, i) => (
                  <li key={i}>
                    {date}
                    <ul>
                      {timePickerTimes[date].map((time, j) => (
                        <li key={j}>{time.time}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </mobiscroll.Treelist>
            </div>
            <Spacer />

            <div className="flex-min">
              <IonButton
                disabled={!selectedTime}
                expand="block"
                color="secondary"
                className="uppercase"
                onClick={() =>
                  menus.length > 1 && !this.props.location?.state?.selectedRestaurant
                    ? this.props.dispatch({
                        type: SET_COMMON_MODAL,
                        modal: 'isChooseMenuModalOpen',
                        value: true,
                      })
                    : this.confirmTime()
                }
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
          <div
            className="click-collect-pickers-backdrop"
            style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
            onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
          ></div>
          <div className={`click-collect-dialog ${animationMenuClass}`}>
            <Loading> {null} </Loading>
            <div className="click-collect-dialog-layout sc-ion-modal-md">
              <div className="click-collect-dialog-header">
                <Title className="centered">{__('Choose menu')}</Title>
              </div>
              <div
                className="click-collect-dialog-closer"
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
              >
                <ion-icon name="close" role="img" class="md hydrated" aria-label="close"></ion-icon>
              </div>
              <div className="click-collect-dialog-content">
                <IonList className="box-wrapper" lines="none">
                  <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                    {menus.length === 0 ? (
                      <NoData />
                    ) : (
                      menus.map((menu) => {
                        const { ikentooMenuId, menuName } = menu;
                        return (
                          <IonItem key={ikentooMenuId}>
                            <div tabIndex="-1"></div>
                            <IonRadio
                              color={isWebConfig() ? 'secondary' : 'white'}
                              slot="start"
                              value={ikentooMenuId}
                            />
                            <IonLabel className="ion-text-wrap" color="dark">
                              {menuName}
                            </IonLabel>
                          </IonItem>
                        );
                      })
                    )}
                  </IonRadioGroup>
                </IonList>
              </div>
              <div className="click-collect-dialog-action">
                <IonButton
                  disabled={menus.length > 0 ? false : true}
                  expand="block"
                  color="secondary"
                  onClick={() => this.confirmTime()}
                >
                  {__('Next')}
                </IonButton>
              </div>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { deliveryOption, deliveryAddress, pickUpPoint } = state.orders;
  const { isChooseMenuModalOpen } = state.common;
  const { ikentooMenusForLocation, restaurants } = state.restaurants;
  return {
    deliveryOption,
    deliveryAddress,
    pickUpPoint,
    isChooseMenuModalOpen,
    ikentooMenusForLocation,
    restaurants,
  };
};

export default connect(stateToProps)(withTranslation(PickUpPointTime));
