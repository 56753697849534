import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonTextarea } from '@ionic/react';
import { withRouter } from 'react-router';
import {
  checkmarkCircle,
  checkmarkCircleOutline,
  chevronForwardOutline,
  pencilOutline,
} from 'ionicons/icons';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import ValidateButton from '../../components/validateButton';
import Modal from '../../components/modal';
import Incrementer from '../../components/incrementer';
import { PillGroup } from '../../components/pill';
import {
  Title,
  SmallText,
  Sectiontitle,
  Spacer,
  NormalText,
  StrongText,
  Subtitle,
  FieldError,
} from '../../components/common';
import OrderContent from '../../components/orderContent';
import { ApplyPoints } from '../../screens/applyPoints';
import {
  forwardTo,
  validateProfileData,
  goBack,
  go,
  isEmptyObject,
  isWebConfig,
  getDefaultRoute,
} from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import moment from '../../lib/moment';
import {
  setModal,
  setProtectedReferrer,
  setOrderTypeMembership,
  setOrderTypeRegular,
  setOrderSubscriptionFlow,
  setSelectedSubscription,
  getSubscriptions,
  restoreAuth,
} from '../../store/actions';
import './index.css';
import Mobiscroll from '../../components/mobiscroll';

const { SelectOption } = Mobiscroll;
const {
  getRestauranName,
  getOrderDate,
  getOrderTime,
  changeItemQuantity,
  itemsCount,
  getItems,
  setMobile,
  getMobile,
  getAllergen,
  getTotal,
  isMinimumOrderTotalSatisfied,
  getTableNumber,
  getPickUpPoint,
  getDeliveryAddress,
  getDeliveryOption,
  getAppliedVocuher,
  getASAP,
} = Basket;

class OrderSummaryRaw extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      quantityModal: null,
      allergenModalOpen: false,
      applyPointsModalOpen: false,
      mobile: getMobile() || '',
      applyLoyaltyModalOpen: false,
      tableNumberModalOpen: false,
      tableNumberValue: Basket.getTableNumber(),
      customFieldValue: Basket.getCustomFieldValue()?.field_value || '',
    };
  }
  selectTableNumber = (event, data) => {
    const tableId = data.getVal();
    this.setState({
      tableNumberValue: tableId,
    });
  };
  componentDidUpdate(prevProps) {
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
  }
  componentDidMount() {
    const { selectedSubscription, subscriptions, profile } = this.props;
    const basketInstance = this.props.basketInstance || Basket;
    if (profile?.active_subscription?.discount_rate > 0) {
      if (selectedSubscription?.idx >= 0 && profile.active_subscription.id) {
        const active_subscription = subscriptions[selectedSubscription?.idx];
        if (active_subscription && active_subscription.id && this.props.isCollection) {
          Basket.setDiscount(active_subscription, 'AS');
        }
      } else if (profile.active_subscription && profile.active_subscription.id) {
        const index = subscriptions.findIndex((e) => e.id === profile.active_subscription.id);
        this.props.setSelectedSubscription({ id: profile.active_subscription.id, idx: index });
        basketInstance.setSubscription(profile.active_subscription);
      }
    }
  }
  handleInput = (key, val) => {
    this.setState({ [key]: val });
    setMobile(val);
  };
  tableNumberChanged = () => {
    if (this.state.tableNumberValue != '') {
      Basket.setTableNumber(this.state.tableNumberValue);
    }
    this.setState({ tableNumberModalOpen: false });
  };
  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else {
      goBack();
    }
  };

  handleSubmit = () => {
    const { cards } = this.props;
    if (!isMinimumOrderTotalSatisfied()) {
      // display toast with flag 'true'
      isMinimumOrderTotalSatisfied(true);
      return;
    }
    Basket.setCustomFieldValue({
      field_value: this.state.customFieldValue,
      field_name: this.state.customFieldName,
    });
    if (getTotal() === 0) {
      Basket.createOrder();
      return;
    }
    if (cards && cards.length >= 1) {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        forwardTo('/checkout', {
          giftVoucher: Basket.getDeliveryOption().id == 'gift-vouchers' ? true : false,
        });
        setMobile(this.props.profile.mobile);
      } else {
        forwardTo('/contact-details');
      }
    } else {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        setMobile(this.props.profile.mobile);
        forwardTo('/checkout', {
          giftVoucher: Basket.getDeliveryOption().id == 'gift-vouchers' ? true : false,
        });
      } else {
        forwardTo('/contact-details');
      }
    }
  };

  handleOrderItemClick = (item, index) => {
    this.setState({ quantityModal: { item, index } });
  };

  updateItemQuantity = () => {
    const { item, index } = this.state.quantityModal;
    if (this.state.quantityModal) {
      changeItemQuantity(index, item.quantity);
      this.setState({ quantityModal: null });
    }
  };

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantityModal: {
        ...this.state.quantityModal,
        item: {
          ...this.state.quantityModal,
          quantity: newQuantity,
        },
      },
    });
  };

  formatDayName = (name) => {
    if (name.includes('Today')) {
      name = 'Today'.toLowerCase();
    } else if (name.includes('Tomorrow')) {
      name = 'Tomorrow'.toLowerCase();
    } else {
      name = getOrderDate();
    }
    return name;
  };
  isRedeemPointsDisabled = () => {
    const { redeemPointsMin } = getConfig().general;
    const { available_balance } = this.props.profile;
    return available_balance < redeemPointsMin ? true : false;
  };

  findSelectedSlot = (dayInWeek, time) => {
    const restaurant = Basket.getRestaurant();
    const selectedDay =
      !isEmptyObject(restaurant) &&
      restaurant.delivery_times_json &&
      !isEmptyObject(restaurant.delivery_times_json) &&
      restaurant.delivery_times_json.slots &&
      !isEmptyObject(restaurant.delivery_times_json.slots)
        ? restaurant.delivery_times_json.slots[dayInWeek]
        : null;
    if (selectedDay) {
      const selectedSlot = selectedDay.find(
        (day) =>
          moment(day.start_time, 'HH:mm').format('HH:mm') ===
          moment(time, 'HH:mm a').format('HH:mm'),
      );
      return (
        moment(selectedSlot.start_time, 'HH:mm').format('h:mm a') +
        ' - ' +
        moment(selectedSlot.end_time, 'HH:mm').format('h:mm a')
      );
    }
    return null;
  };

  drawContentHeader = (__, deliveryOption, orderType, deliveryAddress) => {
    let dateName = this.formatDayName(
      moment(getOrderDate(), 'dddd Do MMMM').calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
      }),
    );
    if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
      return (
        <>
          <Title>{__(deliveryOption.label)}</Title>
          <NormalText>
            {__('Ordering for')} {__('drop-off')} {__('at')} {getRestauranName()} {dateName}{' '}
            {__('at')} {getASAP() ? 'ASAP' : getOrderTime()}
            <br />
          </NormalText>
        </>
      );
    } else if (deliveryOption.id === 'charter-delivery' && !isEmptyObject(deliveryAddress)) {
      return (
        <>
          <Title>{__(deliveryOption.label)}</Title>
          <NormalText>
            {__('Ordering for')} {__('delivery')} {__('to')} {deliveryAddress.addressLine1},{' '}
            {deliveryAddress.place} {deliveryAddress.postalCode} {dateName} {__('at')}{' '}
            {getASAP() ? 'ASAP' : getOrderTime()}
            <br />
          </NormalText>
        </>
      );
    } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') {
      return (
        <>
          <Title>{__(deliveryOption.label)}</Title>

          <div className="order-summary-header-wrapper">
            <div>
              <StrongText className="block">{__('Table order at')}</StrongText>
              <NormalText>{getRestauranName()}</NormalText>
            </div>
            <div
              className="pointer"
              onClick={() => {
                this.setState({ tableNumberModalOpen: true });
              }}
            >
              <StrongText> {getTableNumber()} </StrongText>
            </div>
          </div>
        </>
      );
    } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'scheduled-collection') {
      return (
        <div className="box-content order-header-content">
          <div>
            <StrongText className="block">{__('Collection from')}</StrongText>
            <NormalText>{getRestauranName()}</NormalText>
          </div>
          <StrongText className="bold">
            {dateName} {'at'} {Basket.getOrderTime('h:mm a')}
          </StrongText>
        </div>
      );
    } else {
      return (
        <>
          <Title>{__(orderType)}</Title>
          <div className="order-summary-header-wrapper">
            <div>
              <StrongText className="block">{__('Collection from')}</StrongText>
              <NormalText>{getRestauranName()}</NormalText>
            </div>
            <div>
              <StrongText> {getASAP() ? 'ASAP' : getOrderTime('h:mm a')}</StrongText>
            </div>
          </div>
        </>
      );
    }
  };

  handleApplyModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      // this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      if (isWebConfig()) {
        this.setState({ applyPointsModalOpen: flag });
      } else {
        forwardTo('/apply-points');
      }
    }
  };
  handleApplyLoyaltyModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      forwardTo('/apply-loyalty');
    }
  };
  formatTableNumberForSelect = (selectedRestaurantId) => {
    const { __ } = this.props;
    let arrForSelect = [];
    if (selectedRestaurantId) {
      (this.state.totalTableNumbers || []).map((table, index) => {
        arrForSelect.push({ text: table, value: table });
      });
    }
    return [{ text: __('Select table'), value: null }, ...arrForSelect];
  };
  getCustomFieldForOrderType = () => {
    const custom_field = Basket.getRestaurant()?.custom_field;
    let result = false;
    switch (Basket.order_type) {
      case 'collection':
        result = custom_field?.['collection'];
        break;
      case 'scheduled-collection':
        result = custom_field?.['scheduled-collection'];
        break;
      case 'table':
        result = custom_field?.['table'];
        break;
      case 'charter-delivery':
        result = custom_field?.['charter-delivery'];
        break;
      case 'pick-up-at-counter':
        result = custom_field?.['pick_up_at_counter'];
        break;
    }
    if (isEmptyObject(result)) {
      return false;
    } else {
      return result;
    }
  };
  render() {
    const { __, profile, auth, dispatch, history } = this.props;
    const { quantityModal, allergenModalOpen, applyPointsModalOpen, mobile } = this.state;
    const valid = validateProfileData(profile).isValid;
    const allergens = getAllergen() || [];
    const isAuth = auth.loggedIn;
    const appliedVouchers = Basket.getAppliedVocuher();
    const contactLabelArrg = isWebConfig() ? { position: 'floating' } : { slot: 'start' };

    const labelTitle =
      Basket.getOrderType() === 'Click & Collect' ? 'Click & Collect Order' : Basket.getOrderType();
    const currentPath = history.location.pathname;
    const showAddItems = currentPath === '/order-summary';
    const customFieldData = this.getCustomFieldForOrderType();
    return (
      <>
        <div className="absolute-content flex-row-wrapper summary-wrapper">
          <div className="scrollable-y checkout">
            {Basket.getDeliveryOption().id != 'gift-vouchers' ? (
              <>
                {this.drawContentHeader(
                  __,
                  Basket.getDeliveryOption(),
                  Basket.getOrderType(),
                  Basket.getDeliveryAddress(),
                )}
                <Spacer size={1} />
                <div className="input-field-container order-summary-mobile">
                  <NormalText>{__('Contact Number For This Order')}</NormalText>
                  <IonItem lines="none" className="input-field-wrapper">
                    <IonInput
                      onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                      clearInput
                      required={false}
                      type="tel"
                      pattern="tel"
                      inputmode="tel"
                      value={mobile}
                    ></IonInput>
                  </IonItem>
                </div>
                <Spacer size={1} />
                {customFieldData && (
                  <>
                    <div className="input-field-container ">
                      <NormalText>{__(customFieldData.field_name)}</NormalText>
                      <IonItem lines="none" className="input-field-wrapper">
                        <IonInput
                          value={this.state.customFieldValue}
                          onIonChange={(e) =>
                            this.setState({
                              customFieldValue: e.target.value,
                              customFieldName: customFieldData.field_name,
                            })
                          }
                        ></IonInput>
                      </IonItem>
                    </div>
                    {this.state.customFieldError && (
                      <FieldError className="field-error" value={__('This field is required')} />
                    )}
                  </>
                )}
                {itemsCount() > 0 ? (
                  <OrderContent
                    showAddItems={showAddItems}
                    handleOrderItemClick={this.handleOrderItemClick.bind(this)}
                  />
                ) : (
                  <IonItem lines="none">
                    <div className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>
                      {__('No items')}
                    </div>
                  </IonItem>
                )}
                <Spacer size={1} />
                <div className="order-summary-additional-buttons-wrapper">
                  {Basket.getDeliveryOption().id !== 'gift-vouchers' && (
                    <>
                      <IonButton
                        onClick={() => {
                          !isAuth
                            ? forwardTo('/login')
                            : itemsCount() === 0 || !valid
                            ? dispatch(setModal('isVerfiedModalOpen', true))
                            : this.handleApplyLoyaltyModal(true);
                        }}
                        className={`${
                          itemsCount() === 0 || !valid ? 'disabled' : ''
                        } order-summary-additional-button`}
                        expand="block"
                        color="primary"
                        fill="clear"
                      >
                        <NormalText>{__('Redeem Loyalty Rewards')}</NormalText>
                        <IonIcon icon={chevronForwardOutline}></IonIcon>
                      </IonButton>
                    </>
                  )}
                  <>
                    {allergens.length > 0 ? (
                      <IonButton
                        fill="clear"
                        expand="block"
                        className="order-summary-additional-button"
                        color="dark"
                        onClick={() => this.setState({ allergenModalOpen: true })}
                      >
                        <NormalText> {__('View allergen information')}</NormalText>
                        <IonIcon icon={chevronForwardOutline}></IonIcon>
                      </IonButton>
                    ) : null}
                  </>
                </div>
                {/* {allergens.length > 0 ? (
                  <IonButton
                    className="order-summary-additional-button"
                    expand="block"
                    color="primary"
                    fill="clear"
                    onClick={() => this.setState({ allergenModalOpen: true })}
                  >
                    <NormalText> {__('View allergen information')}</NormalText>
                    <IonIcon icon={chevronForwardOutline}></IonIcon>
                  </IonButton>
                ) : null} */}

                {/* {!auth.loggedIn ? (
                  <IonButton
                    style={{ fontSize: '13px' }}
                    className="uppercase login-btn"
                    expand="block"
                    fill="outline"
                    color="secondary"
                    onClick={() => forwardTo('/login')}
                  >
                    {__('Already registered? Login')}
                  </IonButton>
                ) : (
                  <IonButton
                    onClick={() => {
                      itemsCount() == 0 || !valid
                        ? dispatch(setModal('isVerfiedModalOpen', true))
                        : this.handleApplyLoyaltyModal(true);
                    }}
                    className={`${
                      itemsCount() == 0 || !valid ? 'disabled' : ''
                    } order-summary-additional-button`}
                    expand="block"
                    color="primary"
                    fill="clear"
                  >
                    <NormalText>{__('Redeem Loyalty Rewards')}</NormalText>
                    <IonIcon icon={chevronForwardOutline}></IonIcon>
                  </IonButton>
                )} */}
              </>
            ) : (
              <>
                <Title>{__('eGift Voucher Summary')}</Title>

                <div className="box-content gift-voucher-order-content">
                  <IonIcon icon={checkmarkCircle}></IonIcon>

                  <div className="gift-voucher-order-content-summary">
                    <Subtitle className="bold">
                      {Basket.formatPrice(this.props.giftVoucherData?.points_value / 100)}{' '}
                      {__('eGift Voucher')}
                    </Subtitle>
                    <NormalText>
                      {__('Recipient')}
                      {': '}
                      {this.props.giftVoucherData?.recipient_email}
                    </NormalText>
                    {this.props.giftVoucherData?.send_on_date && (
                      <NormalText className="block">
                        {__('Send date')}
                        {': '}
                        {this.props.giftVoucherData?.send_on_date}
                      </NormalText>
                    )}
                  </div>
                </div>
                <Spacer />

                <OrderContent showAddItems={showAddItems} />
              </>
            )}
          </div>

          <div className="flex-min">
            <IonButton
              disabled={itemsCount() === 0}
              onClick={() => {
                if (
                  customFieldData &&
                  customFieldData.is_required &&
                  this.state.customFieldValue == ''
                ) {
                  this.setState({ customFieldError: true });
                } else {
                  this.handleSubmit();
                }
              }}
              expand="block"
              color="secondary"
              className={
                'checkout-btn ' +
                (!isMinimumOrderTotalSatisfied() ? 'greyed' : '') +
                ' uppercase okx-font-secondary'
              }
            >
              {__('Checkout')}
            </IonButton>
          </div>
        </div>
        <Modal
          cssClass="table-number-modal"
          isOpen={this.state.tableNumberModalOpen}
          onDidDismiss={() =>
            this.setState({
              tableNumberModalOpen: false,
              tableNumberValue: Basket.getTableNumber(),
            })
          }
        >
          <Subtitle className="centered">{__('Change Table Number')}</Subtitle>
          <Spacer size={1} />
          <IonItem
            className={`${
              getConfig().flags.tableNumberPicker ? 'dropdown-field' : ''
            } input-field-wrapper`}
            lines="none"
          >
            {getConfig().flags.tableNumberPicker ? (
              <SelectOption
                display="center"
                label="Table Number"
                inputStyle="box"
                setText={__('OK')}
                cancelText={__('Cancel')}
                data={this.formatTableNumberForSelect(Basket.getRestaurant())}
                value={this.state.tableNumberValue}
                onSet={(e, a) => this.selectTableNumber(e, a)}
                disabled={(this.state.totalTableNumbers || []).length <= 1 ? true : false}
              />
            ) : (
              <>
                <IonInput
                  className="table-number-input"
                  onIonChange={(event) => {
                    this.setState({ tableNumberValue: event.target.value });
                  }}
                  value={this.state.tableNumberValue}
                  type="text"
                  placeholder={__('Enter table number')}
                />
              </>
            )}
          </IonItem>
          <Spacer size={1} />
          <IonButton
            color="secondary"
            expand="block"
            className="uppercase"
            onClick={() => {
              this.tableNumberChanged();
            }}
          >
            {' '}
            {__('Confirm')}
          </IonButton>
        </Modal>
        <Modal
          cssClass="quantity-modal full-screen-modal"
          // title={__('Change quantity')}
          action={this.updateItemQuantity}
          actionLabel={__('Change')}
          isOpen={!!quantityModal}
          onDidDismiss={() => this.setState({ quantityModal: null })}
        >
          <Title>{__('Change quantity')}</Title>
          <Spacer />
          {quantityModal && quantityModal.item ? (
            <Incrementer
              allowNegative={false}
              quantity={quantityModal.item.quantity}
              onUpdate={this.onIncrementerUpdate}
            />
          ) : null}
          <Spacer />
        </Modal>
        <Modal
          cssClass="allergen-modal full-screen-modal"
          isOpen={allergenModalOpen}
          onDidDismiss={() => this.setState({ allergenModalOpen: false })}
        >
          <div className="flex-row-wrapper">
            <div className="scrollable-y">
              <Title>{__('Allergen Information')}</Title>
              <Spacer />
              {getItems().map((item, index) => {
                let data = allergens.find((allergen) => allergen[1].sku === item.item.sku);
                if (data && data.length > 0) {
                  return (
                    <div key={'allergen-' + index}>
                      <Sectiontitle>
                        {item.quantity}x {item.item.productName}
                      </Sectiontitle>
                      <PillGroup items={data[0].allergens} borderColor="primary" />
                      <Spacer />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </Modal>
        <Modal
          cssClass="apply-points-modal"
          isOpen={applyPointsModalOpen}
          onDidDismiss={() => this.handleApplyModal(false)}
        >
          <ApplyPoints
            handleApplyModal={this.handleApplyModal}
            applyPointsModalOpen={applyPointsModalOpen}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { basketUpdated, deliveryOption, selectedSubscription } = store.orders;
  const { auth } = store.profile;
  return {
    basketUpdated,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    deliveryOption,
    itemAllergens: store.restaurants.itemAllergens,
    auth,
    isCollection: deliveryOption !== null && deliveryOption.id === 'collection',
    selectedSubscription,
    subscriptions: store.subscription.subscriptions,
  };
};

const mapDispatchToProps = {
  setOrderTypeMembership,
  setOrderTypeRegular,
  setOrderSubscriptionFlow,
  setSelectedSubscription,
  getSubscriptions,
};

export const OrderSummary = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation(OrderSummaryRaw)));
class OrderSummaryWrapped extends React.Component {
  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipBackToThePreviousPage
    ) {
      forwardTo('/order');
    } else {
      goBack();
    }
  };
  render() {
    const { __ } = this.props;
    return (
      <Loading transparent>
        <Layout
          color="transparent"
          headerTitle={__('Order Summary')}
          backHandler={this.backHandler}
          scrollY={false}
        >
          <OrderSummary />
        </Layout>
      </Loading>
    );
  }
}

export default withTranslation(OrderSummaryWrapped);
