import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Scan from './scan';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import {
  getProfile,
  getRewards,
  getTransactionHistory,
  getVouchers,
  sendVoucherCode,
  setModal,
} from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import './index.css';
import SwipableTabs from '../../components/swipeableTabs';
import {
  FieldError,
  NormalText,
  SmallText,
  Spacer,
  StrongText,
  Subtitle,
  Title,
} from '../../components/common';
import {
  isDefined,
  isEmptyObject,
  isWebConfig,
  validateForm,
  validateProfileData,
} from '../../lib/utils';
import { IonCol, IonIcon, IonRow, IonButton, IonInput, IonItem } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import Basket from '../../lib/basket';
import moment from 'moment';
import QRCode from 'qrcode-react';
import Modal from '../../components/modal';
import RedeemGiftVoucher from '../../components/redeemGiftVoucher';
import api from '../../lib/api';
import { getConfig } from '../../appConfig';
import AddToWallet from '../../components/addToWallet';
const loyaltyBg = require('../../assets/images/loyalty-overlay.svg');

const VoucherItem = ({ reward, id, className, __, action }) => {
  return (
    <div
      style={{ backgroundImage: `url(${reward.image})` }}
      className={'voucher' + (className ? ' ' + className : '')}
      onClick={action ? () => action(id) : null}
    ></div>
  );
};
export const getLabel = (item) => {
  let label = '';
  const defaultDescriptors = [
    {
      bl_id: '-1',
      label: 'Referral Bonus',
    },
    {
      bl_id: '-2',
      label: 'Sign up Bonus',
    },
    {
      bl_id: '-3',
      label: 'Refunded points',
    },
  ];
  const historyDescriptors = getConfig().history_descriptors || defaultDescriptors;

  const descriptors = historyDescriptors.filter((el) => el.bl_id == item.business_location_id);
  if (descriptors.length > 0) {
    label = descriptors[0].label;
  } else {
    let isPoints = item.is_points;
    let isStamps = item.is_stamps;
    let subLabel = '';
    if (item.stamp_power == 1) {
      subLabel = `${isPoints ? 'Point' : isStamps ? 'Stamp' : ''}`;
    } else if (item.stamp_power !== 1) {
      subLabel = `${isPoints ? 'Points' : isStamps ? 'Stamps' : ''}`;
    }
    if (item.stamp_power < 0) {
      label = `Redeemed ${subLabel}`;
    } else if (item.stamp_power > 0) {
      label = `Earned ${subLabel}`;
    }
  }
  return label;
};
class Loyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      loyaltyFaqModalOpened: false,
      voucherCode: '',
      selectedVoucher: null,
      formErrors: {
        voucherCode: '',
      },
      stampCards: [],
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  async componentDidMount() {
    this.props.dispatch(getRewards());
    this.props.dispatch(getTransactionHistory());
    const config = await api.getFrontEndAppConfig();
    if (config.stamp_cards_json) {
      const stampsPosition = config.stamp_cards_json.map((el, i) =>
        Array(el.stamps_required).fill({}),
      );
      for (let i = 0; i < stampsPosition.length; i++) {
        for (let j = 0; j < stampsPosition[i].length; j++) {
          stampsPosition[i][j] = {
            top: this.getRandomNumber(-3, 3),
            left: this.getRandomNumber(-3, 3),
            rotate: this.getRandomNumber(-90, 90),
          };
        }
      }
      this.setState({ stampCards: config.stamp_cards_json, stampsPosition });
    }
  }

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
      //console.log('Redeem voucher, code: "' + this.state.voucherCode + '"')
    }
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      //dispatch(redeemGiftVoucher(data));
      dispatch(sendVoucherCode(data, false));
    }
  };

  handlerOfVoucherCode = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({ voucherCode: val });
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  getNumberOfRowsAndColumns(stampNumber) {
    let data = {};
    if (stampNumber % 5 !== 0) {
      data.columns = 'repeat(4,65px)';
      data.rows = '65px';
    } else {
      data.columns = 'repeat(5,50px)';
      data.rows = '50px';
    }
    return data;
  }
  onRefreshHandler = (e) => {
    setTimeout(() => {
      this.props.dispatch(getProfile());
      this.props.dispatch(getTransactionHistory());
      this.props.dispatch(getRewards());
      this.props.dispatch(getVouchers());

      e.target.complete();
    }, 2000);
  };
  render() {
    const {
      __,
      /*rewards,*/ profile,
      screenName,
      transactionHistory,
      faq,
      vouchers,
      qr_code,
    } = this.props;
    const { loyaltyFaqModalOpened, voucherCode, selectedVoucher } = this.state;
    let voucherRes = vouchers || [];
    const valid = validateProfileData(profile).isValid;
    const { hasAddToAppleWallet, hasAddToGoogleWallet } = getConfig().flags;
    return (
      <Loading transparent>
        <Layout
          withRefresher={true}
          onRefreshHandler={this.onRefreshHandler}
          color="transparent"
          headerWithTitle={true}
          headerTitle={__('Loyalty')}
          noPadding={true}
        >
          <div className="scrollable-y">
            <div
              className="loyalty-header"
              style={{
                backgroundImage: getConfig().general.loyaltyCardImage
                  ? `url(${getConfig().general.loyaltyCardImage})`
                  : `url(${loyaltyBg})`,
              }}
            >
              <div className="loyalty-header-name">
                <Subtitle className="bold loyalty-name">{screenName}</Subtitle>
              </div>
              <div className="loyalty-header-content">
                <div>
                  <div>
                    <Title className="loyalty-points lowercase">
                      {profile.available_balance?.toLocaleString()} {__('pts')}{' '}
                      <IonIcon
                        onClick={() => {
                          this.setState({ loyaltyFaqModalOpened: true });
                        }}
                        icon={informationCircleOutline}
                      />
                    </Title>
                    {Basket.calculatePointsAppliedPrice(profile.available_balance) > 0 && (
                      <NormalText className="loyalty-points-value">
                        {`${__('worth')} ${Basket.formatPrice(
                          Basket.calculatePointsAppliedPrice(profile.available_balance),
                        )}`}
                      </NormalText>
                    )}
                  </div>
                  <div>
                    <NormalText className="">{__('Member since')}</NormalText>
                    <NormalText className="uppercase ">
                      {moment(profile.created_at).format('MM/YY')}
                    </NormalText>
                  </div>
                </div>
                {isDefined(profile.qr_code) &&
                profile.qr_code !== '' &&
                (hasAddToAppleWallet || hasAddToGoogleWallet) ? (
                  <AddToWallet />
                ) : null}
                <div className="qr-code">
                  {isDefined(profile.qr_code) ? (
                    <div className="qr-holder">
                      <QRCode value={profile.qr_code} size={isWebConfig() ? 150 : 200} />
                    </div>
                  ) : (
                    <div>
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                !valid && this.props.dispatch(setModal('isVerfiedModalOpen', true));
              }}
              className={`loyalty-validation-wrapper ${!valid && 'invalid'}`}
            >
              <StrongText>{valid ? __('Account Verified') : __('Account Not Verified')}</StrongText>
            </div>
            {/*<div className="loyalty-redeem-voucher">
              <RedeemGiftVoucher __={__} />
            </div>*/}
            <div className="loyalty-vouchers-wrapper">
              <div className="redeem-voucher-field-wrapper redeem-gift-voucher-component-wrapper">
                <div className="input-field-container">
                  <Subtitle className="bold">{__('Enter Voucher Code')}</Subtitle>
                  <IonItem lines="none" className="input-field-wrapper">
                    <IonInput
                      placeholder={__('Voucher Code')}
                      onIonChange={(e) => this.handlerOfVoucherCode(e)}
                      value={voucherCode}
                    ></IonInput>
                  </IonItem>
                </div>
                <IonButton
                  disabled={voucherCode === ''}
                  color="secondary"
                  expand="block"
                  onClick={this.sendCode}
                  className="submit-button"
                >
                  {__('Submit')}
                </IonButton>
              </div>
            </div>
            <div className="loyalty-vouchers-wrapper">
              <FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
              <div>
                {voucherRes.length > 0 && (
                  <Subtitle className="bold">{__('Your Vouchers')}</Subtitle>
                )}
                {voucherRes &&
                  voucherRes.map((voucher, index) => {
                    return (
                      <VoucherItem
                        key={'vc-' + index}
                        {...voucher}
                        __={__}
                        action={this.viewVoucher}
                      />
                    );
                  })}
              </div>
            </div>
            {this.state.stampCards.length > 0 && (
              <div className="stamps-content">
                <Subtitle className="bold">{__('Your loyalty cards')}</Subtitle>
                {this.state.stampCards
                  .filter((el) => el.is_global && el.is_published)
                  .map((stampCard, stampIndex) => {
                    return (
                      <div
                        style={{ backgroundColor: stampCard?.background_color }}
                        className="stamps-wrapper"
                      >
                        <div className="stamps-box-header">
                          <Subtitle className="bold">{__(stampCard?.title)}</Subtitle>
                          <IonIcon
                            onClick={() => {
                              this.setState({ loyaltyFaqModalOpened: true });
                            }}
                            icon={informationCircleOutline}
                          />
                        </div>

                        <div
                          className="stamps-box"
                          style={{
                            gridTemplateColumns: this.getNumberOfRowsAndColumns(
                              stampCard?.stamps_required,
                            ).columns,
                            gridAutoRows: this.getNumberOfRowsAndColumns(stampCard?.stamps_required)
                              .rows,
                          }}
                        >
                          {Array(stampCard?.stamps_required)
                            .fill(null)
                            .map((item, index) => {
                              return (
                                <div
                                  style={
                                    index < profile.stamp_cards_json &&
                                    profile.stamp_cards_json.filter(
                                      (item) => item.id == stampCard.id,
                                    )[0]?.stamp_balance
                                      ? {
                                          boxShadow: `inset 0px 0px 0px 4px ${stampCard?.background_color}`,
                                        }
                                      : { border: `4px solid ${stampCard?.background_color}` }
                                  }
                                  key={index}
                                  className={
                                    profile.stamp_cards_json
                                      ? index <
                                        profile.stamp_cards_json.filter(
                                          (item) => item.id == stampCard.id,
                                        )[0]?.stamp_balance
                                        ? 'stamp-photo redeemable-stamp-photo'
                                        : 'stamp-photo locked-stamp-photo'
                                      : 'stamp-photo locked-stamp-photo'
                                  }
                                >
                                  <img
                                    style={{
                                      transform: `rotate(${this.state.stampsPosition[stampIndex][index].rotate}deg)`,
                                      top: this.state.stampsPosition[stampIndex][index].top,
                                      left: this.state.stampsPosition[stampIndex][index].left,
                                    }}
                                    src={`data:image/svg+xml;base64,${stampCard?.stamp_svg}`}
                                  />
                                </div>
                              );
                            })}
                        </div>
                        <SmallText>{__(stampCard?.description)}</SmallText>
                      </div>
                    );
                  })}{' '}
              </div>
            )}
            <div className="loyalty-history">
              {transactionHistory.length > 0 && (
                <Subtitle className="bold ">{__('Latest Transactions')}</Subtitle>
              )}
              <div className="transactions-wrapper box-wrapper">
                {transactionHistory.map((el, index) => {
                  return (
                    <div key={index}>
                      <IonRow>
                        <IonCol
                          size="8"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <SmallText>{el.location_name}</SmallText>
                          <StrongText className="block bold">{__(getLabel(el))}</StrongText>
                          <SmallText>
                            {Basket.getDate(el.transaction_date.replace('Z', '')).format(
                              'DD MMM YYYY h:mm a',
                            )}
                          </SmallText>{' '}
                        </IonCol>
                        <IonCol
                          ize="4"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <div className="transaction-points-wrapper">
                            <Subtitle
                              className={
                                el.stamp_power < 0 ? 'bold danger-color' : 'bold secondary-color'
                              }
                            >
                              {el.stamp_power < 0 ? '-' : '+'}
                              {Math.abs(el.stamp_power)}
                            </Subtitle>
                            <SmallText className="secondary-color">{__('points')}</SmallText>
                          </div>
                        </IonCol>
                      </IonRow>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Layout>
        <Modal
          className="loyalty-faq-modal"
          isOpen={loyaltyFaqModalOpened}
          onDidDismiss={() => {
            this.setState({ loyaltyFaqModalOpened: false });
          }}
        >
          <div className="lefted">
            <Title>{__('Faq & Support')}</Title>
            <div dangerouslySetInnerHTML={{ __html: faq }}></div>
          </div>{' '}
        </Modal>
        <Modal
          className="loyalty-faq-modal"
          isOpen={loyaltyFaqModalOpened}
          onDidDismiss={() => {
            this.setState({ loyaltyFaqModalOpened: false });
          }}
        >
          <div className="lefted">
            <Title>{__('Faq & Support')}</Title>
            <div dangerouslySetInnerHTML={{ __html: faq }}></div>
          </div>{' '}
        </Modal>

        <Modal
          className="voucher-modal"
          isOpen={selectedVoucher !== null}
          onDidDismiss={() => {
            this.setState({ selectedVoucher: null });
            this.props.history.replace({ state: {} });
          }}
        >
          {selectedVoucher && selectedVoucher.reward ? (
            <div style={{ textAlign: 'center' }}>
              <Title>{__('Voucher Info')}</Title>
              <VoucherItem {...selectedVoucher} __={__} />
              <div className="voucher-modal-content">
                {selectedVoucher.reward.name ? (
                  <>
                    <Title className="centered uppercase bold no-margin-bottom">
                      {selectedVoucher.reward.name}
                    </Title>
                  </>
                ) : null}
                {selectedVoucher.reward.small_print ? (
                  <div>
                    <NormalText>{selectedVoucher.reward.small_print}</NormalText>
                  </div>
                ) : null}
                <hr />
                {selectedVoucher.reward.expiry_date ? (
                  <div>
                    <StrongText>
                      {__('Expires:')}{' '}
                      {moment(selectedVoucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}
                    </StrongText>
                  </div>
                ) : null}
                <Spacer size={1} />
                <>
                  {isDefined(qr_code) ? (
                    <div className="qr-holder">
                      <QRCode value={qr_code} size={150} />
                    </div>
                  ) : (
                    <div className="noQrCode">
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </>
                {selectedVoucher.reward.description ? (
                  <div>
                    <SmallText className="block small-line-height">
                      {__(selectedVoucher.reward.description)}
                    </SmallText>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { rewards } = state.restaurants;
  const { history } = state.orders;

  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name} ${profile.last_name}`;
  }
  return {
    screenName,
    profile,
    rewards: rewards || [],
    transactionHistory: history || [],
    faq: state.common.faq || null,
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Loyalty)));
