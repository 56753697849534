import moment from './moment';
import {
  isDefined,
  getLocale,
  forwardTo,
  isEmptyObject,
  checkIdenticalArrays,
  isString,
  getItemTax,
} from './utils';
import { getConfig } from '../appConfig';
import asyncStorage from './asyncStorage';
import { store } from '../index';
import { SET_ORDERS_PROP, CREATE_ORDER } from '../store/constants';
import * as actions from '../store/actions';
import BigNumber from './bignumber';
import { createDiscount, createDiscountPackage } from './subscriptionFactory';

const { getIkentooMenu, showToast } = actions;
const zero = 0;
const negativeOne = -1;
const positiveOne = 1;
const emptyStr = '';
const errorMessages = {
  requiredOneItem: 'Must have at least one selected option',
  oneOrMode: 'Must have selected zero or one option',
  basketNotValid:
    'Basket has been cleared because collection time is passed. Please start new order',
  maxItems: 'You have selected maximum number of options',
  minItems: 'You must select required minimum of options. Min:',
};
const pointRatio = 100; //100 points = 1 gbp/eur
const enableCollectionTimeValidation = true;
const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];
const _vouchersType = getConfig().vouchersType;
const vouchersType = isDefined(_vouchersType) ? _vouchersType : 1;
class Basket {
  constructor() {
    this.create();
  }

  create = () => {
    this.items = [];
    this.restaurant = null;
    this.menu = null;
    this.collection_time = null;
    this._collection_time = null;
    this.discount_total = zero;
    this.applied_vouchers = [];
    this.applicable_vouchers = [];
    this.total = zero;
    this.subTotal = zero;
    this.selectedCard = null;
    this.pointsApplied = zero;
    this.pointsAppliedValue = zero;
    this.order_type = '';
    this.mobile = null;
    this.delivery_option = {};
    this.delivery_address = null;
    this.pick_up_point = null;
    this.pay_on_collection = false;
    this.delivery_price = 0;
    this.min_order = 0;
    this.allergen_data = [];
    this.cutoffTime = moment().unix();
    this.table_name = null;
    this.passed_order = null;
    this.process_fee_value = 0;
    this.service_charge_value = null;
    this.service_charge = 0;
    this.is_gift = false;
    this.discount = null;
    this.discountPackage = null;
    this.subscription = null; // when new subscription is created
    this.restaurant_delivery_price = 0;
    this.custom_field = {};
    this.is_asap = false;
    this.itemsTax = 0;
  };

  reset = () => {
    this.create();
    store.dispatch({ type: 'CLEAR_IKENOO_MENU' });
    this.instanceStateChanged();
  };

  // SETTERS ----------------------------------------------------------------------------------------
  // IMPORTANT: all changes of any instance property must triger SAVING new instance state to the local storage (instanceStateChanged)
  setRestaurant = (restaurant = null) => {
    this.restaurant = restaurant;
    this.instanceStateChanged();
  };
  setGift = (isGift = true) => {
    this.is_gift = isGift;
  };
  isGift = () => {
    return this.is_gift;
  };
  setMenu = (menu = null) => {
    this.menu = menu;

    this.instanceStateChanged();
  };

  setMobile = (mobile = null) => {
    this.mobile = mobile;

    this.instanceStateChanged();
  };

  setSelectedCard = (selectedCard) => {
    this.selectedCard = selectedCard;

    this.instanceStateChanged();
  };
  setCustomFieldValue = (val) => {
    this.custom_field = val;
  };
  setCollectionTime = (collection_time = null) => {
    if (collection_time && collection_time !== 'asap') {
      this._collection_time = collection_time;
      this.collection_time = moment(collection_time).unix();

      this.instanceStateChanged();
      // only for apps which dont have delivery module (only C&C)
      if (!isDefined(delivery)) {
        this.setOrderType('collection');
      }
    } else {
      this._collection_time = 'asap';
      this.collection_time = 'asap';
      this.instanceStateChanged();
    }
  };
  _getCollectionTime = () => this._collection_time;

  setCutoffTime = (cutoffTime = null) => {
    if (cutoffTime) {
      this.cutoffTime = cutoffTime;
      this.instanceStateChanged();
    }
  };

  addVoucher = (voucher, applicableVoucher) => {
    if (voucher) {
      this.applied_vouchers = [];
      this.applicable_vouchers = [];
      if (vouchersType === 3) {
        if (applicableVoucher && applicableVoucher.type) {
          let voucherRes = { ...voucher, type: applicableVoucher.type };
          this.applied_vouchers.push(voucherRes);
        }
      } else {
        this.applied_vouchers.push(voucher);
      }
      this.applicable_vouchers.push(applicableVoucher);

      this.instanceStateChanged();
      // this.clearAllDiscounts()
    }
  };

  isServiceChargeEnabled = () => {
    const hasServiceCharge = getConfig().general.hasServiceCharge;
    return (
      isDefined(hasServiceCharge) && hasServiceCharge && ['table'].indexOf(this.order_type) !== -1
    );
  };

  // available types: delivery, collect, table, pick-up-point
  setOrderType = (orderType = 'collection') => {
    if (
      [
        'delivery',
        'collection',
        'table',
        'pick-up-point',
        'charter-delivery',
        'scheduled-collection',
        'bill-pay',
        'pick-up-at-counter',
      ].indexOf(orderType) === -1
    ) {
      this.toastMessage(this.translate('Wrong order type'));
    }

    const option = this.delivery_option;
    this.order_type = orderType;
    this.delivery_option = option;

    // add service charge

    this.instanceStateChanged();
  };

  setTableNumber = (tableNumber) => {
    this.table_name = tableNumber;
    this.instanceStateChanged();
  };

  setDeliveryOption = (delivery_option) => {
    this.delivery_option = delivery_option;
  };

  setDeliveryAddress = (deliveryAddress) => {
    this.delivery_address = deliveryAddress;
    this.pick_up_point = null;
    this.instanceStateChanged();
  };

  setPickUpPoint = (pickUpPoint) => {
    this.pick_up_point = pickUpPoint;
    this.delivery_address = null;
    this.instanceStateChanged();
  };
  setDeliveryPrice = (deliveryPrice) => {
    this.restaurant_delivery_price = deliveryPrice;
    this.delivery_price = deliveryPrice;
    this.instanceStateChanged();
  };
  setProcessingFee = (value) => {
    this.process_fee_value = value;
  };
  calculateProcessingFee = (isGift) => {
    let subTotal;
    if (!this.service_charge_value) {
      subTotal = new BigNumber(this.subTotal).minus(this.service_charge).toNumber();
    } else {
      subTotal = new BigNumber(this.subTotal);
    }
    let process_fee_value = this.process_fee_value || 0;
    const processFeeConfig = getConfig().processFeeConfig;
    if (processFeeConfig || processFeeConfig?.length) {
      const appliedProcessFee = processFeeConfig.filter((el) => {
        return (
          el.min < subTotal - this.process_fee_value && el.max >= subTotal - this.process_fee_value
        );
      });
      if (appliedProcessFee) {
        if (!appliedProcessFee[0]?.isFixedAmount) {
          let processingFeePecentage = this.round(
            ((subTotal - this.process_fee_value) * appliedProcessFee[0]?.value) / 100,
            2,
          );
          if (processingFeePecentage) {
            process_fee_value = processingFeePecentage;
            this.processing_fee_description = appliedProcessFee[0]?.value;
          }
        } else {
          let processingFee = appliedProcessFee[0]?.value;
          if (processingFee) {
            process_fee_value = processingFee;
            this.processing_fee_description = 0;
          }
        }
      }
      this.setProcessingFee(isGift ? 0 : process_fee_value);
    }
    return isGift ? 0 : process_fee_value;
  };
  setMinOrder = (minOrder) => {
    this.min_order = parseFloat(minOrder);
    this.instanceStateChanged();
  };

  setAllergen = (allergen) => {
    if (allergen.length > 0) {
      this.allergen_data.push(allergen);
      this.instanceStateChanged();
    }
  };

  setSubscription = (subscription) => {
    this.subscription = subscription;
    if (subscription) {
      this.discount = createDiscount(subscription);
      this.discountPackage = createDiscountPackage(subscription);
    } else {
      this.discount = null;
      this.discountPackage = null;
    }
    this.instanceStateChanged();
  };

  getMembershipTotal = () => {
    return this.discountPackage.price * 100;
  };

  getMembershipName = () => {
    return this.discountPackage.title;
  };

  setDiscount = (subscription) => {
    if (subscription) {
      this.discount = createDiscount(subscription);
    }
    this.instanceStateChanged();
  };

  setPassedOrder = (passed_order = null) => {
    this.passed_order = passed_order;
    this.instanceStateChanged();
  };

  getPassedOrder = () => this.passed_order;

  setServicePercentage = (service_percentage = 0) => {
    this.service_percentage = service_percentage;
    this.instanceStateChanged();
    this.clearAllDiscounts();
  };

  setASAP = (is_asap = true) => {
    this.is_asap = is_asap;
    this.instanceStateChanged();
  };

  setItemsTax = (itemsTax = 0) => {
    this.itemsTax = itemsTax;
  };

  getAllergen = () => this.allergen_data;

  getDeliveryAddress = () => this.delivery_address;

  getPickUpPoint = () => this.pick_up_point;

  getDeliveryPrice = () => this.formatPrice(this.delivery_price);

  _getDeliveryPrice = () => this.delivery_price;

  getProcessingFee = () => this.formatPrice(this.process_fee_value);

  getDeliveryOption = () => this.delivery_option;

  getMinOrder = () => this.min_order;

  getTableNumber = () => this.table_name;

  getServicePercentage = () => this.service_percentage;

  _getServicePercentage = () => this.getServicePercentage() + '%';

  getServiceChargeValue = () => this.service_charge_value;

  getFormatedServiceChargeValue = () => this.formatPrice(this.service_charge_value, true);

  calculateServiceCost = () => {
    if (this.service_percentage === false) {
      return this.service_charge;
    } else {
      const _service_percentage = new BigNumber(this.service_percentage);
      let _order_value = BigNumber(zero);
      this.items.forEach((basketItem) => {
        _order_value = _order_value.plus(this.calculateItemPrice(basketItem));
      });
      // _order_value = _order_value.plus(this.process_fee_value);
      const _service_charge = isNaN(_order_value.div(100).times(_service_percentage).toNumber())
        ? zero
        : _order_value.div(100).times(_service_percentage).toNumber();

      this.service_charge = this.round(_service_charge, 2);
      return this.round(_service_charge, 2);
    }
  };
  setServiceCharge = (num) => {
    this.service_charge = this.round(parseFloat(num), 2);
    this.service_percentage = false;
    this.instanceStateChanged();
  };
  _calculateServiceCost = (inlucdeZero) =>
    this.formatPrice(this.calculateServiceCost(), inlucdeZero);

  getServiceCharge = (inlucdeZero) => this.formatPrice(this.service_charge, inlucdeZero);

  _getServiceCharge = () => this.service_charge;

  selectedChoicesSkus = (choices) => {
    let skus = [];
    if (choices && choices.length > 0) {
      choices.forEach((choice) => {
        if (choice && choice.length > 0) {
          skus.push(...this.selectedChoicesSkus(choice));
        } else {
          if (choice.sku) {
            skus.push(choice.sku);
          }
          return;
        }
      });
    }

    return skus;
  };

  addToBasket = (item) => {
    if (item) {
      if (this.items.length > 0) {
        let foundItem = this.items.find((fItm) => fItm.item.sku === item.item.sku);
        let foundItemIndex = this.items.findIndex((fItm) => fItm.item.sku === item.item.sku);
        if (foundItem && foundItemIndex !== -1) {
          if (
            foundItem.selectedChoices.length === 0 &&
            item.selectedChoices.length === 0 &&
            foundItem.instructions === item.instructions
          ) {
            this.changeItemQuantity(foundItemIndex, item.quantity, true);
          } else {
            let itmSkus = this.selectedChoicesSkus(foundItem.selectedChoices);
            let itemSkus = this.selectedChoicesSkus(item.selectedChoices);
            if (
              checkIdenticalArrays(itmSkus, itemSkus) &&
              foundItem.instructions === item.instructions
            ) {
              this.changeItemQuantity(foundItemIndex, item.quantity, true);
            } else {
              this.items.push(item);
            }
          }
        } else {
          this.items.push(item);
        }
      } else {
        this.items.push(item);
      }

      this.instanceStateChanged();
      this.clearAllDiscounts();
    }
  };

  removeFromBasket = (itemIndex) => {
    if (isDefined(itemIndex) && isDefined(this.items[itemIndex])) {
      this.items = this.items.filter((item, index) => index !== itemIndex);

      this.instanceStateChanged();
      this.clearAllDiscounts();
    }
  };

  changeItemQuantity = (index, value = 0, addOn = false) => {
    if (isDefined(index) && isDefined(this.items[index])) {
      let item = this.items[index];
      if (addOn) {
        item.quantity += value;
      } else {
        item.quantity = value;
      }
      if (item.quantity <= 0) {
        this.items = this.items.filter((i, basketIndex) => basketIndex !== index);
        let allergenIndex = this.allergen_data.findIndex((data) => data[1].sku === item.item.sku);
        if (allergenIndex > -1) {
          let newArr = [...this.allergen_data];
          newArr.splice(allergenIndex, 1);
          this.allergen_data = newArr;
        }
      }
    }

    this.instanceStateChanged();
    this.clearAllDiscounts();
  };

  changeSelectedCard = (cardToken = null) => {
    if (cardToken) {
      this.selectedCard = cardToken;
      this.instanceStateChanged();
    }
  };

  // e.g. points = 700
  // that is 7eur for the ration 100
  applyPoints = (points = zero, currentAvailableBalace = zero, cb) => {
    // check if applied points don't overcomes users available_balance
    const { redeemPointsMin } = getConfig().general;
    if (isString(points)) {
      points = parseInt(points);
    }
    let pointsBalance = currentAvailableBalace - points;
    if (pointsBalance < 0) {
      this.toastMessage('You have no points avaliable');
      return;
    }

    // prevent user to apply greater points amount then order total (e.g. total: 7 gbp, applied_points_value: 8 gbt)
    // this.calculatePointsAppliedPrice(this.pointsApplied) -- reset total to the state without applied points
    const newTotal = new BigNumber(this.total)
      .plus(this.calculatePointsAppliedPrice(this.pointsApplied))
      .minus(this.calculatePointsAppliedPrice(points))
      .toNumber();
    if (newTotal < 0) {
      this.toastMessage(
        `Minimum Points redemption is ${redeemPointsMin}. Please add more items to your basket. `,
      );
      return;
    }

    this.pointsApplied = points;
    this.instanceStateChanged();
    if (cb) {
      cb();
    }
  };

  // GETTERS ----------------------------------------------------------------------------------------
  toObject = () => {
    const {
      items,
      restaurant,
      mobile,
      collection_time,
      _collection_time,
      total,
      discount_total,
      applied_vouchers,
      applicable_vouchers,
      selectedCard,
      pointsApplied,
      subTotal,
      order_type,
      delivery_option,
      delivery_address,
      pick_up_point,
      delivery_price,
      process_fee_value,
      menu,
      min_order,
      allergen_data,
      cutoffTime,
      table_name,
      service_percentage,
      service_charge,
      service_charge_value,
      passed_order,
      is_gift,
      discount,
      restaurant_delivery_price,
      custom_field,
      is_asap,
      itemsTax,
    } = this;
    return {
      is_gift,
      items,
      restaurant,
      mobile,
      collection_time,
      _collection_time,
      discount_total,
      applied_vouchers,
      applicable_vouchers,
      total,
      selectedCard,
      pointsApplied,
      subTotal,
      order_type,
      delivery_option,
      delivery_address,
      pick_up_point,
      delivery_price,
      process_fee_value,
      menu,
      min_order,
      allergen_data,
      cutoffTime,
      table_name,
      service_percentage,
      service_charge,
      service_charge_value,
      passed_order,
      discount,
      restaurant_delivery_price,
      custom_field,
      is_asap,
      itemsTax,
    };
  };

  itemsCount = () => (this.items || []).length;

  itemsCountAll = () => this.items.map((item) => item.quantity).reduce((a, b) => a + b, 0);

  getDiscountTotal = () => this.discount_total;

  _getDiscountTotal = () => this.formatPrice(this.getDiscountTotal());

  getItems = () => this.items || [];

  getTotal = () => this.total;

  _getTotal = (inlucdeZero) => this.formatPrice(this.getTotal(), inlucdeZero);

  getSubTotal = () => this.subTotal;

  _getSubTotal = () => this.formatPrice(this.getSubTotal());

  getRestauranName = () =>
    this.restaurant && this.restaurant.name ? this.restaurant.name : emptyStr;

  getRestaurant = () => this.restaurant || null;

  getASAP = () => this.is_asap;

  getRestaurantServiceCharge = () => {
    if (this.restaurant.service_charge) {
      return JSON.parse(this.restaurant.service_charge);
    } else {
      return false;
    }
  };
  getMenu = () => this.menu || null;

  getMobile = () => this.mobile || null;

  getOrderDate = (format = null) =>
    this.collection_time
      ? moment.unix(this.collection_time).format(format || 'dddd Do MMMM')
      : emptyStr;

  getOrderTime = (format = null) =>
    this.collection_time ? moment.unix(this.collection_time).format(format || 'LT') : emptyStr;

  getSelectedCurrency = () =>
    this.restaurant && this.restaurant.currency
      ? this.restaurant.currency
      : getConfig().general.defaultCurrency;

  getAppliedVocuher = () => this.applied_vouchers || [];

  getApplicableVocuher = () => this.applicable_vouchers || [];

  getCurrency = () => {
    const defaultCurrencySimbol = getConfig().general.defaultCurrencySimbol || '';
    const currency = this.getSelectedCurrency();
    const empty = { label: defaultCurrencySimbol, beforeNumber: true, includeSpace: false }; // When no value}
    const currency_symbols = {
      ...empty,
      USD: { label: '$', beforeNumber: true, includeSpace: false }, // US Dollar
      EUR: { label: '€', beforeNumber: true, includeSpace: false }, // Euro
      GBP: { label: '£', beforeNumber: true, includeSpace: false }, // British Pound Sterling
      CHF: { label: '', beforeNumber: false, includeSpace: false },
    };
    const currency_name = currency.toUpperCase();
    if (currency_symbols[currency_name] !== undefined) {
      return currency_symbols[currency_name];
    } else {
      return empty;
    }
  };

  getCountry = () =>
    this.restaurant && this.restaurant.country_code
      ? this.restaurant.country_code
      : getConfig().general.default_country_code;

  getAppliedPoints = () => this.pointsApplied;

  getItemsForApplePay = (profile) => {
    return (this.items || [])
      .map((item) => ({
        label: this.getProductName(item.item, profile),
        amount: this.calculateItemPrice(item),
      }))
      .filter((item) => item.amount > 0);
  };

  getItemsForWebPay = (clientName) => {
    let productName = clientName;
    return {
      label: productName,
      amount: Math.ceil(new BigNumber(this.total).times(100).toNumber()),
    };
  };

  getProductName = (item = {}, profile) => {
    let productName = emptyStr;
    const locale = getLocale(profile);
    if (item.productName) {
      productName = item.productName;
      if (item.itemRichData && item.itemRichData.texts) {
        const translation = item.itemRichData.texts.find((i) => i.locale === locale);
        if (translation && translation.friendlyDisplayName !== emptyStr) {
          productName = translation.friendlyDisplayName;
        }
      }
    }
    return productName;
  };

  getProductDescription = (item = {}, profile) => {
    let description = item.description || emptyStr;
    const locale = getLocale(profile);
    if (item.itemRichData && item.itemRichData.texts) {
      const translation = item.itemRichData.texts.find((i) => i.locale === locale);
      if (translation && translation.description !== emptyStr) {
        description = translation.description;
      }
    }
    return description;
  };

  getOrderType = (order = null) => {
    if (order) {
      if (order.delivery) {
        return 'charter-delivery';
      }
      if (order.take_away) {
        return 'collection';
      }
      if (order.eat_in) {
        return 'table';
      }
      if (order.pick_up_point) {
        return 'pick-up-point';
      }
      if (order.scheduled_collection) {
        return 'scheduled-collection';
      }
      if (order.pick_up_at_counter) {
        return 'pick-up-at-counter';
      }
      return null;
    } else {
      switch (this.order_type) {
        case 'delivery':
          return 'Delivery';
        case 'collection':
          return 'Click & Collect';
        case 'table':
          return 'Table';
        case 'pick-up-point':
          return 'Outpost Drop-Off';
        case 'charter-delivery':
          return 'Delivery';
        case 'scheduled-collection':
          return 'scheduled-collection';
        case 'pick-up-at-counter':
          return 'pick-up-at-counter';
        default:
          return '';
      }
    }
  };

  getOrderTypeRaw() {
    return this.order_type;
  }

  geRestaurantDeliveryPrice = () => {
    return parseFloat(this.restaurant_delivery_price) || 0;
  };

  getItemsTax = () => {
    return this.itemsTax;
  };

  getCustomFieldValue = () => {
    return this.custom_field;
  };
  // METHODS ----------------------------------------------------------------------------------------

  // get current state of the instance as JSON object
  export = () => {
    const {
      items,
      restaurant,
      mobile,
      collection_time,
      _collection_time,
      applied_vouchers,
      applicable_vouchers,
      selectedCard,
      pointsApplied,
      order_type,
      delivery_option,
      delivery_address,
      pick_up_point,
      delivery_price,
      process_fee_value,
      menu,
      min_order,
      allergen_data,
      cutoffTime,
      table_name,
      service_percentage,
      service_charge,
      service_charge_value,
      passed_order,
      is_gift,
      custom_field,
      is_asap,
      restaurant_delivery_price,
      itemsTax,
    } = this;
    return {
      items,
      restaurant,
      mobile,
      collection_time,
      _collection_time,
      applied_vouchers,
      applicable_vouchers,
      selectedCard,
      pointsApplied,
      order_type,
      delivery_option,
      delivery_address,
      pick_up_point,
      delivery_price,
      process_fee_value,
      menu,
      min_order,
      allergen_data,
      cutoffTime,
      table_name,
      service_percentage,
      service_charge,
      service_charge_value,
      passed_order,
      is_gift,
      custom_field,
      is_asap,
      restaurant_delivery_price,
      itemsTax,
    };
  };

  //save instance to the local storage
  //this method should track ALL instance changes (must be called in every SETTER)
  saveInstance = async () => {
    // save to local storage
    await asyncStorage.setItem('basket', JSON.stringify(this.export()));
    this.log('Saved to localStorage');
  };

  import = async (basketObject = null) => {
    //reset current instance to the initial state
    this.create();

    if (!basketObject) {
      let storageBasket = await asyncStorage.getItem('basket');
      if (storageBasket) {
        try {
          if (isDefined(storageBasket)) {
            basketObject = JSON.parse(storageBasket);
          }
        } catch (e) {
          this.log('Error: Parsing basket from storage.');
        }
      }
    }

    //restore all relevent instance properties from provided object
    if (isDefined(basketObject)) {
      Object.keys(basketObject).forEach((key) => {
        this[key] = basketObject[key];
      });

      if (
        basketObject.restaurant &&
        basketObject.restaurant.business_location_id &&
        basketObject.restaurant.menu_id
      ) {
        const { menu_id, business_location_id } = basketObject.restaurant;
        const _menu_id = this.menu || menu_id;
        store.dispatch(getIkentooMenu(_menu_id, business_location_id, false));
      }

      //recalculate totals and skip saving to the local storage
      this.instanceStateChanged(false);

      this.log('Imported from localStorage');

      this._isCollectionTimeStillValid();
    } else {
      this.log("LocalStorage basket don't exists.");
      createNewBasketInstance();
    }
  };

  // eslint-disable-next-line no-console
  log = (message = null) =>
    console.log('Basket: ', message ? '(' + message + ')' : '', this.toObject());

  calculateTotal = () => {
    const total = new BigNumber(zero);
    this.total = total
      .plus(this.subTotal)
      .plus(this.calculatePointsAppliedPrice(null, true))
      .plus(this.calculateAppliedVocuhersPrice(true))
      .minus(this.calculateMembershipDiscount())
      .toNumber();
  };

  calculateSubTotal = () => {
    let subTotal = new BigNumber(zero);
    let itemsTax = new BigNumber(zero);
    this.items.forEach((basketItem) => {
      const { itemPrice, totalItemTax } = this.calculateItemPriceWithTax(basketItem, true);
      subTotal = subTotal.plus(itemPrice);
      itemsTax = itemsTax.plus(totalItemTax);
    });
    this.subTotal = subTotal
      .plus(this.calculateServiceCost())
      .plus(this.calculateProcessingFee(this.isGift()));
    this.subTotal = this.subTotal.plus(this.calculateDeliveryCharge(this.subTotal));
    this.itemsTax = this.round(itemsTax.toNumber(), 2);
    return this.subTotal.toNumber();
  };

  calculateMembershipDiscount = () => {
    const subtotalFull = new BigNumber(this.getSubTotal());
    const voucherDiscount = this.calculateAppliedVocuhersPrice();
    const subtotal = subtotalFull.minus(voucherDiscount).toNumber();
    if (subtotal === 0 || subtotal < 0) {
      return 0;
    }
    return this.discount ? this.round(this.discount.calculate_discount(subtotal), 2).toFixed(2) : 0;
  };

  membershipSubscription = () => (this.discountPackage ? this.discountPackage.price : 0);

  calculateItemPrice = (basketItem, includeSubItems = true) => {
    const { item, quantity, selectedChoices } = basketItem;
    let itemPrice = new BigNumber(zero);
    let menuDealTotal = new BigNumber(zero);
    let selectedChoicesPrice = new BigNumber(zero);

    if (item && item.productPrice) {
      itemPrice = parseFloat(item.productPrice);
    }
    if (includeSubItems && selectedChoices && selectedChoices.length > 0) {
      //go throught all groups
      selectedChoices.forEach((menuDealGroup) => {
        if (menuDealGroup && menuDealGroup.length > 0) {
          //go throught all selected choices
          menuDealGroup.forEach((selectedChoice) => {
            selectedChoicesPrice = new BigNumber(parseFloat(selectedChoice.productPrice));
            if (selectedChoice.productPrice && selectedChoice.productPrice !== '') {
              menuDealTotal = menuDealTotal.plus(
                selectedChoicesPrice.times(selectedChoice.quantity),
              );
            }
          });
        }
      });
    }
    return new BigNumber(itemPrice).plus(menuDealTotal).times(quantity).toNumber();
  };

  calculateItemPriceWithTax = (basketItem, calculateOnlyTaxes = false, includeSubItems = true) => {
    const { item, quantity, selectedChoices } = basketItem;
    let itemPrice = new BigNumber(zero);
    let menuDealTotal = new BigNumber(zero);
    let selectedChoicesPrice = new BigNumber(zero);
    let totalItemTax = new BigNumber(zero);
    if (item && item.productPrice) {
      itemPrice = parseFloat(item.productPrice);
    }
    if (includeSubItems && selectedChoices && selectedChoices.length > 0) {
      //go throught all groups
      selectedChoices.forEach((menuDealGroup) => {
        if (menuDealGroup && menuDealGroup.length > 0) {
          //go throught all selected choices
          menuDealGroup.forEach((selectedChoice) => {
            selectedChoicesPrice = new BigNumber(parseFloat(selectedChoice.productPrice));
            if (selectedChoice.productPrice && selectedChoice.productPrice !== '') {
              const itemTax = getItemTax(selectedChoice, this.getOrderTypeRaw());
              if (itemTax && calculateOnlyTaxes) {
                totalItemTax = totalItemTax.plus(itemTax.itemTax);
              } else if (itemTax) {
                selectedChoicesPrice = selectedChoicesPrice.plus(itemTax.itemTax);
                totalItemTax = totalItemTax.plus(itemTax.itemTax);
              }
              menuDealTotal = menuDealTotal.plus(
                selectedChoicesPrice.times(selectedChoice.quantity),
              );
            }
          });
        }
      });
    }
    const itemTax = getItemTax(item, this.getOrderTypeRaw());
    let oneItemPrice = new BigNumber(itemPrice);
    if (itemTax && calculateOnlyTaxes) {
      totalItemTax = totalItemTax.plus(itemTax.itemTax);
    } else if (itemTax) {
      oneItemPrice = oneItemPrice.plus(itemTax.itemTax);
      totalItemTax = totalItemTax.plus(itemTax.itemTax);
    }
    return {
      itemPrice: oneItemPrice.plus(menuDealTotal).times(quantity).toNumber(),
      totalItemTax: totalItemTax.toNumber(),
    };
  };

  _calculateItemPrice = (basketItem, includeSubItems, inlucdeZero) =>
    this.formatPrice(this.calculateItemPrice(basketItem, includeSubItems), inlucdeZero);

  // parse sub item as items and then use existing methods
  calculateSubItemPrice = (subItem, quantity = 1) => {
    const item = {
      quantity,
      item: subItem,
    };
    return this.calculateItemPrice(item);
  };

  _calculateSubItemPrice = (subItem, quantity) =>
    this.formatPrice(this.calculateSubItemPrice(subItem, quantity));

  calculateItemPriceByIndex = (itemIndex, includeSubItems) => {
    if (isDefined(itemIndex) && this.items[itemIndex]) {
      return this.calculateItemPrice(this.items[itemIndex], includeSubItems);
    } else {
      return zero;
    }
  };

  _calculateItemPriceByIndex = (itemIndex, includeSubItems) =>
    this.formatPrice(this.calculateItemPriceByIndex(itemIndex, includeSubItems));

  // use appliablePoints to calculate pointsApplieddPrice without need to change instace and then make calculations
  calculatePointsAppliedPrice = (appliablePoints = null, shouldBeNagative = false) => {
    const points = isDefined(appliablePoints) ? appliablePoints : this.pointsApplied;
    if (points > zero) {
      const pointsRealValue = new BigNumber(points).div(pointRatio); //currency value
      return pointsRealValue.times(shouldBeNagative ? negativeOne : positiveOne).toNumber();
    }
    return zero;
  };

  _calculatePointsAppliedPrice = (appliablePoints, shouldBeNagative, inlucdeZero) =>
    this.formatPrice(
      this.calculatePointsAppliedPrice(appliablePoints, shouldBeNagative),
      inlucdeZero,
    );

  formatPrice = (price, inlucdeZero = false) => {
    if (isDefined(price)) {
      if (typeof price === 'string') {
        price = parseFloat(price);
      }
      if (price !== 0 || inlucdeZero) {
        let retValue = '';
        const currencyObj = this.getCurrency();
        let currencySign = currencyObj.label;
        currencySign = currencyObj.includeSpace
          ? currencyObj.beforeNumber
            ? currencySign + ' '
            : ' ' + currencySign
          : currencySign;
        retValue += price < 0 ? '-' : '';

        // before number
        retValue += currencyObj.beforeNumber ? currencySign : '';
        const fullPrice =
          typeof price === 'string' ? price : price < 0 ? price * negativeOne : price;
        retValue += this.round(fullPrice, 2).toFixed(2);
        //after number
        retValue += currencyObj.beforeNumber ? '' : currencySign;
        return retValue;
      }
    }
    return emptyStr;
  };

  round(v, d) {
    return parseFloat(Math.round(v.toFixed(d + 1) + 'e' + d) + 'e-' + d);
  }
  isProductJustEnabled = (item) =>
    item &&
    isDefined(item.sku) &&
    this.restaurant &&
    [...(this.restaurant.disabled_skus || [])].indexOf(item.sku) !== -1
      ? false
      : true;

  isProductUnsnoozed = (item) => {
    let format_date = 'YYYY-MM-DD HH:mm:ss';
    const isValid =
      item &&
      isDefined(item.sku) &&
      this.restaurant &&
      [...(this.restaurant.snoozed_skus || [])].indexOf(
        item.sku.includes('###') ? item.sku.replace('###', '') : item.sku,
      );

    return isValid !== null && isValid !== -1
      ? this.restaurant &&
        this._getCollectionTime() &&
        this.restaurant.snoozeEnd_time.length - 1 >= isValid
        ? moment(moment(this.restaurant.snoozeEnd_time[isValid]).format(format_date)).isBefore(
            moment(this._collection_time).format(format_date),
          )
          ? true
          : false
        : false
      : true;
  };

  isProductEnabled = (item) => {
    let format_date = 'YYYY-MM-DD HH:mm:ss';
    const isValid =
      item &&
      isDefined(item.sku) &&
      this.restaurant &&
      [...(this.restaurant.disabled_skus || []), ...(this.restaurant.snoozed_skus || [])].indexOf(
        item.sku.includes('###') ? item.sku.replace('###', '') : item.sku,
      );

    return isValid !== null && isValid !== -1
      ? this.restaurant &&
        this._getCollectionTime() &&
        this.restaurant.snoozeEnd_time.length - 1 >= isValid
        ? moment(moment(this.restaurant.snoozeEnd_time[isValid]).format(format_date)).isBefore(
            moment(this._collection_time).format(format_date),
          )
          ? true
          : false
        : false
      : true;
  };

  isChoicesGroupValid = (item) => item.items.filter((i) => this.isProductEnabled(i)).length > 0;

  calculateVouchersPrice = (vouchers = [], applicableVouchers = [], shouldBeNagative = false) => {
    let cost = zero;
    let voucherWithDiscountInfo = {};
    vouchers.forEach((applied_voucher) => {
      if (vouchersType === 3) {
        voucherWithDiscountInfo = applicableVouchers.find((applicable_vocuher) =>
          applicable_vocuher.type && applicable_vocuher.type === 1
            ? applicable_vocuher.id === applied_voucher.id
            : applied_voucher.reward.id
            ? applicable_vocuher.id === applied_voucher.reward.id
            : applicable_vocuher.id === applied_voucher.reward_sku,
        );
      } else {
        voucherWithDiscountInfo = applicableVouchers.find(
          (applicable_vocuher) => applicable_vocuher.id === applied_voucher.id,
        );
      }
      if (voucherWithDiscountInfo) {
        cost = new BigNumber(cost).plus(new BigNumber(voucherWithDiscountInfo.cost)).toNumber();
      }
    });
    return new BigNumber(cost)
      .times(shouldBeNagative ? negativeOne : positiveOne)
      .div(pointRatio)
      .toNumber();
  };

  calculateAppliedVocuhersPrice = (shouldBeNagative = false) => {
    return this.calculateVouchersPrice(
      this.applied_vouchers,
      this.applicable_vouchers,
      shouldBeNagative,
    );
  };

  _calculateAppliedVocuhersPrice = (shouldBeNagative, inlucdeZero) =>
    this.formatPrice(this.calculateAppliedVocuhersPrice(shouldBeNagative), inlucdeZero);

  canVoucherBeApplied = (voucher, applicableVoucher, shouldBeNagative = true) => {
    const vouchersPrice = this.calculateVouchersPrice(
      [voucher],
      [applicableVoucher],
      shouldBeNagative,
    );
    return this.total >= vouchersPrice;
  };

  instanceStateChanged = (saveToStorage = true, skipStoreUpdate = false) => {
    this.calculateSubTotal();

    this.calculateTotal();
    if (saveToStorage) {
      this.saveInstance();
    }
    if (!skipStoreUpdate) {
      store.dispatch({ type: SET_ORDERS_PROP, key: 'basketUpdated', value: Date.now() });
    }
  };

  clearAllDiscounts = (clearPoints = true, clearVouchers = true) => {
    if (clearPoints) {
      this.pointsApplied = zero;
    }
    if (clearVouchers) {
      this.applied_vouchers = [];
    }

    this.instanceStateChanged();
  };

  validateItem = (basketItem) => {
    const { item, selectedChoices, selectedSubChoices } = basketItem;
    let errors =
      item && item.menuDealGroups ? Array((item.menuDealGroups || []).length).fill(null) : [];
    let errorCount = 0;
    if (item) {
      if (item.menuDealGroups && item.menuDealGroups.length > 0) {
        if (selectedChoices && selectedChoices.length > 0) {
          if (item.menuDealGroups.length === selectedChoices.length) {
            item.menuDealGroups.forEach((menuDealGroup, groupIndex) => {
              const selectedChoiceGroup = selectedChoices[groupIndex];
              const { mustSelectAnItem, multiSelectionPermitted, min, max } = menuDealGroup;
              if (this.isChoicesGroupValid(menuDealGroup)) {
                const selectedChoicesQuantities = selectedChoiceGroup.reduce(
                  (total, currentChoice) => {
                    total += currentChoice.quantity || 0;
                    return total;
                  },
                  0,
                );

                if (mustSelectAnItem && selectedChoiceGroup.length === 0) {
                  errors[groupIndex] = errorMessages.requiredOneItem;
                  errorCount += 1;
                }
                if (!multiSelectionPermitted && selectedChoiceGroup.length > 1) {
                  errors[groupIndex] = errorMessages.oneOrMode;
                  errorCount += 1;
                }
                if (
                  multiSelectionPermitted &&
                  isDefined(max) &&
                  max > 0 &&
                  selectedChoiceGroup.length > 0 &&
                  selectedChoicesQuantities > max
                ) {
                  errors[groupIndex] = errorMessages.maxItems;
                  errorCount += 1;
                }
                if (
                  multiSelectionPermitted &&
                  isDefined(min) &&
                  min > 0 &&
                  selectedChoiceGroup.length > 0 &&
                  selectedChoicesQuantities < min
                ) {
                  errors[groupIndex] = errorMessages.minItems + min;
                  errorCount += 1;
                }
              }
              if (selectedChoiceGroup?.[0]?.subModifiers) {
                const selected = selectedChoiceGroup?.[0]?.subModifiers[0];
                if (selected) {
                  const subMax = selected.max;
                  const subMin = selected.min;
                  const subMultiSelectionPermitted = selected.multiSelectionPermitted;
                  const subMustSelectAnItem = selected.mustSelectAnItem;
                  const selectedSubChoicesQuantities = selectedSubChoices[groupIndex].flat(1)
                    .length;
                  if (subMustSelectAnItem && selectedSubChoicesQuantities === 0) {
                    errors[groupIndex] = errorMessages.requiredOneItem;
                    errorCount += 1;
                  }
                  if (!subMultiSelectionPermitted && selectedSubChoicesQuantities > 1) {
                    errors[groupIndex] = errorMessages.oneOrMode;
                    errorCount += 1;
                  }
                  if (
                    subMultiSelectionPermitted &&
                    isDefined(subMax) &&
                    subMax > 0 &&
                    selectedSubChoicesQuantities === 0 &&
                    selectedSubChoicesQuantities > subMax
                  ) {
                    errors[groupIndex] = errorMessages.maxItems;
                    errorCount += 1;
                  }
                  if (
                    subMultiSelectionPermitted &&
                    isDefined(subMin) &&
                    subMin > 0 &&
                    selectedSubChoicesQuantities === 0 &&
                    selectedSubChoicesQuantities < subMin
                  ) {
                    errors[groupIndex] = errorMessages.minItems + subMin;
                    errorCount += 1;
                  }
                }
              }
            });
          }
        }
        if (selectedSubChoices && selectedSubChoices.length > 0) {
          item.menuDealGroups.forEach((menuDealGroup, groupIndex) => {
            if (menuDealGroup.bundleModifiers) {
              const selectedBundledModifierIndex = selectedSubChoices[groupIndex].findIndex(
                (el) => el.length !== 0,
              );
              if (selectedBundledModifierIndex > 0) {
                const selectedSubChoiceGroup =
                  selectedSubChoices[groupIndex][selectedBundledModifierIndex];
                const subMenuDealGroup =
                  menuDealGroup.bundleModifiers[selectedBundledModifierIndex][0];
                const { mustSelectAnItem, multiSelectionPermitted, min, max } = subMenuDealGroup;
                const selectedSubChoicesQuantities = selectedSubChoiceGroup.reduce(
                  (total, currentChoice) => {
                    total += currentChoice.quantity || 0;
                    return total;
                  },
                  0,
                );
                if (mustSelectAnItem && selectedSubChoiceGroup.length === 0) {
                  errors[groupIndex] = errorMessages.requiredOneItem;
                  errorCount += 1;
                }
                if (!multiSelectionPermitted && selectedSubChoiceGroup.length > 1) {
                  errors[groupIndex] = errorMessages.oneOrMode;
                  errorCount += 1;
                }
                if (
                  multiSelectionPermitted &&
                  isDefined(max) &&
                  max > 0 &&
                  selectedSubChoiceGroup.length > 0 &&
                  selectedSubChoicesQuantities > max
                ) {
                  errors[groupIndex] = errorMessages.maxItems;
                  errorCount += 1;
                }
                if (
                  multiSelectionPermitted &&
                  isDefined(min) &&
                  min > 0 &&
                  selectedSubChoiceGroup.length > 0 &&
                  selectedSubChoicesQuantities < min
                ) {
                  errors[groupIndex] = errorMessages.minItems + min;
                  errorCount += 1;
                }
              }
            }
          });
        }
      }
    }
    return {
      errors,
      errorCount,
    };
  };
  getModifierGroupName = (item = {}, profile) => {
    let description = emptyStr;
    const locale = getLocale(profile);
    if (item.itemRichData && item.itemRichData.texts) {
      const translation = item.itemRichData.texts.find((i) => i.locale === locale);
      if (translation && translation.friendlyDisplayName !== '') {
        description = translation.friendlyDisplayName;
      } else if (item.itemRichData.texts.length > 0) {
        description = item.description;
      }
    } else {
      description = item.description;
    }
    return description;
  };
  isMinimumOrderTotalSatisfied = (showToast = false) => {
    const minOrder = this.getMinOrder();
    const total = this.getSubTotal();
    if (minOrder > 0 && minOrder > total) {
      if (showToast) {
        this.toastMessage('Minimum order must be ' + this.formatPrice(minOrder), 'warning');
      }
      return false;
    }
    return true;
  };

  createOrder = (paymentType, paymentWebType, cb) => {
    if (!isEmptyObject(this.getDeliveryOption()) && this.getDeliveryOption().id === 'delivery') {
      if (this.isMinimumOrderTotalSatisfied()) {
        store.dispatch({ type: CREATE_ORDER, paymentType, paymentWebType, cb });
      }
    } else {
      store.dispatch({ type: CREATE_ORDER, paymentType, paymentWebType, cb });
    }
  };

  parseBasketData = (paymentType = null, paymentWebType = null) => {
    const {
      items,
      selectedCard,
      restaurant,
      mobile,
      collection_time,
      total,
      pointsApplied,
      order_type,
      delivery_option,
      delivery_address,
      pick_up_point,
      delivery_price,
      min_order,
      allergen_data,
      cutoffTime,
      applicable_vouchers,
      table_name,
      service_percentage,
      service_charge,
      service_charge_value,
      passed_order,
      process_fee_value,
      is_gift,
      discount,
      restaurant_delivery_price,
      custom_field,
      is_asap,
      itemsTax,
    } = this;
    const totalWithTaxes = total + itemsTax;
    let errors = [];
    if (this.itemsCount() === 0) {
      errors.push('Your basket is empty');
    }
    if (!restaurant) {
      errors.push('Please select restaurant');
    }
    // if (hasContactDetails && !mobile) {
    // 	errors.push('Please select mobile')
    // }
    if (!collection_time) {
      errors.push('Please select collection time');
    }

    if (paymentType || paymentWebType) {
      this.selectedCard =
        paymentType === 'apple'
          ? 'Apple Pay'
          : paymentType === 'google'
          ? 'Google Pay'
          : paymentType === 'collectedPay'
          ? 'Pay on collection'
          : paymentType === 'driverPay'
          ? 'Pay to the driver'
          : paymentWebType === 'APPLE_PAY'
          ? 'Apple Pay'
          : paymentWebType === 'BROWSER'
          ? 'Google Pay'
          : paymentWebType === 'GOOGLE_PAY'
          ? 'Google Pay'
          : paymentWebType === 'LINK'
          ? 'Google Pay'
          : paymentWebType === 'BROWSER_CARD'
          ? 'Google Pay'
          : null;
      this.pay_on_collection =
        paymentType === 'collectedPay' || paymentType === 'driverPay' ? true : false;
    } else {
      this.selectedCard = selectedCard;
    }
    // if (!cutoffTime) {
    // 	errors.push('Cutoff time empty')
    // }

    if (!this.isCollectionTimeStillValid()) {
      errors.push(errorMessages.basketNotValid);
      this._isCollectionTimeStillValid();
    }

    if (errors.length > 0) {
      errors.forEach((e) => this.toastMessage(e));
      throw errors;
    }
    let rewardCost = 0;
    if (vouchersType === 2) {
      this.applied_vouchers.forEach((i) => (rewardCost += i.cost));
    } else if (vouchersType === 3) {
      if (this.applied_vouchers.type === 2) {
        this.applied_vouchers.forEach((i) => (rewardCost += i.cost));
      }
    }
    const parsedItems = items;
    return {
      applicable_vouchers: applicable_vouchers,
      items: parsedItems,
      payment_token: this.selectedCard,
      pay_on_collection: this.pay_on_collection,
      discount_applied: pointsApplied + rewardCost,
      business_location_id: restaurant.business_location_id,
      collection_time:
        typeof collection_time === 'number' ? collection_time * 1000 : collection_time,
      mobile: mobile,
      currency: this.getSelectedCurrency(),
      order_type,
      delivery_option,
      delivery_address,
      pick_up_point,
      is_gift,
      delivery_price: new BigNumber(delivery_price).times(100).toNumber(),
      // properties for delete (later we will calculate total on BO)
      _total: totalWithTaxes,
      total: Math.ceil(new BigNumber(totalWithTaxes).times(100).toNumber()), //cents
      min_order,
      allergen_data,
      cutoffTime: cutoffTime * 1000,
      table_name: table_name || null,
      service_charge_percentage: service_percentage,
      _service_charge_value: service_charge,
      service_charge_value: new BigNumber(service_charge).times(100).toNumber(), //cents,
      passed_order,
      process_fee_value: new BigNumber(process_fee_value).times(100).toNumber(),
      subscription_discount: this.toCents(this.calculateMembershipDiscount()),
      subscription_price: this.toCents(this.membershipSubscription()),
      discount_name: discount?.title || null,
      restaurant_delivery_price,
      custom_field: custom_field || {},
      is_asap,
      total_taxes: new BigNumber(itemsTax || 0).times(100).toNumber(),
    };
  };

  toCents = (price) => Math.round(new BigNumber(price).times(100).toNumber());

  // ORDER HISTORY RELATED METHODS ----------------------------------------------------------------------------------------

  recreateOrder = (orderFromHistory) => {
    if (orderFromHistory) {
      const {
        items,
        payment_token,
        mobile,
        collection_time,
        discount_applied,
        discount,
        delivery_price,
        process_fee_value,
        delivery_address,
        applied_vouchers,
        table_name,
        service_charge_percentage,
        service_charge_value,
        subscription_discount,
        discount_name,
        is_gift,
        restaurant_delivery_price,
        custom_field,
        total_taxes,
      } = orderFromHistory;
      this.items = items || [];
      this.applied_vouchers = applied_vouchers || [];
      this.applicable_vouchers = applied_vouchers || [];
      this.selectedCard = payment_token || '';
      this.mobile = mobile;
      this.collection_time = collection_time;
      this.pointsApplied = discount_applied || vouchersType !== 2 ? discount : 0;
      this.setGift(is_gift);
      this.order_type = this.getOrderType(orderFromHistory);
      this.delivery_price =
        !isDefined(delivery_price) || delivery_price === 0
          ? 0
          : new BigNumber(delivery_price).div(100).toNumber();
      this.process_fee_value = process_fee_value;
      this.delivery_address = delivery_address;
      this.instanceStateChanged(false, true);
      // probably will need table_order here as well
      this.table_name = table_name;
      this.orderFromHistory = orderFromHistory;
      this.service_percentage = service_charge_percentage;
      this.service_charge_value = BigNumber(service_charge_value).div(100).toNumber();
      if (subscription_discount) {
        this.discount = {
          title: discount_name || 'Club',
          calculate_discount: (x) => subscription_discount / 100,
        };
      }
      this.calculateSubTotal();
      this.calculateTotal();
      this.restaurant_delivery_price = restaurant_delivery_price;
      this.custom_field = custom_field;
      this.itemsTax = total_taxes;
    }
  };

  parseBasketForCheckVouchers = () => {
    const business_location_id = this.restaurant ? this.restaurant.business_location_id : null;
    const menu_id = this.restaurant ? this.restaurant.menu_id : null;
    const parsedDiscountData = {
      items: this.items.map((item) => {
        const selectedSubItems = [];
        item.selectedChoices.forEach((selectedChoiceGroup) => {
          selectedChoiceGroup.forEach((item) => selectedSubItems.push(item));
        });
        let parsedItem = {
          qty: item.quantity,
          productPrice: isDefined(item.item.productPrice) ? item.item.productPrice : '0.00',
          name: isDefined(item.item.productName) ? item.item.productName : '',
          sku: isDefined(item.item.sku) ? item.item.sku : '',
          sub_items: selectedSubItems.map((selectedSubItem) => {
            return { productPrice: selectedSubItem.productPrice, sku: selectedSubItem.sku };
          }),
        };
        if (isDefined(item.item.sku)) {
          parsedItem.sku = item.item.sku;
        }
        return parsedItem;
      }),
      total: new BigNumber(this.total).minus(this.calculateAppliedVocuhersPrice(true)).toNumber(),
      vouchersType: vouchersType,
      restaurant: {
        business_location_id: business_location_id,
        menu_id: menu_id,
      },
    };

    return parsedDiscountData;
  };

  getDate = (date) => {
    if (date && typeof date === 'string') {
      const utcOffset = moment(date).utcOffset();
      return moment(date).add('minutes', utcOffset);
    } else {
      return moment();
    }
  };

  formatOrderTime = (flag, format = null) => {
    let time = null;
    if (flag) {
      time = this.collection_time
        ? this.getDate(this.collection_time).format(format ? format : 'ddd DD MMMM YYYY [at] LT')
        : '';
    } else {
      time = this.collection_time
        ? this.getDate(this.collection_time).format(format ? format : 'ddd DD MMMM YYYY [at] LT')
        : '';
    }
    if (time.indexOf('pm') !== -1) {
      time = time.replace(/ pm/g, '\u00A0pm');
    } else if (time.indexOf('PM') !== -1) {
      time = time.replace(/ PM/g, '\u00A0PM');
    }
    if (time.indexOf('am') !== -1) {
      time = time.replace(/ am/g, '\u00A0am');
    } else if (time.indexOf('AM') !== -1) {
      time = time.replace(/ AM/g, '\u00A0AM');
    }
    return time;
  };

  formatPaymentMethod = (cards = [], __, orderCompletePage) => {
    let paymentMethod = '';
    const paymentType = getConfig().payment;
    if (paymentType === 'judopay') {
      const usedCard = cards.find((card) => card.cardToken === this.selectedCard);
      if (usedCard) {
        const { cardType, cardLastFour } = usedCard;
        paymentMethod = orderCompletePage
          ? `${cardType}  **** ${cardLastFour}`
          : `${cardType}  **** ${cardLastFour}`;
      }
      return paymentMethod;
    } else {
      const usedCard = cards.find((card) => card.id === this.selectedCard);
      if (usedCard) {
        const { brand, last4 } = usedCard;
        paymentMethod = orderCompletePage ? `${brand}  **** ${last4}` : `${brand}  **** ${last4}`;
      } else {
        if (
          this.orderFromHistory &&
          ['Apple Pay', 'apple'].indexOf(this.orderFromHistory.payment_token) !== -1
        ) {
          paymentMethod = 'Apple Pay';
        } else if (
          this.orderFromHistory &&
          ['Google Pay', 'google'].indexOf(this.orderFromHistory.payment_token) !== -1
        ) {
          paymentMethod = 'Google Pay';
        } else if (
          this.orderFromHistory &&
          ['Pay on collection', 'collectedPay'].indexOf(this.orderFromHistory.payment_token) !== -1
        ) {
          paymentMethod = 'Pay on collection';
        } else if (
          this.orderFromHistory &&
          ['Pay to the driver', 'driverPay'].indexOf(this.orderFromHistory.payment_token) !== -1
        ) {
          paymentMethod = 'Pay to the driver';
        }
      }
      return paymentMethod;
    }
  };

  toastMessage = (message = '', type = 'warning') => store.dispatch(showToast(message, type));

  _isCollectionTimeStillValid = (applyActions = true) => {
    if (
      enableCollectionTimeValidation &&
      this.collection_time &&
      !this.table_name &&
      !this.is_asap
    ) {
      const collection_time = this.collection_time * 1000;
      const currentTime = Date.now();
      // const currentTime = 1587742235001
      if (collection_time < currentTime) {
        if (applyActions) {
          this.reset();
          forwardTo('/click-and-collect');
          this.log(errorMessages.basketNotValid);
        }
        return false;
      }
    }
    return true;
  };

  isCollectionTimeStillValid = () => this._isCollectionTimeStillValid(false);

  flattenMenuItems = (menu) => {
    let flatMenu = [];

    menu.forEach((item) => {
      if (item.menuEntry && item.menuEntry.length > 0) {
        flatMenu.push(...this.flattenMenuItems(item.menuEntry));
      } else {
        if (item.sku) {
          flatMenu.push(item);
        }
        return;
      }
    });

    return flatMenu;
  };

  calculateDeliveryCharge = (total) => {
    const restaurant = this.getRestaurant();
    const deliveryChargeisPercent = restaurant?.delivery_charge_in_percent;
    let deliveryPrice = this.delivery_price;
    let restaurantDeliveryPrice = this.restaurant_delivery_price;
    if (deliveryChargeisPercent && restaurantDeliveryPrice) {
      deliveryPrice = total * (restaurantDeliveryPrice / 100);
      deliveryPrice = deliveryPrice.toFixed(2);
    }
    this.delivery_price = deliveryPrice;
    return deliveryPrice;
  };
}

export const createNewBasketInstance = () => new Basket();

export default new Basket();
