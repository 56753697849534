import React from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonButton,
} from '@ionic/react';
import { withTranslation } from '../../lib/translate';
// import moment from '../../lib/moment'
import { withRouter } from 'react-router';
import { SmallText, Spacer, StrongText, Subtitle } from '../../components/common';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import orderIcon from '../../assets/images/order.svg';
import loyaltyIcon from '../../assets/images/loyalty.svg';
import { forwardTo, isDefined } from '../../lib/utils';
import { connect } from 'react-redux';
import { setDeliveryOption, showToast } from '../../store/actions';

const doRefresh = (event, getTransactionHistory) => {
  setTimeout(() => {
    event.detail.complete();
    getTransactionHistory();
  }, 1000);
};

const HistoryTab = withRouter(
  ({
    __,
    transactionHistory,
    getTransactionHistory,
    history,
    clientLogo,
    restaurants,
    dispatch,
    ...rest
  }) => {
    const type = rest.type || 'order';

    const handleRowClick = (item) => {
      if (type === 'order') {
        history.push('./history-details', { order: item });
      } else {
        // history.push('./history-details')
      }
    };
    const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];

    const orderAgain = (past_orders, option) => {
      const restaurant = restaurants.filter(
        (restaurant) => restaurant.id === past_orders.restaurant_id,
      );
      if (restaurant.length > 0) {
        Basket.reset();
        Basket.setDeliveryOption(option);
        forwardTo(option.route, { selectedRestaurant: past_orders });
      } else {
        dispatch(showToast('This restaurant is no longer receiving orders.', 'warning'));
      }
    };
    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className={`history-content ${type !== '' ? `${type}-tab` : ''}`}>
          {(transactionHistory || []).length === 0 ? (
            type === 'order' ? (
              <div className="no-data-history">
                <img alt=" " src={clientLogo} />
                <Spacer size={4} />
                <span>{__('Your order history will appear here')}</span>
              </div>
            ) : (
              <div className="no-data-history">
                <img alt=" " src={clientLogo} />
                <Spacer size={4} />
                <span>{__('Your loyalty history will appear here')}</span>
              </div>
            )
          ) : (
            <IonGrid class="box-wrapper ">
              {transactionHistory.map((i, index) => {
                const { stamp_power, transaction_date, label, item, location_name } = i;
                const withoutZ = transaction_date.replace('Z', '');
                const transDate = Basket.getDate(withoutZ);
                let orderLabel = Basket.getOrderType(i.item);
                let option = (delivery || []).find((d) => d.id === orderLabel);
                let orderId = i && i.item ? ' #' + i.item.id : '';
                let status = item && item.status ? item.status : '';
                let locationName =
                  location_name && location_name !== ''
                    ? location_name
                    : i && i.item
                    ? i.item.restaurant_name
                    : '';
                return status === 'CREATED' ||
                  status === 'created' ||
                  status === 'new' ||
                  status === 'NEW' ? null : (
                  <IonRow key={index} className="history-item">
                    <IonCol onClick={() => handleRowClick(item)}>
                      <SmallText>{!item?.is_gift && locationName}</SmallText>
                      <StrongText className="block uppercase">
                        {!item?.is_gift ? (
                          <>
                            {type === 'order'
                              ? option
                                ? __(option.label) + orderId
                                : ''
                              : __(label)}
                          </>
                        ) : (
                          <span>
                            {__('eGift Voucher')} {orderId}
                          </span>
                        )}
                      </StrongText>
                      <SmallText className="ellipsis">
                        {__(transDate.format('ddd')) +
                          transDate.format(' DD ') +
                          __(transDate.format('MMMM')) +
                          transDate.format(' YYYY ') +
                          __('at') +
                          transDate.format(' h:mm a')}
                      </SmallText>
                    </IonCol>
                    {!item?.is_gift && (
                      <>
                        {type === 'order' ? (
                          <IonCol
                            style={{ alignSelf: 'center', textAlign: 'end' }}
                            className="order-button-col-width"
                          >
                            <IonButton color="secondary" onClick={() => orderAgain(item, option)}>
                              {__('Order Again')}
                            </IonButton>
                          </IonCol>
                        ) : (
                          <IonCol
                            style={{
                              alignSelf: 'center',
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <div className="transaction-points-wrapper">
                              <Subtitle
                                className={
                                  stamp_power < 0 ? 'bold danger-color' : 'bold secondary-color'
                                }
                              >
                                {stamp_power < 0 ? '-' : '+'}
                                {Math.abs(stamp_power)}
                              </Subtitle>
                              <SmallText className="secondary-color">{__('points')}</SmallText>
                            </div>
                          </IonCol>
                        )}
                      </>
                    )}
                  </IonRow>
                );
              })}
            </IonGrid>
          )}
        </div>
      </>
    );
  },
);
const stateToProps = (store) => {
  return {
    ikentooMenu: store.restaurants.ikentooMenu || {},
    profile: store.profile.profile,
  };
};

export default connect(stateToProps)(withTranslation(HistoryTab));
