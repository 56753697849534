import React from 'react';
import { withRouter } from 'react-router';
import { IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import { validateProfileData } from '../../lib/utils';
import { NormalText, SmallText, StrongText } from '../common';
import { setModal } from '../../store/actions';
import { getConfig } from '../../appConfig';
import './index.css';

const check = require('../../assets/images/Deli_Check.svg');

const ValidateButton = ({ __, dispatch, profile, auth }) => {
  const isAuth = auth.loggedIn;
  const valid = validateProfileData(profile).isValid;
  if (!getConfig().appType.hasEmailValidationEnabled) {
    return null;
  }
  return (
    // <>      {isAuth && !valid ? (
    //   <>
    //     <div className='validate-button'>
    //       <NormalText className='validate-status' >{__('Not Verified')}</NormalText>
    //       <StrongText onClick={() => dispatch(setModal('isVerfiedModalOpen', true))} className='pointer secondary-color underlined'>{__('Verify Account')}</StrongText>
    //     </div>
    //   </>
    // ) : isAuth && valid ? (
    //   <div className='validate-button valid'>
    //     <NormalText className='validate-status' >{__('Verified')}</NormalText>
    //   </div>
    // ) : null}</>
    <>
      {isAuth && !valid ? (
        <>
          <IonGrid className="validate-button-wrapper bottom unverified validate-button">
            <IonRow onClick={() => dispatch(setModal('isVerfiedModalOpen', true))}>
              <IonCol className="">
                <NormalText className="validate-status">{__('Not Verified')}</NormalText>
                <StrongText
                  onClick={() => dispatch(setModal('isVerfiedModalOpen', true))}
                  className="pointer secondary-color underlined"
                >
                  {__('Verify Account')}
                </StrongText>{' '}
              </IonCol>
            </IonRow>
          </IonGrid>
        </>
      ) : isAuth && valid ? (
        <IonGrid className="validate-button-wrapper validate-button valid">
          <IonRow>
            <IonCol className="">
              <NormalText className="validate-status">{__('Verified')}</NormalText>
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { isVerfiedModalOpen, profile, auth } = store.profile;
  return {
    isVerfiedModalOpen,
    profile,
    auth,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(ValidateButton)));
