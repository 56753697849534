import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import './index.css';
import {
  isDefined,
  isEmptyObject,
  forwardTo,
  getSingleDeliveryOption,
  checkBackgroundColor,
} from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { NormalText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import HistoryOrderBar from '../../components/historyOrderBar';
import api from '../../lib/api';

const { hasOrdering } = getConfig().appType;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      singleDelivery: null,
      image: '',
      backgorundColor: false,
    };
  }

  async componentDidMount() {
    const styles = this.props.clientStyles.colors?.['--okx-dashboard-background'];
    if (styles) {
      this.setState({ backgorundColor: styles });
    }
    const { clientProfile } = this.props;
    getSingleDeliveryOption().then((res) => {
      this.setState({
        singleDelivery: res,
        image: clientProfile.main_image,
      });
    });
  }

  render() {
    const { __, screenName, orderHistory, clientProfile } = this.props;
    const { singleDelivery, image } = this.state;
    const dashboardContent = getConfig().dashboardConfig;
    return (
      <>
        <Layout
          headerIconColors={
            this.state.backgorundColor ? checkBackgroundColor(this.state.backgorundColor) : 'white'
          }
          color="transparent"
          noPadding={true}
        >
          {orderHistory.filter(
            (el) =>
              !el.is_gift &&
              el.status.toLowerCase() !== 'new' &&
              el.status.toLowerCase() !== 'created',
          ).length > 0 && (
            <HistoryOrderBar
              label={__('Order again')}
              handleClick={() => forwardTo('./history', { tab: 'order' })}
            />
          )}
          <div className="dashboard-content">
            <div
              className="dashboard-header"
              style={orderHistory.length > 0 ? null : { paddingTop: '10px' }}
            >
              <Subtitle className="uppercase bold letter-spacing-big">{__('Loyalty')}</Subtitle>
              <Title>
                {__('Welcome')} {screenName}
              </Title>
              <NormalText>{__(dashboardContent.description)}</NormalText>
            </div>
            <div className="dashboard-cards-wrapper">
              {dashboardContent.data.map((item) => {
                return (
                  <div
                    style={{ backgroundImage: `url(${item.image})` }}
                    className="dashboard-card "
                    onClick={() => forwardTo(item.path)}
                  >
                    <Subtitle>{__(item.title)}</Subtitle>
                  </div>
                );
              })}
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { clientProfile, clientStyles } = state.common;
  const { orderHistory } = state.orders;

  let screenName = '';
  if (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) {
    screenName = profile.first_name;
  }
  return {
    screenName,
    clientProfile,
    orderHistory,
    clientStyles,
  };
};

export default connect(stateToProps)(withTranslation(Dashboard));
