import React from 'react';
import { IonCheckbox, IonItem, IonLabel, IonNote } from '@ionic/react';
import Basket from '../../lib/basket';
import { NormalText, StrongText, Subtitle, Title } from '../common';

const { formatPrice } = Basket;

export const SubscriptionLine = (
  __,
  clickHandler,
  key,
  isChecked,
  name,
  description,
  price,
  perks,
) => {
  return (
    <IonItem className="box-content " lines="none" key={key}>
      <div className="subscription-item-wrapper">
        <div className="subscription-header">
          <IonCheckbox color="secondary" slot="start" checked={isChecked} onClick={clickHandler} />
          <div className="subscription-information">
            <Subtitle>{name}</Subtitle>
            <NormalText>{description}</NormalText>
          </div>
          <div>
            <Subtitle>{formatPrice(price)}</Subtitle>
            <NormalText>{__('per month')}</NormalText>
          </div>
        </div>
        <div className="subscription-additional-data">
          {perks?.map((el) => (
            <div>
              <Subtitle>{el.name}</Subtitle>
              <NormalText>{el.description}</NormalText>
            </div>
          ))}
        </div>
      </div>
    </IonItem>
  );
};
