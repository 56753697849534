import React from 'react';
import { StrongText } from '../common';
import './index.css';

const HistoryOrderBar = ({ label, onDismiss, handleClick }) => (
  <div className="history-order-bar-wrapper promo-bar-wrapper">
    <div className="history-order-bar-scrim" onClick={onDismiss ? onDismiss : null}></div>
    <div className="promo-bar info" onClick={handleClick}>
      <StrongText className="capitalized history-order-bar-label">{label}</StrongText>
    </div>
  </div>
);

export default HistoryOrderBar;
