import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonToggle,
  IonCheckbox,
} from '@ionic/react';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import {
  FieldError,
  Title,
  SmallText,
  NormalText,
  Spacer,
  StrongText,
} from '../../components/common';
import { validateForm, forwardTo } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { setGuestUser, updateProfile } from '../../store/actions';
import Basket from '../../lib/basket';
import './index.css';

const { getMobile, setMobile } = Basket;

class ContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.profile.first_name || '',
      last_name: this.props.profile.last_name || '',
      mobile: getMobile() || this.props.profile.mobile || '',
      formErrors: {},
      guest_first_name: this.props.guestUserData.guest_first_name || '',
      guest_email: this.props.guestUserData.guest_email || '',
      guest_accepted_terms_and_conditions:
        this.props.guestUserData.guest_accepted_terms_and_conditions || false,
      guest_mobile: this.props.guestUserData.guest_mobile || '',
      guestFormErrors: {},
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.formConfig = {
      first_name: { type: 'first_name', required: true },
      mobile: { type: 'tel', required: true },
    };
    this.guestValidationConfig = {
      guest_first_name: { type: 'first_name', required: true },
      guest_mobile: { type: 'tel', required: true },
      guest_email: { type: 'email', required: true },
      guest_accepted_terms_and_conditions: { type: 'toggle', toggle: true },
    };
  }

  componentDidMount() {
    if (this.state.first_name !== '' && this.state.mobile !== '') {
      forwardTo('/checkout', { skipContactDetails: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.first_name !== prevProps.profile.first_name) {
      this.setState({ first_name: this.props.profile.first_name });
    }
    if (this.props.profile.last_name !== prevProps.profile.last_name) {
      this.setState({ last_name: this.props.profile.last_name });
    }
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({
        mobile: this.props.profile.mobile ? this.props.profile.mobile : getMobile(),
      });
    }
  }

  handleInput = (key, val) => {
    if (key === 'mobile' || key === 'guest_mobile') {
      setMobile(val);
    }
    this.setState({ [key]: val });
  };

  handleSave() {
    const { cards } = this.props;
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { first_name, last_name, mobile } = this.state;
      const profile = {
        first_name: first_name,
        last_name: last_name,
        mobile: mobile ? mobile : getMobile(),
      };
      if (mobile) {
        this.props.dispatch(updateProfile(profile, true));
        forwardTo('/checkout');
      } else {
        forwardTo('/checkout');
      }
    }
  }
  handleGuestSave = () => {
    const { dispatch } = this.props;
    let guestFormErrors = validateForm(this.guestValidationConfig, this.state);
    this.setState({ guestFormErrors });

    if (Object.keys(guestFormErrors).length === 0) {
      const {
        guest_email,
        guest_first_name,
        guest_mobile,
        guest_accepted_terms_and_conditions,
      } = this.state;

      const guestUserData = {
        guest_email,
        guest_first_name,
        guest_mobile,
        guest_accepted_terms_and_conditions,
      };
      dispatch(setGuestUser(guestUserData));
      forwardTo('/create-account');
    }
  };
  render() {
    const { __, auth } = this.props;
    const {
      first_name,
      last_name,
      mobile,
      formErrors,
      guest_first_name,
      guest_mobile,
      guest_email,
      guest_accepted_terms_and_conditions,
      guestFormErrors,
    } = this.state;

    return (
      <Loading transparent>
        <Layout color="transparent" headerWithTitle={true} headerTitle="Contact Details">
          <div className="absolute-content flex-row-wrapper">
            <div className="scrollable-y checkout">
              <Title className="web-only">{__('Contact Details')}</Title>
              <NormalText>
                {__(
                  'To complete your order, please provide a few details in case we need to contact you',
                )}
              </NormalText>
              <Spacer size={2} />
              {this.props.auth && this.props.auth.loggedIn ? (
                <IonList>
                  <div className="input-field-container">
                    <NormalText>{__('First Name')}</NormalText>
                    <IonItem lines="none" className="input-field-wrapper">
                      <IonInput
                        onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={first_name}
                      ></IonInput>
                    </IonItem>
                  </div>
                  {formErrors.first_name ? (
                    <FieldError className="field-error" value={__(formErrors.first_name)} />
                  ) : null}
                  <div className="input-field-container">
                    <NormalText>{__('Last Name')}</NormalText>
                    <IonItem lines="none" className="input-field-wrapper">
                      <IonInput
                        onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={last_name}
                      ></IonInput>
                    </IonItem>
                  </div>
                  <div className="input-field-container">
                    <NormalText>{__('Mobile Number')}</NormalText>
                    <IonItem lines="none" className="input-field-wrapper">
                      <IonInput
                        onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                        clearInput
                        required={true}
                        type="tel"
                        pattern="tel"
                        inputmode="tel"
                        value={mobile}
                      ></IonInput>
                    </IonItem>
                  </div>
                  {formErrors.mobile ? (
                    <FieldError className="field-error" value={__(formErrors.mobile)} />
                  ) : null}
                </IonList>
              ) : (
                <IonList>
                  <div className="input-field-container">
                    <NormalText>{__('First Name')}</NormalText>
                    <IonItem lines="none" className="input-field-wrapper">
                      <IonInput
                        onIonChange={(e) => this.handleInput('guest_first_name', e.target.value)}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={guest_first_name}
                      ></IonInput>
                    </IonItem>
                  </div>
                  {guestFormErrors.guest_first_name ? (
                    <FieldError
                      className="field-error"
                      value={__(guestFormErrors.guest_first_name)}
                    />
                  ) : null}
                  <div className="input-field-container">
                    <NormalText>{__('Email')}</NormalText>
                    <IonItem lines="none" className="input-field-wrapper">
                      <IonInput
                        onIonChange={(e) => this.handleInput('guest_email', e.target.value.trim())}
                        clearInput
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={guest_email}
                      ></IonInput>
                    </IonItem>
                  </div>
                  {guestFormErrors.guest_email ? (
                    <FieldError className="field-error" value={__(guestFormErrors.guest_email)} />
                  ) : null}
                  <div className="input-field-container">
                    <NormalText>{__('Mobile')}</NormalText>
                    <IonItem lines="none" className="input-field-wrapper">
                      <IonInput
                        onIonChange={(e) => this.handleInput('guest_mobile', e.target.value)}
                        clearInput
                        required={true}
                        type="tel"
                        pattern="tel"
                        inputmode="tel"
                        value={guest_mobile}
                      ></IonInput>
                    </IonItem>
                  </div>
                  {guestFormErrors.guest_mobile ? (
                    <FieldError className="field-error" value={__(guestFormErrors.guest_mobile)} />
                  ) : null}
                  <Spacer size={1} />
                  <div lines="none">
                    <div tabIndex="-1"></div>
                    <div className="toggle">
                      <IonLabel>
                        <StrongText className="roboto-slab">{__('Terms & Conditions')}</StrongText>
                        <div className="register-terms-wrapper">
                          <IonCheckbox
                            color="secondary"
                            checked={guest_accepted_terms_and_conditions}
                            onIonChange={(e) =>
                              this.handleInput(
                                'guest_accepted_terms_and_conditions',
                                e.detail.checked,
                              )
                            }
                          />
                          <IonLabel className="ion-text-wrap">
                            <NormalText color="primary">
                              {__('By signing up you agree to') + ' '}{' '}
                              <span
                                className="pointer underlined secondary-color"
                                onClick={() => forwardTo('/terms')}
                              >
                                {__('our terms and conditions')}
                              </span>{' '}
                              {__('and')}{' '}
                              <span
                                className="secondary-color  pointer underlined"
                                onClick={() => forwardTo('/privacy')}
                              >
                                {__('privacy policy')}
                              </span>
                            </NormalText>
                          </IonLabel>
                        </div>
                      </IonLabel>
                      <FieldError
                        className="field-error"
                        value={__(guestFormErrors.guest_accepted_terms_and_conditions)}
                      />{' '}
                    </div>
                  </div>
                </IonList>
              )}
              <IonButton
                onClick={
                  this.props.auth && this.props.auth.loggedIn
                    ? this.handleSave
                    : this.handleGuestSave
                }
                expand="block"
                color="secondary"
              >
                {__('Checkout')}
              </IonButton>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile, guestUserData } = state.profile;
  const { cards } = state.orders;
  return {
    auth,
    profile,
    cards,
    guestUserData,
  };
};

export default connect(stateToProps)(withTranslation(ContactDetails));
