import React from 'react';
import { withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonBadge,
  IonIcon,
} from '@ionic/react';
import { basket, scan } from 'ionicons/icons';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import Icon from '../icon';
import BackButton from '../backButton';
import {
  checkBackgroundColor,
  forwardTo,
  getDefaultRoute,
  getRouteClassName,
  isWebConfig,
} from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import './index.css';
import { Title } from '../common';
import api from '../../lib/api';

const StaticHeader = ({
  __,
  history,
  backHandler,
  clientProfile,
  navConfig,
  showHamburger,
  headerWithTitle,
  title,
  headerIconColors,
  clientStyles,
}) => {
  const currentPath = history.location.pathname;
  const authPages = getConfig().general.authRoutes.indexOf(currentPath) !== -1;
  const hideStaticHeader = getConfig().theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
  const pagesWithoutBackButton =
    getConfig().general.routesWithoutBackButton.indexOf(currentPath) !== -1;
  const routesWithBackButtonAndHamburger =
    getConfig().general.routesWithBackButtonAndHamburger.indexOf(currentPath) !== -1;
  const routeClassName = getRouteClassName(currentPath, navConfig);

  const defaultIconsColor = clientStyles?.colors?.['--okx-background-color']
    ? checkBackgroundColor(clientStyles?.colors['--okx-background-color'])
    : 'dark';
  let logoMain;
  if (headerIconColors) {
    if (headerIconColors === 'white') {
      logoMain = clientProfile.logo_image_light || clientProfile.logo_image || '';
    } else if (headerIconColors === 'dark') {
      logoMain = clientProfile.logo_image || '';
    }
  } else {
    if (defaultIconsColor === 'white') {
      logoMain = clientProfile.logo_image_light || clientProfile.logo_image || '';
    } else if (defaultIconsColor === 'dark') {
      logoMain = clientProfile.logo_image || '';
    }
  }
  return (
    <>
      {hideStaticHeader ? null : getConfig().theme.showHeaderOnAuthRoutes || !authPages ? (
        <IonHeader className={'static ' + routeClassName}>
          <IonToolbar
            className={`${headerWithTitle ? 'no-logo' : ''} primary-toolbar`}
            style={{
              backgroundImage: !isWebConfig() && !headerWithTitle ? `url(${logoMain})` : '',
              backgroundSize: 'auto 35px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            {routesWithBackButtonAndHamburger && (
              <IonButtons slot="start">
                {showHamburger ? (
                  <IonMenuButton color={headerIconColors ? headerIconColors : defaultIconsColor} />
                ) : (
                  <BackButton
                    iconColor={headerIconColors ? headerIconColors : defaultIconsColor}
                    backHandler={backHandler}
                  />
                )}
              </IonButtons>
            )}
            {!routesWithBackButtonAndHamburger && (
              <IonButtons slot="start">
                {!authPages && pagesWithoutBackButton && !routesWithBackButtonAndHamburger ? (
                  <IonMenuButton color={headerIconColors ? headerIconColors : defaultIconsColor} />
                ) : (
                  <>
                    <BackButton
                      iconColor={headerIconColors ? headerIconColors : defaultIconsColor}
                      backHandler={backHandler}
                    />
                  </>
                )}
              </IonButtons>
            )}

            {!headerWithTitle ? (
              <IonButtons color="primary">
                <IonButton
                  className="image-button"
                  color="primary-shade"
                  onClick={() => forwardTo(getDefaultRoute(navConfig).path)}
                />
              </IonButtons>
            ) : (
              <IonButton
                color="transparent"
                className="header-title"
                onClick={() => forwardTo('/dashboard')}
              >
                <Title className={`${defaultIconsColor}-header-title`}>{title}</Title>
              </IonButton>
            )}
            <IonButtons slot="end">
              {Basket.itemsCount() > 0 && !Basket.is_gift ? (
                <IonButton
                  color={headerIconColors ? headerIconColors : defaultIconsColor}
                  className="basket-button"
                  onClick={() => forwardTo('/order-summary')}
                  size={24}
                >
                  <div>
                    <div className="basket-icon-wrapper">
                      <IonBadge
                        slot="end"
                        color={headerIconColors ? headerIconColors : defaultIconsColor}
                        className={Basket.itemsCount() >= 10 ? 'badge' : 'badge-small'}
                      >
                        {Basket.itemsCountAll()}
                      </IonBadge>
                      <Icon
                        color={headerIconColors ? headerIconColors : defaultIconsColor}
                        icon={basket}
                        classname="icon"
                      />
                    </div>
                    <div>{/* <SmallText color="gray">
													{ Basket._getTotal() }
												</SmallText> */}</div>
                  </div>
                </IonButton>
              ) : !authPages && !isWebConfig() ? (
                getConfig().flags.hasLoyalty ? (
                  <IonButton
                    color={headerIconColors ? headerIconColors : defaultIconsColor}
                    button
                    clear
                    onClick={() => forwardTo('/loyalty', { tab: 'scan' })}
                  >
                    <IonIcon
                      color={headerIconColors ? headerIconColors : defaultIconsColor}
                      slot="icon-only"
                      icon={scan}
                    />
                  </IonButton>
                ) : null
              ) : null}
            </IonButtons>
          </IonToolbar>
          {/* <IonToolbar color="transparent" /> */}
        </IonHeader>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { orders } = store;
  const { clientProfile, navConfig, clientStyles } = store.common;
  return {
    basketUpdated: orders.basketUpdated,
    clientProfile,
    navConfig,
    clientStyles,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
