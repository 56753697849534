import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { IonAlert } from '@ionic/react';
import { isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { removeAlert } from '../../store/actions';

class Alert extends Component {
  showAlert = () => {
    const { message, __, removeAlert } = this.props;
    return (
      <IonAlert
        isOpen={true}
        onDidDismiss={() => {
          removeAlert(); // removes original toast
          removeAlert(); // removes separator toast (empty one)
        }}
        message={__(message)}
        buttons={[
          {
            text: __('Close'),
            role: 'cancel',
            handler: () => removeAlert(),
          },
        ]}
      />
    );
  };

  render() {
    const { message, __ } = this.props;
    return isDefined(message) && message !== '' ? this.showAlert() : null;
  }
}

const stateToProps = (state) => {
  const { alert } = state.common;
  let message = '';

  if (alert?.length > 0) {
    message = alert[0].message;
  }

  return {
    message,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    removeAlert: () => dispatch(removeAlert()),
  };
};

export default connect(stateToProps, dispatchToProps)(withRouter(withTranslation(Alert)));
