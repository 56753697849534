import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { IonButton, IonIcon, IonList } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { getSubscriptions } from '../../store/subscription/actions';
import { setSelectedSubscription } from '../../store/orders/actions';
import { forwardTo, go } from '../../lib/utils';
import { SubscriptionLine } from '../../components/subscription-item';
import { addCircleOutline } from 'ionicons/icons';
import {
  NormalText,
  SmallText,
  Spacer,
  StrongText,
  Subtitle,
  Title,
} from '../../components/common';
import { loading } from '../../store/actions';
const handleMembership = () => forwardTo('/membership-terms');

const ChooseSubscription = ({
  __,
  subscriptions,
  selectedSubscription,
  activeSubscription,
  getSubscriptions,
  setSelectedSubscription,
  location,
  history,
  clientProfile,
  auth,
}) => {
  useEffect(() => {
    getSubscriptions();
  }, []);

  if (
    activeSubscription &&
    subscriptions[0]?.packages.find((subscription) => subscription.id === activeSubscription.id)
  ) {
    forwardTo('/my-membership');
  }
  let imageUrl = null;

  const items = subscriptions[0]?.packages.map((e, idx) => {
    const isChecked = idx === selectedSubscription?.idx;
    if (imageUrl === null) {
      imageUrl = e.image;
    }
    const price = e.monthly_cost_inc_vat / 100;
    return SubscriptionLine(
      __,
      () => setSelectedSubscription({ id: e.id, idx }),
      idx,
      isChecked,
      e.name,
      e.description,
      price,
      e.perks,
    );
  });

  const fromChooseSubscriptionMenu = location?.state?.fromChooseSubscriptionMenu;
  const backHandler = () => {
    fromChooseSubscriptionMenu ? go(-2) : history.goBack();
  };
  return (
    <Loading transparent>
      <Layout
        color="transparent"
        scrollY={false}
        noPadding
        headerTitle={__('Membership')}
        headerWithTitle={true}
        hideSecondToolbar={true}
        backHandler={backHandler}
      >
        <div className="absolute-content scrollable-y">
          <img src={subscriptions[0]?.image} />

          <div className="centered subscription-content">
            <Spacer size={1} />
            <Subtitle className="block bold">{subscriptions[0]?.name}</Subtitle>
            <NormalText>{subscriptions[0]?.description}</NormalText>
            <Spacer size={2} />

            <div className="subscription-list">
              <IonList>{items}</IonList>
              <Spacer size={1} />
              <IonButton
                disabled={!selectedSubscription}
                onClick={handleMembership}
                expand="block"
                color="secondary"
                className={'checkout-btn '}
              >
                {__('JOIN NOW')}
              </IonButton>
            </div>
          </div>
        </div>
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  subscriptions: store.subscription.subscriptions || [],
  selectedSubscription: store.orders.selectedSubscription,
  activeSubscription: store.profile.profile.active_subscription,
  clientProfile: store.common.clientProfile,
  auth: store.profile.auth,
});

const mapDispatchToProps = {
  setSelectedSubscription,
  getSubscriptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(ChooseSubscription));
