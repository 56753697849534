import React, { Component } from 'react';
import { IonGrid, IonRow, IonCol, IonButton, IonInput, IonItem } from '@ionic/react';
import { NormalText, SmallText, Spacer, Subtitle, Title } from '../common';
import { makeKey, isDefined, forwardTo, isWebConfig, getItemTax } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import './index.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ServiceCharge } from '../../screens/service-charge';
import Modal from '../modal';
import { getConfig } from '../../appConfig';
import { removeVoucher, setModal } from '../../store/actions';

class OrderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceChargeModalOpen: false,
      showCharge: false,
    };
  }

  handleServiceChargeModal = (flag) => {
    this.setState({ serviceChargeModalOpen: flag });
  };

  drawSubItems = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;

    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <>
              <IonRow
                style={{ paddingLeft: choice.bundleModifier ? '40px' : null }}
                className="sub-item"
                key={makeKey(
                  choiceGroupIndex,
                  choiceIndex,
                  sku,
                )} /*onClick={ () => this.handleOrderItemClick(basketItem, basketItemIndex) }*/
              >
                <IonCol size="50px"></IonCol>
                <IonCol className="paddLR grow">
                  {choice.quantity} x {Basket.getProductName(choice, profile)}
                </IonCol>
                <IonCol className="righted paddLR self-aligned">
                  {basketInstance.calculateSubItemPrice(choice, choice.quantity) !== 0
                    ? basketInstance._calculateSubItemPrice(choice, choice.quantity)
                    : null}
                </IonCol>
              </IonRow>
              {/* this.drawItemTaxes(choice, choiceGroupIndex, true) */}
            </>
          );
        });
      });
    }
    return null;
  };

  drawItemTaxes = (item = {}, index, subItem = false) => {
    const { __ } = this.props;
    const basketInstance = this.props.basketInstance || Basket;
    const orderType = basketInstance.getOrderTypeRaw();
    const itemTax = getItemTax(item, orderType);
    if (itemTax) {
      return (
        <IonRow
          style={{ paddingLeft: item.bundleModifier ? '40px' : null }}
          className={subItem ? 'sub-item' : 'item-tax'}
          key={index}
        >
          <IonCol size="50px"></IonCol>
          <IonCol className="paddLR grow">
            {itemTax.taxPercentage}% {__('Sale tax')}
          </IonCol>
          <IonCol className="righted paddLR self-aligned">
            {basketInstance.formatPrice(itemTax.itemTax)}
          </IonCol>
        </IonRow>
      );
    }
  };
  selectServicePercentage(serviceCharge) {
    const basketInstance = this.props.basketInstance || Basket;
    const serviceChargeApplied = basketInstance.getServicePercentage();
    return serviceCharge === serviceChargeApplied;
  }
  drawServiceChargeBox = (__, service_charge, basketInstance) => {
    return (
      <div className="service-charge-box-wrapper  centered">
        <div>
          {!getConfig().serviceChargeBoxHidden && (
            <NormalText>{__('Add a service charge?')}</NormalText>
          )}
          <NormalText>
            {basketInstance.getServiceChargeValue() > 0
              ? basketInstance.getFormatedServiceChargeValue()
              : basketInstance.getServiceCharge(true)}
          </NormalText>
        </div>
        <div className="service-charge-values">
          {service_charge.map((item) => {
            return (
              <IonButton
                className={this.selectServicePercentage(item) && 'is-active'}
                key={item + 'service_charge'}
                expand="block"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  document
                    .querySelectorAll('.service-charge-values ion-button')
                    .forEach((el) => el.classList.remove('is-active'));
                  e.target.classList.add('is-active');
                  basketInstance.setServicePercentage(item);
                }}
              >
                {item === 0 ? <strong>{__('No thanks')} </strong> : <strong>{item}% </strong>}
              </IonButton>
            );
          })}
        </div>

        <IonButton
          onClick={() =>
            this.setState({ serviceChargeModalOpen: true, serviceChargeBoxModalOpen: false })
          }
          fill="clear"
          className="link underlined"
        >
          {__('Add different amount')}
        </IonButton>
        <IonButton
          color="secondary "
          onClick={() => {
            basketInstance.setServicePercentage(0);
            this.setState({ serviceChargeBoxModalOpen: false });
          }}
          fill="clear"
          className="link underlined block decline-service-charge-btn"
        >
          {__('Not this time')}
        </IonButton>
      </div>
    );
  };
  setCustomServiceCharge = (value) => {
    if (value >= 0) {
      this.setState({ serviceChargeAmount: value, customAmountErrorVisible: false });
    } else {
      this.setState({ customAmountErrorVisible: true, serviceChargeAmount: 0 });
    }
  };
  render() {
    const {
      handleOrderItemClick,
      __,
      history,
      profile,
      showAddItems,
      dispatch,
      isRemoveVoucherModalOpen,
    } = this.props;
    const basketInstance = this.props.basketInstance || Basket;
    const type = this.props.type || 'order';
    const orderType = basketInstance.getOrderType();
    const appliedPoints = basketInstance.getAppliedPoints();
    const deliveryPrice = basketInstance.getDeliveryPrice();
    const deliveryPriceUnformated = basketInstance._getDeliveryPrice();
    const { serviceChargeModalOpen } = this.state;
    const appliedVoucher = basketInstance.getAppliedVocuher();
    let location =
      history && history.location && history.location.pathname === '/history-details'
        ? true
        : false;
    let service_charge_original;
    let service_charge = [];
    if (
      !basketInstance.service_charge_value &&
      basketInstance.service_charge_value !== 0 &&
      orderType === 'Table'
    ) {
      if (basketInstance.getRestaurantServiceCharge()) {
        service_charge_original = basketInstance.getRestaurantServiceCharge();
      } else {
        service_charge_original = JSON.parse(getConfig().general.defaultServiceCharge);
      }
      service_charge = service_charge_original.map((el) => parseInt(el * 100));
    }
    return (
      <>
        {getConfig().general.hasServiceCharge &&
          !basketInstance.service_charge_value &&
          basketInstance.service_charge_value !== 0 &&
          orderType === 'Table' && (
            <>
              {!getConfig().general.serviceChargeBoxHidden ? (
                <>{this.drawServiceChargeBox(__, service_charge, basketInstance)}</>
              ) : (
                <>
                  <Modal
                    className="service-charge-box-modal"
                    isOpen={this.state.serviceChargeBoxModalOpen}
                    onDidDismiss={() => this.setState({ serviceChargeBoxModalOpen: false })}
                  >
                    <Title>{__('Add Service Charge')}</Title>
                    <Spacer size={1} />
                    {this.drawServiceChargeBox(__, service_charge, basketInstance)}
                    <Spacer size={1} />
                    <IonButton
                      expand="block"
                      color="secondary"
                      onClick={() => {
                        this.setState({ serviceChargeBoxModalOpen: false });
                      }}
                    >
                      {__('Add Service Charge')}
                    </IonButton>
                  </Modal>
                </>
              )}
              <Spacer size={1} />
            </>
          )}
        <Subtitle className="bold order-summary-items-wrapper">{__('Items Ordered')}</Subtitle>
        <IonGrid className="box-wrapper order-content-wrapper paddL">
          <div className="add-items-button-wrapper">
            {showAddItems ? (
              <IonButton
                size="small"
                color="secondary"
                className="add-items-btn uppercase"
                onClick={() => forwardTo('/order')}
              >
                {__('Add Items')}
              </IonButton>
            ) : null}
          </div>
          {basketInstance.getItems().map((basketItem, basketItemIndex) => {
            const { quantity, item, instructions } = basketItem;
            return (
              <div
                key={basketItemIndex}
                className={`basket-item-wrapper basket-item-wrapper-${basketItemIndex}`}
              >
                <IonRow
                  key={makeKey(basketItemIndex, item.sku)}
                  onClick={() => {
                    if (handleOrderItemClick) {
                      handleOrderItemClick(basketItem, basketItemIndex);
                    }
                  }}
                >
                  <IonCol className="paddLR grow">
                    {quantity} x {Basket.getProductName(item, profile)}
                  </IonCol>
                  <IonCol className="righted paddLR self-aligned">
                    {basketInstance.calculateItemPriceByIndex(basketItemIndex, false) > 0
                      ? basketInstance._calculateItemPriceByIndex(basketItemIndex, false)
                      : null}
                  </IonCol>
                </IonRow>
                {/* this.drawItemTaxes(basketItem?.item, basketItemIndex) */}
                {this.drawSubItems(basketItem, basketItemIndex)}
                {instructions && instructions !== '' ? (
                  <IonRow class="sub-item">
                    <IonCol>
                      <NormalText className="block italic">{__('Special Notes')}:</NormalText>
                      <NormalText className="block italic">{instructions}</NormalText>
                    </IonCol>
                  </IonRow>
                ) : null}
              </div>
            );
          })}

          <>
            {basketInstance.process_fee_value > 0 && (
              <IonRow className="basket-item-service-charge-row">
                <>
                  <IonCol className="paddLR grow ">
                    {Basket.processing_fee_description > 0
                      ? `${Basket.processing_fee_description}% `
                      : null}
                    {__('Order processing fee')}
                  </IonCol>
                  <IonCol className="righted paddLR self-center ">
                    {basketInstance.getProcessingFee()}
                  </IonCol>
                </>
              </IonRow>
            )}
            {/* {(basketInstance.service_percentage > 0 &&
              orderType === 'Table' &&
              getConfig().general.hasServiceCharge) ||
            basketInstance.getServiceChargeValue() > 0 ? (
              <div key="service_charge" className="basket-item-wrapper">
                <IonRow
                  className="basket-item-service-charge-row service-charge-item"
                  onClick={() => this.handleServiceChargeModal(true)}
                >
                  <>
                    <IonCol className="paddLR grow ">
                      {__('Service charge')}&nbsp;{basketInstance._getServicePercentage()}
                    </IonCol>
                    <IonCol className="righted paddLR self-center ">
                      {basketInstance.getServiceChargeValue() > 0
                        ? basketInstance.getFormatedServiceChargeValue()
                        : basketInstance.getServiceCharge(true)}
                    </IonCol>
                  </>
                </IonRow>
              </div>
            ) : null} */}
            {((basketInstance.service_percentage > 0 ||
              basketInstance.service_percentage === false) &&
              orderType === 'Table' &&
              getConfig().general.hasServiceCharge) ||
            basketInstance.getServiceChargeValue() > 0 ? (
              <div key="service_charge" className="basket-item-wrapper">
                <IonRow
                  className="basket-item-service-charge-row service-charge-item"
                  onClick={() => this.setState({ serviceChargeBoxModalOpen: true })}
                >
                  <>
                    <IonCol className="paddLR grow ">
                      {__('Service charge')}&nbsp;
                      {basketInstance.service_charge_value ? (
                        <>
                          {' '}
                          {basketInstance.service_percentage !== 0 &&
                            basketInstance._getServicePercentage()}
                        </>
                      ) : (
                        <>
                          {' '}
                          {basketInstance.service_percentage !== false &&
                            basketInstance._getServicePercentage()}
                        </>
                      )}
                    </IonCol>
                    <IonCol className="righted paddLR self-center ">
                      {basketInstance.getServiceChargeValue() > 0
                        ? basketInstance.getFormatedServiceChargeValue()
                        : basketInstance.getServiceCharge(true)}
                    </IonCol>
                  </>
                </IonRow>
              </div>
            ) : null}
            {deliveryPriceUnformated > 0 ? (
              <IonRow>
                <IonCol className="paddLR grow">
                  {basketInstance.getRestaurant()?.delivery_charge_in_percent &&
                  basketInstance.geRestaurantDeliveryPrice()
                    ? `${basketInstance.geRestaurantDeliveryPrice()}% `
                    : null}
                  {__('Delivery Charge')}
                </IonCol>
                <IonCol className="righted paddLR">
                  {/* basketInstance._calculatePointsAppliedPrice(null, true) */} {deliveryPrice}
                </IonCol>
              </IonRow>
            ) : null}
            {/* {(isDefined(appliedVoucher) && appliedVoucher.length) ||
              (isDefined(appliedPoints) && appliedPoints > 0) ||
              basketInstance.discount ? (
              <IonRow
                className={appliedVoucher.length > 0 ? ' amount-values' : 'subtotal-amount-values'}
              >
                <IonCol className="paddLR grow okx-font-secondary">{__('Subtotal')}</IonCol>
                <IonCol className="righted paddLR okx-font-secondary">
                  {basketInstance._getSubTotal()}
                </IonCol>
              </IonRow>
            ) : null} */}
            {/* {appliedVoucher &&
              appliedVoucher.length > 0 &&
              appliedVoucher.map((voucher) => {
                let voucherName = voucher.name;
                if (voucher && voucher.reward && voucher.reward.name) {
                  voucherName = voucher.reward.name;
                }
                return (
                  <div key={voucher.id}>
                    <IonRow
                      className=""
                      onClick={() =>
                        location ? null : dispatch(setModal('isRemoveVoucherModalOpen', true))
                      }
                    >
                      <IonCol className="paddLR grow ">{__(voucherName)} </IonCol>
                      <IonCol className="righted paddLR">
                        {basketInstance._calculateAppliedVocuhersPrice(true, null)}
                      </IonCol>
                    </IonRow>
                    <Modal
                      className="remove-apply-voucuher"
                      title={__('Do you want to remove voucher') + ' ?'}
                      actionLabel={__('Yes')}
                      action={() => dispatch(removeVoucher(voucher.id))}
                      isOpen={!!isRemoveVoucherModalOpen}
                      onDidDismiss={() => dispatch(setModal('isRemoveVoucherModalOpen', false))}
                      noContent={true}
                    />
                  </div>
                );
              })} */}

            {/* {basketInstance.discount && (
              <IonRow className="">
                <IonCol className="paddLR grow">{basketInstance.discount.title}</IonCol>
                <IonCol className="righted paddLR">
                  -{basketInstance.formatPrice(basketInstance.calculateMembershipDiscount())}
                </IonCol>
              </IonRow>
            )} */}

            {/* {isDefined(appliedPoints) && appliedPoints > 0 ? (
              <IonRow
                onClick={() => {
                  if (type === 'order' && history) {
                    history.push('/apply-points');
                  }
                }}
              >
                <IonCol className="paddLR grow">
                  {appliedPoints}&nbsp;{__('Points Redeem')}
                </IonCol>
                <IonCol className="righted paddLR">
                  {basketInstance._calculatePointsAppliedPrice(null, true)}
                </IonCol>
              </IonRow>
            ) : null} */}
          </>

          {/* <IonRow>
            <IonCol className="paddLR">
              <IonCol className="paddLR grow okx-font-secondary">{__('Total')}</IonCol>
            </IonCol>
            <IonCol className="righted paddLR okx-font-secondary">
              {basketInstance._getTotal(true)}
            </IonCol>
          </IonRow> */}

          <Modal
            className="service-charge-modal"
            isOpen={serviceChargeModalOpen}
            onDidDismiss={() => this.handleServiceChargeModal(false)}
          >
            <Title>{__('Add service charge')}</Title>
            <Spacer size={1} />
            <IonItem lines="none" className="input-field-wrapper">
              <IonInput
                className="okx-font-secondary"
                onIonChange={(e) => this.setCustomServiceCharge(e.target.value)}
                type="number"
                pattern="number"
                inputmode="number"
              ></IonInput>
            </IonItem>
            <Spacer size={1} />
            {this.state.customAmountErrorVisible && (
              <>
                <SmallText className="danger-color">{__('Value can not be negative')}</SmallText>
                <Spacer size={1} />
              </>
            )}
            <IonButton
              expand="block"
              color="secondary"
              onClick={() => {
                basketInstance.setServiceCharge(this.state.serviceChargeAmount);
                this.setState({ serviceChargeModalOpen: false });
              }}
              disabled={!this.state.serviceChargeAmount}
            >
              {this.state.serviceChargeAmount ? (
                <>
                  {__('Add')} {basketInstance.formatPrice(this.state.serviceChargeAmount)}
                </>
              ) : (
                <>{__('Add')}</>
              )}
            </IonButton>
          </Modal>
        </IonGrid>
        {(isDefined(appliedVoucher) && appliedVoucher.length) ||
        (isDefined(appliedPoints) && appliedPoints > 0) ||
        basketInstance.discount ? (
          <>
            <Spacer size={1} />
            <IonGrid class="box-wrapper order-content-wrapper">
              <IonRow>
                <IonCol>
                  <IonCol>{__('Items Total')}</IonCol>
                </IonCol>
                <IonCol className="righted ">{basketInstance._getSubTotal(true)}</IonCol>
              </IonRow>
              {isDefined(appliedPoints) && appliedPoints > 0 ? (
                <IonRow
                  onClick={() => {
                    if (type === 'order' && history) {
                      history.push('/apply-points');
                    }
                  }}
                >
                  <IonCol className="paddLR grow">
                    {appliedPoints}&nbsp;{__('Points Redeem')}
                  </IonCol>
                  <IonCol className="righted paddLR">
                    {basketInstance._calculatePointsAppliedPrice(null, true)}
                  </IonCol>
                </IonRow>
              ) : null}
              {appliedVoucher &&
                appliedVoucher.length > 0 &&
                appliedVoucher.map((voucher) => {
                  let voucherName = voucher.name;
                  if (voucher && voucher.reward && voucher.reward.name) {
                    voucherName = voucher.reward.name;
                  }
                  return (
                    <>
                      <IonRow
                        key={voucher.id}
                        onClick={() =>
                          location ? null : dispatch(setModal('isRemoveVoucherModalOpen', true))
                        }
                      >
                        <IonCol className="paddLR grow ">{__(voucherName)} </IonCol>
                        <IonCol className="righted paddLR">
                          {basketInstance._calculateAppliedVocuhersPrice(true, null)}
                        </IonCol>
                      </IonRow>
                      <Modal
                        className="remove-apply-voucuher"
                        title={__('Do you want to remove voucher') + ' ?'}
                        actionLabel={__('Yes')}
                        action={() => dispatch(removeVoucher(voucher.id))}
                        isOpen={!!isRemoveVoucherModalOpen}
                        onDidDismiss={() => dispatch(setModal('isRemoveVoucherModalOpen', false))}
                        noContent={true}
                      />
                    </>
                  );
                })}
              {basketInstance.discount && (
                <IonRow className="">
                  <IonCol className="paddLR grow">{basketInstance.discount.title}</IonCol>
                  <IonCol className="righted paddLR">
                    -{basketInstance.formatPrice(basketInstance.calculateMembershipDiscount())}
                  </IonCol>
                </IonRow>
              )}
              {/* {basketInstance.process_fee_value > 0 && (
								<IonRow className="basket-item-service-charge-row">
									<>
										<IonCol className="paddLR grow ">
											{Basket.processing_fee_description > 0 ? `${Basket.processing_fee_description}% ` : null}
											{__('Order processing fee')}
										</IonCol>
										<IonCol className="righted paddLR self-center ">{basketInstance.getProcessingFee()}</IonCol>
									</>
								</IonRow>
							)} */}
              <IonRow>
                <IonCol>
                  <IonCol>{__('Order Total')}</IonCol>
                </IonCol>
                <IonCol className="righted">{basketInstance._getTotal(true)}</IonCol>
              </IonRow>
            </IonGrid>
          </>
        ) : null}
        <Spacer size={1} />
        <IonGrid class="box-wrapper order-content-wrapper">
          <IonRow>
            <IonCol>
              <IonCol className="bold">{__('TOTAL TO PAY')}</IonCol>
            </IonCol>
            <IonCol className="righted  bold">{basketInstance._getTotal(true)}</IonCol>
          </IonRow>
        </IonGrid>
        {basketInstance.service_percentage === 0 &&
        orderType === 'Table' &&
        getConfig().general.hasServiceCharge &&
        getConfig().general.serviceChargeBoxHidden &&
        basketInstance.getServiceChargeValue() === null ? (
          <div key="service_charge" className="basket-item-wrapper">
            <IonButton
              expand="block"
              fill="clear"
              color="secondary"
              className="link underlined basket-item-service-charge-row"
              onClick={() => this.setState({ serviceChargeBoxModalOpen: true })}
            >
              {__('add service charge')}
            </IonButton>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
    isRemoveVoucherModalOpen: store.profile.isRemoveVoucherModalOpen,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)));
