import {
  IonButton,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
} from '@ionic/react';
import mobiscroll from '@mobiscroll/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import {
  FieldError,
  NormalText,
  Sectiontitle,
  SmallText,
  Spacer,
  StrongText,
  Title,
} from '../../components/common';
import Layout from '../../components/layout';
import Mobiscroll from '../../components/mobiscroll';
import NoData from '../../components/noData';
import Loading from '../../components/spinner';
import api from '../../lib/api';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import {
  checkForDeliveryOption,
  deepCopy,
  forwardTo,
  getDistanceUnit,
  goBack,
  groupBy,
  isArray,
  isDefined,
  isObject,
  isWebConfig,
  parseAllergenData,
  weekdays,
  getRestaurantOpeningTime,
} from '../../lib/utils';
import { setDeliveryOption } from '../../store/actions';
import { loading, setCommonModal, setMyLocation, showToast } from '../../store/common/actions';
import { SET_RESTAURANT_PROP } from '../../store/constants';
import {
  getIkentooMenu,
  getIkentooMenusForLocation,
  getRestaurants,
} from '../../store/restaurants/actions';
import { filterMenu } from '../../store/restaurants/sagas';
import './index.css';

const { SelectOption } = Mobiscroll;

const createMomentFromTime = (time = '') => {
  const parsedTime = time.split(':');
  if (parsedTime.length !== 2 || time === '') {
    return null;
  }
  let hour = parseInt(time.split(':')[0]);
  let minutes = parseInt(time.split(':')[1]);
  return moment().hours(hour).minutes(minutes);
};

const toWhichSegmentTimeBelongs = (time, segments = []) => {
  let timeIsInSegment = -1;
  segments.forEach((segment, index) => {
    const { start, end } = segment;
    const targetTime = createMomentFromTime(time);
    const segmentStart = createMomentFromTime(start);
    const segmentEnd = createMomentFromTime(end);
    if (targetTime.isSameOrAfter(segmentStart) && targetTime.isSameOrBefore(segmentEnd)) {
      timeIsInSegment = index;
    }
  });
  return timeIsInSegment;
};

const isTimeInSegment = (time, segments = []) => {
  return toWhichSegmentTimeBelongs(time, segments) !== -1;
};

const addSegment = (start, end, segments = [], date) => {
  let updatedSegments = [...segments];
  const dayNumber = 'w' + date.day();
  const newSegment = { d: dayNumber, start: start, end: end };

  // check previously added segment. maybe some of them are handled with new added segment
  const oldSegments = [...segments];
  oldSegments.forEach((oldSegment, index) => {
    if (
      isTimeInSegment(oldSegment.start, [newSegment]) &&
      isTimeInSegment(oldSegment.end, [newSegment])
    ) {
      updatedSegments = removeSegment(index, updatedSegments);
    }
  });
  return [...updatedSegments, newSegment];
};

const updateSegment = (segmentIndex, propName, value, segments = []) => {
  let updatedSegments = [...segments];
  if (updatedSegments && updatedSegments[segmentIndex]) {
    updatedSegments[segmentIndex][propName] = value;
  }
  return updatedSegments;
};

const removeSegment = (segmentIndexForRemove, segments = []) =>
  [...segments]
    .map((segment, index) => (index === segmentIndexForRemove ? null : segment))
    .filter((segment) => isDefined(segment));

const parseTimesJson = (json = [], date) => {
  let parsed_json = [];
  const dayNumber = 'w' + date.day();

  // use old logic for 'json_time_selector' json (without: menuId and availabity)
  if (json.length > 0 && !json[0].menuId) {
    return json;
  }

  json.forEach((menu) => {
    (menu.availability || [])
      .filter((i) => i.d === dayNumber)
      .forEach((time) => {
        const { start, end } = time;
        if (parsed_json.length === 0) {
          // add first available time
          parsed_json = addSegment(start, end, parsed_json, date);
        } else {
          if (!isTimeInSegment(start, parsed_json) && !isTimeInSegment(end, parsed_json)) {
            // case 1: start and end dont belong to any other segment
            parsed_json = addSegment(start, end, parsed_json, date);
          } else if (isTimeInSegment(start, parsed_json) && !isTimeInSegment(end, parsed_json)) {
            // case 2: start belong to some segment and end dont bolong to any segment
            const segmentIndex = toWhichSegmentTimeBelongs(start, parsed_json);
            parsed_json = updateSegment(segmentIndex, 'end', end, parsed_json);
          } else if (!isTimeInSegment(start, parsed_json) && isTimeInSegment(end, parsed_json)) {
            // case 3: end belong to some segment and start dont bolong to any segment
            const segmentIndex = toWhichSegmentTimeBelongs(end, parsed_json);
            parsed_json = updateSegment(segmentIndex, 'start', start, parsed_json);
          } else if (isTimeInSegment(start, parsed_json) && isTimeInSegment(end, parsed_json)) {
            // case 4: and start and end belongs to some segment
            const startSegmentIndex = toWhichSegmentTimeBelongs(start, parsed_json);
            const endSegmentIndex = toWhichSegmentTimeBelongs(end, parsed_json);
            if (parsed_json && parsed_json[startSegmentIndex] && parsed_json[endSegmentIndex]) {
              const newStartTime = parsed_json[startSegmentIndex].start;
              const newEndTime = parsed_json[endSegmentIndex].end;

              if (startSegmentIndex !== endSegmentIndex) {
                parsed_json = addSegment(newStartTime, newEndTime, parsed_json, date);
                parsed_json = removeSegment(startSegmentIndex, parsed_json);
                parsed_json = removeSegment(endSegmentIndex, parsed_json);
              }
            }
          }
        }
      });
  });

  // sort times by 'start' time
  return parsed_json.sort((a, b) => {
    const aStart = createMomentFromTime(a.start);
    const bStart = createMomentFromTime(b.start);
    return bStart.isSameOrBefore(aStart) ? 1 : -1;
  });
};

export const getMenusForSelectedTime = (
  menusForLocation = [],
  pickTime,
  json_time_selector = [],
) => {
  if (isObject(menusForLocation) && !isArray(menusForLocation)) {
    menusForLocation = [menusForLocation];
  }
  if (pickTime === 'asap') {
    const menus = menusForLocation.filter((menu) => {
      const ikentooMenuId = menu.ikentooMenuId;
      if (
        json_time_selector.length > 0 &&
        json_time_selector[0].menuId &&
        pickTime &&
        ikentooMenuId
      ) {
        const target_menu = json_time_selector.find(
          (i) => i.menuId.toString() === ikentooMenuId.toString(),
        );
        if (target_menu) {
          return true;
        }
      }
      return false;
    });
    return menus;
  } else {
    return menusForLocation.filter((menu) => {
      const ikentooMenuId = menu.ikentooMenuId;

      if (
        json_time_selector.length > 0 &&
        json_time_selector[0].menuId &&
        pickTime &&
        ikentooMenuId
      ) {
        const target_menu = json_time_selector.find(
          (i) => i.menuId.toString() === ikentooMenuId.toString(),
        );
        if (
          target_menu &&
          isTimeInSegment(
            pickTime,
            target_menu.availability.filter((i) => i.d === 'w' + moment().day()),
          )
        ) {
          return true;
        }
      } else {
        // handle old json_time_selector (without menuId and availability)
        return false;
      }

      return false;
    });
  }
};

export const checkSnoozedTimes = (store, order_type) => {
  if (!store) {
    return null;
  }
  let snoozed_disabled = [];
  if (store.hasOwnProperty('snoozed_times') && store.snoozed_times[order_type]) {
    const snoozed_times = store.snoozed_times;
    snoozed_disabled.push(
      ...(snoozed_times[order_type] ? snoozed_times[order_type] : snoozed_times),
    );
  }
  if (store.hasOwnProperty('disabled_times') && store.disabled_times[order_type]) {
    const disabled_times = store.disabled_times;
    snoozed_disabled.push(
      ...(disabled_times[order_type] ? disabled_times[order_type] : disabled_times),
    );
  }
  return snoozed_disabled;
};

export const formatDataForTime = (
  store,
  minDate,
  selectedRestaurant,
  isCharterDelivery,
  isTableOrder,
  snoozedTimes,
  isScheduledClickCollect,
  __,
) => {
  const timeFormat = 'HH:mm';
  let timesInBetween = [];
  let storeTimes = [];
  const charterDeliveryPeriod =
    store && store.charter_delivery_order_slot_interval_mins
      ? store.charter_delivery_order_slot_interval_mins
      : 30;
  const clickAndCollectPeriod =
    store && store.order_slot_interval_mins ? store.order_slot_interval_mins : 5;
  const scheduledMenu = getScheduledMenu(store);
  const scheduledClickCollectPeriod = scheduledMenu?.slot_duration_mins || 5;
  const minDT = minDate.format('HH:mm');
  const date = moment(minDate);
  const dow = 'w' + date.day();
  const period = isCharterDelivery
    ? charterDeliveryPeriod
    : !isScheduledClickCollect
    ? clickAndCollectPeriod
    : scheduledClickCollectPeriod;
  let additionalSettings =
    store && store.additional_delivery_settings ? store.additional_delivery_settings : {};
  if (Basket.getDeliveryOption()) {
    additionalSettings = additionalSettings[Basket.getDeliveryOption().id];
  }
  const collection_minutes = [];
  let minutes = -period;
  while (minutes < 60) {
    minutes += period;
    if (minutes < 60) {
      collection_minutes.push(minutes);
    }
  }
  if (store) {
    if (isScheduledClickCollect && scheduledMenu?.availability) {
      const availability = scheduledMenu.availability || [];
      let slot = availability.find((day) => day.d === dow);
      if (slot?.start) {
        storeTimes.push({
          date: date,
          d: dow,
          start: slot.start,
          end: slot.end,
        });
      }
    } else {
      parseTimesJson(
        isCharterDelivery
          ? store.charter_delivery_times_json
          : isTableOrder
          ? store.table_json_time_selector
          : store.json_time_selector,
        date,
      ).forEach((time) => {
        if (time.d === dow) {
          storeTimes.push({ ...time });
        }
      });
    }
  }

  function makePickerText(times, j, i) {
    let collectionMinutes = parseInt(times[j]) < 10 ? '0' + times[j] : times[j];
    return {
      text: i < 10 ? '0' + i + ':' + collectionMinutes : i + ':' + collectionMinutes,
      value: i < 10 ? '0' + i + ':' + collectionMinutes : i + ':' + collectionMinutes,
    };
  }

  function returnTimesInBetween(start, end, cnt, slotDate) {
    let startH = parseInt(start.split(':')[0]);
    let startM = parseInt(start.split(':')[1]);
    let endH = parseInt(end.split(':')[0]);
    let endM = parseInt(end.split(':')[1]);
    let minTimeStart = parseInt(minDT.split(':')[0]);
    let minTimeEnd = parseInt(minDT.split(':')[1]);
    let c = collection_minutes.filter((cm) => cm >= startM);
    let cm = collection_minutes.filter((cm) => cm <= endM);
    let startHH = startH;
    if (startHH <= minTimeStart) {
      startHH = minTimeStart;
    }
    if (isScheduledClickCollect) {
      const todaySlot = scheduledMenu?.availability.find((day) => day.d === 'w' + moment().day());
      const prepTime = todaySlot?.prep_time || 0;
      const timeWithPrepTime = moment().add(prepTime, 'hours');
      let _start = moment()
        .hours(parseInt(start.split(':')[0]))
        .minutes(parseInt(start.split(':')[1]))
        .seconds(0);
      _start.set('year', slotDate.year());
      _start.set('month', slotDate.month());
      _start.set('date', slotDate.date());
      let _end = moment()
        .hours(parseInt(end.split(':')[0]))
        .minutes(parseInt(end.split(':')[1]))
        .seconds(0);
      _end.set('year', slotDate.year());
      _end.set('month', slotDate.month());
      _end.set('date', slotDate.date());
      for (let _time = _start; _time.isSameOrBefore(_end); _time.add(period, 'minutes')) {
        if (_time.isAfter(timeWithPrepTime)) {
          timesInBetween.push({
            text: _time.format('HH:mm'),
            value: _time.format('HH:mm'),
          });
        }
      }
    } else {
      for (let i = startHH; i <= endH; i++) {
        if (startH === i) {
          for (let j = 0; j < c.length; j++) {
            if (c[j] >= minTimeEnd && cnt === 0 && startH <= minTimeStart) {
              timesInBetween.push(makePickerText(c, j, i));
            } else if (cnt !== 0) {
              timesInBetween.push(makePickerText(c, j, i));
            } else if (startH > minTimeStart) {
              timesInBetween.push(makePickerText(c, j, i));
            }
          }
        } else if (endH === i) {
          if (minTimeStart === i) {
            for (let j = 0; j < cm.length; j++) {
              if (cm[j] >= minTimeEnd) {
                timesInBetween.push(makePickerText(cm, j, i));
              }
            }
          } else {
            for (let j = 0; j < cm.length; j++) {
              timesInBetween.push(makePickerText(cm, j, i));
            }
          }
        } else {
          if (i === minTimeStart) {
            let collection_m = collection_minutes.filter((cm) => cm >= minTimeEnd);
            for (let j = 0; j < collection_m.length; j++) {
              timesInBetween.push(makePickerText(collection_m, j, i));
            }
          } else {
            for (let j = 0; j < collection_minutes.length; j++) {
              timesInBetween.push(makePickerText(collection_minutes, j, i));
            }
          }
        }
      }
    }

    //if we have no oppning times, just add 'CLOSED' label to the picker
    if (
      timesInBetween &&
      timesInBetween.length > 1 &&
      timesInBetween[0] &&
      timesInBetween[0].text === 'CLOSED'
    ) {
      timesInBetween.shift();
    }
    //if we have no oppning times, just add 'CLOSED' label to the picker
    if (isDefined(store) && timesInBetween.length === 0) {
      timesInBetween.push({ text: 'CLOSED', value: null });
    }
    const storeWorkingTime = storeTimes && storeTimes[0];
    const startTime = moment(storeWorkingTime.start, timeFormat);
    const endTime = moment(storeWorkingTime.end, timeFormat);
    if (
      timesInBetween.length > 1 &&
      timesInBetween[0].text !== 'CLOSED' &&
      additionalSettings &&
      ((additionalSettings.asap && date.isBetween(startTime, endTime)) ||
        (additionalSettings.asap === false && additionalSettings.timePicker === false))
    ) {
      timesInBetween[0] = {
        text: 'ASAP',
        value: 'asap',
      };
    }
    if (isCharterDelivery && getConfig().flags.delivery_asap) {
      timesInBetween = [
        {
          text: 'ASAP',
          value: timesInBetween[0].value,
        },
      ];
    }

    return timesInBetween;
  }

  storeTimes.forEach((storeT, i, arr) => {
    returnTimesInBetween(storeT.start, storeT.end, i, storeTimes[0].date || moment());
    let minH = parseInt(minDT.split(':')[0]);
    let minM = parseInt(minDT.split(':')[1]);
    let endTimeH = parseInt(storeT.end.split(':')[0]);
    let endTimeM = parseInt(storeT.end.split(':')[1]);
    let minTime = date.hours(minH).minutes(minM);
    let timeEnd = date.hours(endTimeH).minutes(endTimeM);
    if (i < arr.length - 1 && arr.length > 0 && moment(minTime).isSameOrBefore(timeEnd)) {
      timesInBetween.push({ text: 'CLOSED', value: null });
    }
  });

  //remove 'CLOSED' label if that is first time
  if (
    timesInBetween &&
    timesInBetween.length > 1 &&
    timesInBetween[0] &&
    timesInBetween[0].text === 'CLOSED'
  ) {
    timesInBetween.shift();
  }
  if (timesInBetween.length === 0 && selectedRestaurant) {
    timesInBetween.push({ text: 'CLOSED', value: null });
  }
  return timesInBetween.map((time) => ({
    ...time,
    disabled: snoozedTimes.length > 0 && snoozedTimes.includes(time.text),
    html: `<span class='${
      snoozedTimes.length > 0 && snoozedTimes.includes(time.text) ? 'snoozed' : ''
    }'>${time.text}</span>`,
  }));
};

export const isStoreOpened = (selectedRestaurant) => {
  let today = moment().format('dddd');

  let currentDay = new Date().getDay();
  if (currentDay == 0) {
    currentDay = 7;
  }
  let label = true;

  const openingTimes = selectedRestaurant.json_opening_time_info.filter((el) => el.day == today)[0];
  if (openingTimes) {
    const openingTimesForToday = openingTimes.time.split('-').map((time) => {
      return {
        hour: time.split(':')[0].trim(),
        minutes: time.split(':')[1].trim(),
      };
    });
    const startTime = openingTimesForToday[0];
    const closeTime = openingTimesForToday[1];
    const current = moment().format('HH-mm').split('-');
    const currentTime = { hour: current[0], minutes: current[1] };
    if (currentTime.hour > closeTime.hour || currentTime.hour < startTime.hour) {
      label = false;
    } else if (currentTime.hour < closeTime.hour && currentTime.hour > startTime.hour) {
      label = true;
    } else if (currentTime.hour === closeTime.hour) {
      if (currentTime.minutes == closeTime.minutes) {
        label = true;
      } else if (currentTime.minutes > closeTime.minutes) {
        label = true;
      } else {
        label = false;
      }
    } else if (currentTime.hour === startTime.hour) {
      if (currentTime.minutes == startTime.minutes) {
        label = true;
      } else if (currentTime.minutes > startTime.minutes) {
        label = true;
      } else {
        label = false;
      }
    }
  } else {
    label = false;
  }
  return label;
};

const isTimeAvaibleInMenu = (json = [], date) => {
  const dayNumber = 'w' + date.day();
  let isTimeAvaible = false;
  if (json.length > 0 && !json[0].menuId) {
    return isTimeAvaible;
  }
  json.forEach((menu) => {
    (menu.availability || [])
      .filter((i) => i.d === dayNumber)
      .forEach((time) => {
        const { start, end } = time;
        if (
          date.isSameOrAfter(createMomentFromTime(start)) &&
          date.isSameOrBefore(createMomentFromTime(end))
        ) {
          isTimeAvaible = true;
        }
      });
  });
  return isTimeAvaible;
};

export const getScheduledMenu = (store) => {
  let scheduledMenu = {};
  if (
    store &&
    store?.scheduled_collection_config_json &&
    store.scheduled_collection_config_json[0]
  ) {
    scheduledMenu =
      store && store?.scheduled_collection_config_json && store.scheduled_collection_config_json[0];
  }
  return scheduledMenu;
};

class ClickAndCollect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRestaurant:
        props.location.state && props.location.state.selectedRestaurant
          ? props.location.state.selectedRestaurant.restaurant_id
          : null,
      pickTime: null,
      error: '',
      modalOpen: false,
      isSelectedResButtonActive: true,
      continueButtonClicked: false,
      isLocationAllowed: false,
      renderAgain: false,
      showOnlyPickUpText: false,
      resturantIsDisabled: false,
    };
  }

  componentDidMount() {
    if (Basket.getDeliveryOption()?.id === 'gift-vouchers') {
      Basket.reset();
    }
    const { restaurants } = this.props;
    this.props.dispatch(loading(true));
    this.props.scheduled
      ? Basket.setOrderType('scheduled-collection')
      : Basket.setOrderType('collection');
    if (this.state.selectedRestaurant) {
      const selectedRestaurant = restaurants.find(
        (restaurant) => restaurant.id === this.state.selectedRestaurant,
      );
      const orderType = getConfig().delivery.filter(
        (el) => el.id === (this.props.scheduled ? 'scheduled-collection' : 'collection'),
      );
      const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
      if (
        (this.props.scheduled
          ? selectedRestaurant.can_scheduled_collection_order
          : selectedRestaurant.can_collection_order) &&
        selectedRestaurant.is_published &&
        !orderTypeDisabled
      ) {
        Basket.setRestaurant(
          restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant),
        );
        Basket.setServicePercentage(0);
        // Basket.setProcessingFee();
      } else {
        forwardTo('/history', { tab: 'order' });

        this.props.dispatch(
          showToast(this.props.__('This restaurant is currently not accepting orders'), 'warning'),
        );
        Basket.reset();
      }
    }
    let option = (getConfig().delivery || []).find((d) => d.id === Basket.order_type);
    Basket.setDeliveryOption(option);
    checkForDeliveryOption(
      Basket.getDeliveryOption(),
      this.props.scheduled ? '/click-and-collect-scheduled' : '/click-and-collect',
    ).then((deliveryOption) => {
      if (deliveryOption) {
        //this.haveOnePublishedResturant();
        this.props.dispatch(getRestaurants());
        this.props.dispatch(setDeliveryOption(deliveryOption));
      }
      if (Basket.getMenu()) {
        this.setState({ selectedIkentooMenu: Basket.getMenu() });
      }
      this.props.dispatch(loading(false));
    });
    this.position();
  }
  selectRestaurant = (selectedRestaurantId) => {
    const { restaurants, profile } = this.props;
    this.setState(
      { selectedRestaurant: selectedRestaurantId, pickTime: null, showTime: true },
      () => {
        const restaurant = restaurants.find((restaurant) => restaurant.id === selectedRestaurantId);
        let additionalSettings =
          restaurant && restaurant.additional_delivery_settings
            ? restaurant.additional_delivery_settings
            : {};
        additionalSettings = additionalSettings[this.props.deliveryOption?.id];
        Basket.reset(profile.cardToken);
        Basket.setRestaurant(restaurant);
        Basket.setServicePercentage(0);
        Basket.setCollectionTime(null);
        Basket.setDeliveryOption(this.props.deliveryOption);
        Basket.setOrderType('collection');
        if (
          additionalSettings &&
          additionalSettings.asap === false &&
          additionalSettings.timePicker === false
        ) {
          const hasPickUpText = additionalSettings?.hasPickUpText;
          this.props.dispatch(loading(true));
          let now = moment();
          if (restaurant && isDefined(restaurant.order_slot_lead_time)) {
            now.add(restaurant.order_slot_lead_time, 'minutes');
          }
          Basket.setASAP(true);
          Basket.setCollectionTime(now);
          this.setState(
            { selectedTime: 'asap', pickTime: 'asap', showOnlyPickUpText: hasPickUpText },
            () => {
              if (!hasPickUpText) {
                this.chooseMenusForLocation();
              }
              this.props.dispatch(loading(false));
            },
          );
        } else {
          this.props.dispatch(loading(false));
        }
      },
    );
  };

  changeTime = (selectedTime, minDT) => {
    if (selectedTime && minDT) {
      let h = parseInt(selectedTime.split(':')[0]);
      let m = parseInt(selectedTime.split(':')[1]);
      const formattedDT = moment(minDT).hours(h).minutes(m);
      this.setState({ pickTime: selectedTime }, () => {
        Basket.setCollectionTime(formattedDT);
        Basket.setOrderType('collection');
        Basket.setASAP(selectedTime === 'asap');
      });
    } else {
      this.setState({ pickTime: null });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.pickTime !== this.state.pickTime ||
      nextProps.restaurants !== this.props.restaurants ||
      nextState.selectedRestaurant !== this.state.selectedRestaurant ||
      nextProps.isChooseMenuModalOpen !== this.props.isChooseMenuModalOpen ||
      nextState.showTime !== this.state.showTime ||
      nextState.continueButtonClicked !== this.state.continueButtonClicked ||
      nextState.isLocationAllowed !== this.state.isLocationAllowed ||
      nextState.renderAgain !== this.state.renderAgain
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isLocationAllowed !== prevState.isLocationAllowed) {
      this.props.dispatch(getRestaurants());
    }
    checkForDeliveryOption(
      Basket.getDeliveryOption(),
      this.props.scheduled ? '/click-and-collect-scheduled' : '/click-and-collect',
    ).then(() => {
      if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
        if (this.props.ikentooMenusForLocation[0]) {
          this.setState({
            selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId,
          });
        }
      }
    });
  }
  selectStore = (store, e) => {
    if (e.target.checked) {
      this.setState({ selectedRestaurant: store.id });
    } else {
      this.setState({ selectedRestaurant: null });
    }
  };
  setPickTime = (inst, minDT) => {
    if (inst && inst.getVal()) {
      this.changeTime(inst.getVal(), minDT);
    } else {
      this.setState({ pickTime: null });
    }
  };

  continueOnMenu = () => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant } = this.state;
    if (selectedRestaurant && selectedIkentooMenu) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };

  getMenuForReorder = (menus, reorderItems) => {
    let menuForReorder = [];
    for (let i = 0; i < menus.length; i++) {
      let foundItems = 0;
      const categoryItems = menus[i].menuEntry || menus[i].menuEntryGroups || null;
      let items = Basket.flattenMenuItems(deepCopy(categoryItems));
      menus[i].flattenMenuItems = items;
      if (items.length > 0) {
        for (let j = 0; j < reorderItems.length; j++) {
          let foundItem = items.find((i) => i.sku === reorderItems[j].item.sku);
          if (foundItem) {
            foundItems = foundItems + 1;
          }
        }
      }
      menus[i].foundItems = foundItems;
      menuForReorder.push(menus[i]);
    }
    menuForReorder.sort((a, b) => b.foundItems - a.foundItems);
    if (menuForReorder[0] && menuForReorder[0].foundItems > 0) {
      return filterMenu(menuForReorder[0]);
    } else {
      return false;
    }
  };
  chooseMenusForLocation = async () => {
    const { restaurants, dispatch } = this.props;
    const { selectedRestaurant, pickTime } = this.state;
    this.setState({ continueButtonClicked: true }, async () => {
      if (selectedRestaurant && pickTime) {
        const choosenRestaurant = restaurants.find(
          (restaurant) => restaurant.id === selectedRestaurant,
        );
        const scheduledMenu = getScheduledMenu(choosenRestaurant);
        const businessLocationId = choosenRestaurant.business_location_id;
        let currentDT = moment();
        let h = parseInt(pickTime.split(':')[0]);
        let m = parseInt(pickTime.split(':')[1]);
        const formattedDT = moment(currentDT).hours(h).minutes(m);
        Basket.setCutoffTime(currentDT.unix());
        if (!this.props.scheduled) {
          Basket.setCollectionTime(formattedDT);
        }
        if (this.props.scheduled) {
          this.setState({ timeSelected: true });
        }
        if (this.props.location.state && this.props.location.state.selectedRestaurant) {
          let ikentooMenusForLocation = await api.getIkentooMenusForLocation(businessLocationId);
          if (this.props.scheduled) {
            const scheduledMenuId = scheduledMenu?.menuId;
            ikentooMenusForLocation = ikentooMenusForLocation.filter(
              (menu) => menu.ikentooMenuId == scheduledMenuId,
            );
          } else {
            ikentooMenusForLocation = getMenusForSelectedTime(
              ikentooMenusForLocation,
              pickTime,
              choosenRestaurant.json_time_selector,
            );
          }
          if (ikentooMenusForLocation && isObject(ikentooMenusForLocation[0])) {
            let ikentooMenus = [];
            try {
              for (let i = 0; i < ikentooMenusForLocation.length; i++) {
                ikentooMenus.push(
                  await api.getIkenooMenu(
                    ikentooMenusForLocation[i].ikentooMenuId,
                    businessLocationId,
                  ),
                );
              }
            } catch (error) {
              this.setState({ error: 'Get restaurant menu error.' });
              this.setState({ continueButtonClicked: false });
            }
            const reorderItems = this.props.location.state.selectedRestaurant.items;
            const menu = this.getMenuForReorder(ikentooMenus, reorderItems);
            if (menu) {
              dispatch({
                type: SET_RESTAURANT_PROP,
                key: 'ikentooMenu',
                value: menu,
              });
              if (menu.flattenMenuItems.length > 0) {
                let validationStatus = { notBasketEmpty: false, validationError: false };
                reorderItems.map((newBasketItem, index) => {
                  let foundItem = menu.flattenMenuItems.find(
                    (i) => i.sku === newBasketItem.item.sku,
                  );
                  if (foundItem && Basket.isProductEnabled(foundItem)) {
                    if (newBasketItem.selectedChoices[0]) {
                      newBasketItem.selectedChoices[0] = newBasketItem.selectedChoices[0].filter(
                        (el) => Basket.isProductEnabled(el),
                      );
                    }
                    newBasketItem.item = foundItem;
                    Basket.addToBasket(newBasketItem);
                    let item = newBasketItem.item;
                    let allergens = this.props.allergens;
                    let profile = this.props.profile;
                    let newArr = parseAllergenData(profile, item, allergens);
                    let allergensCodes =
                      newBasketItem.item?.itemRichData?.allergenCodes?.length > 0
                        ? newBasketItem.item.itemRichData.allergenCodes
                        : [];
                    if (allergensCodes.length > 0) {
                      let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
                      Basket.setAllergen(allergensData);
                    }
                    validationStatus.notBasketEmpty = true;
                  } else {
                    validationStatus.validationError = true;
                  }
                });
                if (validationStatus.notBasketEmpty) {
                  if (validationStatus.validationError) {
                    dispatch(
                      showToast(
                        'Some items were not added to your basket as they are currently unavailable',
                        'warning',
                      ),
                    );
                    this.setState({ continueButtonClicked: false });
                  }
                  forwardTo('/order-summary', { skipBackToThePreviousPage: false });
                } else {
                  dispatch(
                    showToast(
                      'Some items were not added to your basket as they are currently unavailable',
                      'warning',
                    ),
                  );
                  this.setState({ continueButtonClicked: false });
                }
              }
            } else {
              dispatch(showToast('Menu not found', 'warning'));
              this.setState({ continueButtonClicked: false });
            }
          } else {
            dispatch(showToast('Menu not found', 'warning'));
            this.setState({ continueButtonClicked: false });
          }
        } else {
          dispatch(
            getIkentooMenusForLocation(businessLocationId, {
              pickTime,
              json_time_selector: choosenRestaurant
                ? this.props.scheduled && scheduledMenu
                  ? [
                      {
                        menuId: scheduledMenu?.menuId,
                        availability: scheduledMenu?.availability,
                      },
                    ]
                  : choosenRestaurant.json_time_selector
                : [],
            }),
          );
          this.setState({ continueButtonClicked: false });
        }
      } else if (!selectedRestaurant) {
        this.setState({ error: 'Please select location' });
      } else {
        this.setState({ error: 'Please select pickup time' });
      }
    });
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });

  formatDataForSelect = (stores) => {
    let formatedStores = [];
    stores.forEach((store) => {
      const currentDT = moment();
      let minDT = currentDT;
      if (store && isDefined(store.order_slot_lead_time)) {
        minDT.add(store.order_slot_lead_time, 'minutes');
      }
      const snoozedTimes = checkSnoozedTimes(
        store,
        this.props.scheduled ? 'scheduled-collection' : 'collection',
      );
      const storeOpened =
        store.can_scheduled_collection_order &&
        Basket.getDeliveryOption()?.id === 'scheduled-collection'
          ? true
          : isStoreOpened(store);
      let slotsAvailable = true;
      if (!this.props.scheduled) {
        let additionalSettings =
          store && store.additional_delivery_settings ? store.additional_delivery_settings : {};
        additionalSettings = additionalSettings[Basket.getDeliveryOption()?.id];
        if (
          additionalSettings &&
          additionalSettings?.asap === false &&
          additionalSettings?.timePicker === false
        ) {
          slotsAvailable = isTimeAvaibleInMenu(store.json_time_selector, currentDT);
        } else {
          const timeData = formatDataForTime(
            store,
            minDT,
            store.id,
            false,
            false,
            snoozedTimes,
            this.props.scheduled,
          );
          slotsAvailable =
            timeData.length === 1 && timeData[0].text.toLowerCase() === 'closed' ? false : true;
        }
      }
      store.opened = slotsAvailable;
      if (storeOpened && slotsAvailable) {
        store.openLabel = 'OPEN';
      } else if (storeOpened && !slotsAvailable) {
        store.openLabel = 'UNAVAILABLE';
      } else if (!storeOpened && slotsAvailable) {
        store.openLabel = 'PREORDER';
      } else if (!storeOpened && !slotsAvailable) {
        store.openLabel = 'CLOSED';
      }
      if (this.props.scheduled) {
        if (isDefined(store.can_scheduled_collection_order) && isDefined(store.is_published)) {
          if (store.can_scheduled_collection_order && store.is_published) {
            formatedStores.push(store);
          }
        }
      } else if (store.is_published && store.can_collection_order) {
        formatedStores.push(store);
      }
    });
    if (this.state.isLocationAllowed) {
      formatedStores.sort(function (a, b) {
        return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
      });
    } else {
      formatedStores.sort(function (a, b) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
    }
    return formatedStores;
  };

  parseSelectedTime(selected) {
    let selectedTimeSlot = selected.match(/\d{1,2}:\d{2} (AM|PM)/)?.[0] || 'CLOSED';
    const selectedDate = selected.replace(' ' + selectedTimeSlot, '');
    const timePickerOptionsKeys = Object.keys(this.timePickerOptions);
    const selectedTime =
      selectedDate === 'init'
        ? this.timePickerOptions && timePickerOptionsKeys.length > 0
          ? this.timePickerOptions[timePickerOptionsKeys[0]][0]
          : {}
        : this.timePickerOptions[selectedDate].find((option) => option.time === selectedTimeSlot);
    if (selectedTime && !this.state.timeSelected) {
      this.setState({ pickTime: selectedTime?.snoozed ? null : selectedTime.slot }, () => {
        Basket.setCollectionTime(selectedTime.formattedDT);
        Basket.setOrderType(
          this.props.deliveryOption && this.props.deliveryOption.id
            ? this.props.deliveryOption.id
            : 'collection',
        );
      });
    }
  }

  formatTimesForTree = (times) => {
    const groupByMonth = groupBy(['date']);
    const grouped = groupByMonth(times);
    return grouped;
  };

  formatPickupTimes = (store) => {
    const formatSelectOptionDays = [];
    const scheduledMenu = getScheduledMenu(store);
    const daysAhead =
      (this.props.scheduled
        ? scheduledMenu?.days_ahead + 1 || 8
        : getConfig().general.collectAheadDays) || 7;
    let picker = [];
    const date = moment();
    const dow = 'w' + date.day();
    Array(daysAhead)
      .fill('')
      .forEach((day, i) => {
        let formatDay = moment().add(i, 'days');
        if (i > 0) {
          formatDay = moment()
            .add(i, 'days')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        }

        if (this.props.scheduled) {
          formatDay = moment()
            .add(i, 'days')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        }

        let formatDayName = formatDay.format('dddd');
        formatSelectOptionDays.push({ formatDayName, formattedDT: formatDay });
      });

    formatSelectOptionDays.map((option) => {
      const snoozedTimes = store?.snoozed_times['scheduled-collection'] || [];
      const daySlot =
        formatDataForTime(
          store,
          option.formattedDT,
          this.state.selectedRestaurant,
          false,
          false,
          snoozedTimes,
          this.props.scheduled,
          this.props.__,
        ) || [];
      daySlot.forEach((slot) => {
        let h = parseInt(slot.text.split(':')[0]);
        let m = parseInt(slot.text.split(':')[1]);
        let formattedDT = moment(option.formattedDT.hours(h).minutes(m));

        picker.push({
          formattedDT: formattedDT,
          // value: formattedDT.toDate(),
          date: formattedDT.format('ddd, DD MMM'),
          time: formattedDT.format('h:mm A'),
          slot: slot.text,
        });
      });
    });

    let unavailable_dates = null;
    if (this.props.scheduled && scheduledMenu && scheduledMenu.unavailable_dates) {
      unavailable_dates = scheduledMenu.unavailable_dates.map((slot) =>
        moment(slot, 'DD/MM/YYYY').format('ddd, DD MMM'),
      );
    }

    const formattedTreeDataSource = this.formatTimesForTree(picker);
    Object.keys(formattedTreeDataSource).map((day) => {
      if (formattedTreeDataSource[day][0].slot === 'CLOSED') {
        if (this.props.scheduled) {
          delete formattedTreeDataSource[day];
        } else {
          formattedTreeDataSource[day][0] = { time: 'CLOSED' };
        }
      } else {
        if (
          unavailable_dates &&
          unavailable_dates.length > 0 &&
          unavailable_dates.indexOf(day) > -1
        ) {
          formattedTreeDataSource[day] = [{ time: 'CLOSED' }];
        }
        let slot = scheduledMenu?.availability.find((day) => day.d === dow);
        if (slot?.start && this.props.scheduled && scheduledMenu) {
          const slotEnd = moment(day, 'ddd, DD MMM')
            .hours(parseInt(slot.end.split(':')[0]))
            .minutes(parseInt(slot.end.split(':')[1]))
            .seconds(0);
          if (moment().isAfter(slotEnd)) {
            delete formattedTreeDataSource[day];
          }
        }
      }
    });
    const snoozedTimes = store?.snoozed_times['scheduled-collection'] || [];
    snoozedTimes.map((snoozeTime) => {
      let _snoozeTime = moment.utc(snoozeTime, 'ddd, DD MMM YYYY-HH:mm').local();
      let temp_data = formattedTreeDataSource[_snoozeTime.format('ddd, DD MMM')];
      let pos = temp_data?.findIndex((item) => item.slot === _snoozeTime.format('HH:mm'));
      if (pos > -1) {
        temp_data[pos] = { ...temp_data[pos], snoozed: true };
      }
    });
    this.timePickerOptions = formattedTreeDataSource;
  };

  getTitle = (deliveryOption) => {
    if (!deliveryOption) {
      return '';
    } else {
      return deliveryOption.label + ' Order';
    }
  };

  refreshTimeSlot = () => {
    if (this.timeSlotRef.current) {
      this.timeSlotRef.current.instance.init();
    }
  };

  haveOnePublishedResturant = () => {
    const { __ } = this.props;

    const restaurantArr = this.props.restaurants.filter(
      (restaurant) => restaurant.is_published === true && restaurant.can_collection_order === true,
    );
    if (
      restaurantArr.length === 1 &&
      this.state.selectedRestaurant === null &&
      restaurantArr[0].opened
    ) {
      const restaurant = restaurantArr[0];
      const additional_delivery_settings = restaurant.additional_delivery_settings || {};
      const additionalSettings = additional_delivery_settings[this.props.deliveryOption?.id] || {};
      if (
        additionalSettings.asap === false &&
        additionalSettings.timePicker === false &&
        !isStoreOpened(restaurant)
      ) {
        this.setState({ resturantIsDisabled: true });
      } else {
        this.selectRestaurant(restaurant.id);
        this.setState({ isSelectedResButtonActive: false });
      }
    }
  };

  handleModalDismissed = () => this.setState({ isSlotReservedModalOpen: false });
  backHandler = () => {
    if (this.state.showTime) {
      this.setState({ showTime: false });
    } else {
      goBack();
    }
  };

  position = async () => {
    navigator.geolocation.getCurrentPosition(
      (res) => {
        this.props.dispatch(
          setMyLocation({
            latitude: res?.coords?.latitude,
            longitude: res?.coords?.longitude,
          }),
        );
        this.setState({
          isLocationAllowed: true,
        });
      },
      () => {
        this.setState(
          {
            isLocationAllowed: false,
          },
          () => {
            this.props.dispatch(setMyLocation({ latitude: null, longitude: null }));
          },
        );
      },
    );
  };

  render() {
    const {
      __,
      restaurants,
      ikentooMenusForLocation,
      isChooseMenuModalOpen,
      deliveryOption,
    } = this.props;
    const {
      error,
      selectedRestaurant,
      pickTime,
      selectedIkentooMenu,
      showTime,
      showOnlyPickUpText,
      resturantIsDisabled,
    } = this.state;
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const store = restaurants.find((restaurant) => restaurant.id === selectedRestaurant) || null;
    const stores = restaurants || [];

    const snoozedTimes = checkSnoozedTimes(store, 'collection');
    const showSnoozedAlert = (e) => {
      if (snoozedTimes.includes(e.valueText)) {
        mobiscroll.alert({
          title: 'Warning',
          message: __("Sorry, we're very busy, please select another time slot"),
          callback: this.handleModalDismissed,
        });
        return false;
      }
    };
    const currentDT = moment();
    let minDT = currentDT;

    const restaurantOpeningTime = getRestaurantOpeningTime(store, minDT);
    let timePickerOptions = [{ text: 'CLOSED', value: null }];
    const scheduledMenu = getScheduledMenu(store);
    if (restaurantOpeningTime?.is_open || this.props.scheduled) {
      if (restaurantOpeningTime && restaurantOpeningTime.open_at > moment()) {
        minDT = restaurantOpeningTime.open_at;
      }
      //include 'Order slot lead time' from the BO
      if (!this.props.scheduled && store && isDefined(store.order_slot_lead_time)) {
        minDT.add(store.order_slot_lead_time, 'minutes');
      }
      timePickerOptions = formatDataForTime(
        store,
        minDT,
        selectedRestaurant,
        false,
        false,
        snoozedTimes,
        this.props.scheduled,
        __,
      );
      if (this.props.scheduled) {
        this.formatPickupTimes(store);
      }
    }
    const menus = getMenusForSelectedTime(
      ikentooMenusForLocation,
      pickTime,
      store
        ? this.props.scheduled && scheduledMenu
          ? [
              {
                menuId: scheduledMenu?.menu_id,
                availability: scheduledMenu?.availability,
              },
            ]
          : store.json_time_selector
        : [],
    );
    const formatedStores = this.formatDataForSelect(stores);
    const orderType = Basket.getDeliveryOption()?.id;
    let additionalSettings =
      store && store.additional_delivery_settings ? store.additional_delivery_settings : {};
    additionalSettings = additionalSettings[orderType];
    const hasPickUpText = additionalSettings?.hasPickUpText;
    const pickUpText = additionalSettings?.pickUpText;
    return (
      <Loading>
        <Layout
          headerWithTitle={true}
          backHandler={this.backHandler}
          headerTitle={__(deliveryOption ? deliveryOption.label : '')}
          color="transparent"
          scrollY={false}
        >
          <div className="absolute-content">
            {!showTime ? (
              <>
                <div className="click-and-collect-title">
                  <Title className="web-only">
                    {this.props.scheduled
                      ? __('Scheduled click & collect')
                      : __('Click & Collect Order')}
                  </Title>
                  <NormalText>{__('Select a restaurant to collect your order')}</NormalText>
                  <Spacer size={1} />
                </div>
                <div className="click-and-collect-locations">
                  <IonList className=" box-wrapper">
                    {formatedStores.map((store, i) => (
                      <IonItem
                        disabled={resturantIsDisabled || !store.opened}
                        onClick={(e) => this.selectStore(store, e)}
                        lines="none"
                      >
                        <IonLabel>
                          <StrongText>{__(store.name)}</StrongText>
                          <SmallText className="block break-space">{__(store.address)}</SmallText>
                          {store.distance && (
                            <SmallText className=" block bold-description click-collect-small-description">
                              {store.distance < 1
                                ? store.distance.toFixed(1)
                                : store.distance.toFixed(0)}{' '}
                              {__('miles')}
                            </SmallText>
                          )}
                          <NormalText className="uppercase secondary-color">
                            {__(store.openLabel)}
                          </NormalText>
                        </IonLabel>
                        <IonCheckbox
                          checked={selectedRestaurant && store.id === selectedRestaurant}
                          slot="start"
                          color="secondary"
                        />
                      </IonItem>
                    ))}
                  </IonList>
                </div>
                {getConfig().frenchDisclaimer?.disclaimerImage && (
                  <div className="disclaimer-wrapper">
                    <img src={getConfig().frenchDisclaimer?.disclaimerImage}></img>
                  </div>
                )}
                <div className="click-and-collect-button-wrapper">
                  <IonButton
                    disabled={!selectedRestaurant || formatedStores.length === 0}
                    expand="block"
                    color="secondary"
                    onClick={() => this.selectRestaurant(selectedRestaurant)}
                  >
                    {__('Continue')}
                  </IonButton>
                </div>
              </>
            ) : this.props.scheduled ? (
              <>
                <div className="click-and-collect-title">
                  <Title className="web-only">{__('Scheduled click & collect')}</Title>
                  <NormalText>{__('Select a time to collect your order')}</NormalText>
                </div>
                <div className="time-picker-wrapper box-wrapper click-and-collect-timepicker-wrapper">
                  <mobiscroll.Treelist
                    display="inline"
                    layout="liquid"
                    showInput={false}
                    circular={false}
                    ref={this.timeSlotRef}
                    focusOnClose={false}
                    themeVariant="light"
                    theme="ios"
                    width={(280, 260)}
                    placeholder={__('Select Collection Time')}
                    onSet={(e, instance) => {
                      const newInstance = new Object(instance);
                      instance.setVal(newInstance._tempWheelArray, true, true, true);
                      this.parseSelectedTime(instance._tempValue);
                    }}
                    onInit={() => this.parseSelectedTime('init')}
                    scrollLock
                  >
                    {Object.keys(this.timePickerOptions).map((date, i) => (
                      <li key={i} data-val={date}>
                        <span
                          className={`item-date-picker ${
                            this.timePickerOptions[date][0].time === 'CLOSED' && 'striked'
                          }`}
                        >
                          {date}
                        </span>
                        <ul>
                          {this.timePickerOptions[date].map((time, j) => (
                            <li key={j} data-val={time.time}>
                              <span className={`item-date-picker ${time.snoozed && 'striked'}`}>
                                {time.time === 'CLOSED' ? 'UNAVAILABLE' : time.time}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </mobiscroll.Treelist>
                </div>
                {hasPickUpText && (
                  <div className="click-and-collect-title">
                    <div dangerouslySetInnerHTML={{ __html: pickUpText }} />
                  </div>
                )}
                <Spacer size={1} />
                <div className="click-and-collect-button-wrapper">
                  <IonButton
                    disabled={!selectedRestaurant || formatedStores.length === 0}
                    expand="block"
                    color="secondary"
                    onClick={() => this.chooseMenusForLocation()}
                  >
                    {__('Continue')}
                  </IonButton>
                </div>
              </>
            ) : showOnlyPickUpText ? (
              <>
                {hasPickUpText && (
                  <div className="click-and-collect-title">
                    <div dangerouslySetInnerHTML={{ __html: pickUpText }} />
                  </div>
                )}
                <Spacer size={1} />
                <div className="click-and-collect-button-wrapper">
                  <IonButton
                    disabled={!pickTime}
                    expand="block"
                    color="secondary"
                    className={this.state.continueButtonClicked ? 'unclicked' : ''}
                    onClick={() => this.chooseMenusForLocation()}
                  >
                    {__('Continue')}
                  </IonButton>
                </div>
              </>
            ) : (
              <>
                <div className="click-and-collect-title">
                  <Title className="web-only">{__('Click & Collect Order')}</Title>
                  <NormalText>{__('Select a time to collect your order')}</NormalText>
                  <Spacer size={1} />
                </div>
                <div className="time-picker-wrapper box-wrapper click-and-collect-timepicker-wrapper">
                  <SelectOption
                    display="inline"
                    onSet={(e, inst) => this.setPickTime(inst, minDT)}
                    data={timePickerOptions}
                    label="Location"
                    value={pickTime}
                    inputStyle="box"
                    placeholder={__('Select Collection Time')}
                    setText={__('OK')}
                    cancelText={__('Cancel')}
                    disabled={this.state.selectedRestaurant === null ? true : false}
                    onInit={() => {
                      if (timePickerOptions.length > 0) {
                        const firstAvailableTime = timePickerOptions.find(
                          (i) => i.value !== null && !snoozedTimes.includes(i.value),
                        );
                        if (
                          !pickTime &&
                          firstAvailableTime &&
                          pickTime !== firstAvailableTime.value
                        ) {
                          this.changeTime(firstAvailableTime.value, minDT);
                        }
                      }
                    }}
                  />
                </div>
                {hasPickUpText && (
                  <div className="click-and-collect-title">
                    <div dangerouslySetInnerHTML={{ __html: pickUpText }} />
                  </div>
                )}
                <Spacer size={1} />

                <div className="click-and-collect-button-wrapper">
                  <IonButton
                    disabled={!pickTime}
                    expand="block"
                    color="secondary"
                    className={this.state.continueButtonClicked ? 'unclicked' : ''}
                    onClick={() => this.chooseMenusForLocation()}
                  >
                    {__('Continue')}
                  </IonButton>
                </div>
              </>
            )}
          </div>
          {!menus.length ? null : (
            <>
              <div
                className="click-collect-pickers-backdrop"
                style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
                onClick={() => {
                  if (
                    additionalSettings &&
                    additionalSettings.asap === false &&
                    additionalSettings.timePicker === false
                  ) {
                    this.backHandler();
                  }
                  this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false));
                }}
              ></div>

              <div className={`click-collect-dialog ${animationMenuClass}`}>
                <div className="click-collect-dialog-layout sc-ion-modal-md">
                  <div className="click-collect-dialog-header">
                    <Title className="centered">{__('Choose menu')}</Title>
                  </div>
                  <div
                    className="click-collect-dialog-closer"
                    onClick={() => {
                      if (
                        additionalSettings &&
                        additionalSettings.asap === false &&
                        additionalSettings.timePicker === false
                      ) {
                        this.backHandler();
                      }
                      this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false));
                    }}
                  >
                    <ion-icon
                      name="close"
                      role="img"
                      class="md hydrated"
                      aria-label="close"
                    ></ion-icon>
                  </div>
                  <div className="click-collect-dialog-content">
                    <IonList lines="none" className="box-wrapper">
                      <IonRadioGroup
                        onIonChange={this.changeIkentooMenus}
                        value={selectedIkentooMenu}
                      >
                        {!menus.length ? (
                          <NoData />
                        ) : (
                          menus.map((menu) => {
                            const { ikentooMenuId, menuName } = menu;
                            return (
                              <IonItem key={ikentooMenuId} lines="none">
                                <div tabIndex="-1"></div>
                                <IonLabel className="ion-text-wrap">
                                  <Sectiontitle>{menuName}</Sectiontitle>
                                </IonLabel>
                                <IonRadio
                                  color={isWebConfig() ? 'secondary' : 'white'}
                                  slot="start"
                                  value={ikentooMenuId}
                                />
                              </IonItem>
                            );
                          })
                        )}
                      </IonRadioGroup>
                    </IonList>
                  </div>
                  <div className="click-collect-dialog-action">
                    {error ? (
                      <IonItem>
                        <div tabIndex="-1"></div>
                        <FieldError className="field-error" value={__(error)} />
                      </IonItem>
                    ) : null}
                    <IonButton
                      disabled={pickTime && menus.length > 0 ? false : true}
                      expand="block"
                      color="secondary"
                      className="customized-button"
                      onClick={() => this.continueOnMenu()}
                    >
                      {__('Continue')}
                    </IonButton>
                  </div>
                </div>
              </div>
            </>
          )}
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isChooseMenuModalOpen, orderProductionMins } = state.common;
  const { restaurants, ikentooMenu, isShowTimePicker, ikentooMenusForLocation } = state.restaurants;
  const { deliveryOption } = state.orders;
  return {
    auth,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    ikentooMenusForLocation: ikentooMenusForLocation || [],
    profile: state.profile.profile,
    isShowTimePicker: isShowTimePicker,
    deliveryOption,
    orderProductionMins: orderProductionMins,
  };
};

export default connect(stateToProps)(withTranslation(ClickAndCollect));
