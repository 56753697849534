import React from 'react';
import {
  IonButton,
  IonInput,
  IonToggle,
  IonItem,
  IonLabel,
  IonList,
  IonAlert,
  IonCheckbox,
} from '@ionic/react';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import {
  registerRequest,
  setModal,
  setSysLocale,
  setRegisterForm,
  updateProfile,
} from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import {
  FieldError,
  Title,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
} from '../../components/common';
import { validateForm } from '../../lib/utils';
import Loading from '../../components/spinner';
import { Plugins } from '@capacitor/core';
import './index.css';
import Mobiscroll from '../../components/mobiscroll';
import { CLEAR_REGISTER_FORM } from '../../store/constants';
import { getConfig } from '../../appConfig';
import moment from '../../lib/moment';

const { Device } = Plugins;
const { DatePicker } = Mobiscroll;
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      fromGiftVoucher: this.props?.location?.state?.fromGiftVoucher,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.navToTerms = this.navToTerms.bind(this);
    this.formConfig = {
      first_name: { type: 'text', required: false },
      last_name: { type: 'text', required: false },
      mobile: { type: 'text', required: false },
      email: { type: 'email', required: true },
      password: { type: 'password', required: true },
      birthday: { type: 'text', required: false },
      accepted_terms_and_conditions: { type: 'toggle', toggle: true },
    };
  }

  handleInput(key, val) {
    const { dispatch } = this.props;
    dispatch(setRegisterForm(key, val));
    if (key === 'accepted_terms_and_conditions') {
      let formErrors = { ...this.state.formErrors };
      formErrors.accepted_terms_and_conditions = undefined;
      this.setState({ formErrors });
    }
  }

  handleRegister() {
    const { registerFormData } = this.props;
    let formErrors = validateForm(this.formConfig, registerFormData);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      this.props.dispatch(registerRequest({ referrer: '/register' }));
    }
  }

  componentDidMount() {
    const { loggedIn } = this.props.auth;
    if (!this.props.location?.state?.saveRegisterData) {
      this.props.dispatch({
        type: CLEAR_REGISTER_FORM,
      });
    }
    Device.getLanguageCode().then((res) => {
      const sysLocale = res.value.substr(0, 2);
      if (sysLocale) {
        this.props.dispatch(setSysLocale(sysLocale));
      }
    });
    if (loggedIn) {
      const defaultRoute = getDefaultRoute(this.props.navConfig);
      forwardTo(defaultRoute.path, {
        fromLogin: this.props?.location?.state?.fromGiftVoucher ? true : false,
      });
    }
  }

  returnToLogin = (history) => history.goBack();

  navToTerms = () => forwardTo('/terms');

  render() {
    const { __, isRegisterModalOpen, registerFormData, dispatch } = this.props;
    const email = registerFormData.email;
    const password = registerFormData.password;
    const first_name = registerFormData.first_name;
    const last_name = registerFormData.last_name;
    const mobile = registerFormData.mobile;
    const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions;
    const is_subscribed = registerFormData.is_subscribed;
    const birthday = registerFormData.birthday;
    const { loggedIn } = this.props.auth;
    const hasDOB = getConfig().flags.requiredDOB;
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const defaultDateValue = moment().subtract(18, 'years');

    return (
      <Loading transparent>
        <Layout headerTitle="Register" color="transparent">
          {!loggedIn ? (
            <div className="absolute-content scrollable-y">
              <Title>{__('Create Account')}</Title>
              <NormalText>
                {__(
                  'Create an account to earn & redeem loyalty, access exclusive vouchers and offers and other app-only perks.',
                )}
              </NormalText>
              <Spacer />
              <IonList>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('Enter your email address') + ' *'}
                    onIonChange={(e) => this.handleInput('email', e.target.value)}
                    onIonBlur={(e) => {
                      const usernameInput = document.querySelector(
                        'input[type="email"]:-webkit-autofill',
                      );
                      if (usernameInput) {
                        this.handleInput('email', usernameInput.value);
                      }
                      this.handleInput('email', e.target.value);
                    }}
                    required={true}
                    type="email"
                    pattern="email"
                    inputmode="email"
                    value={email}
                  ></IonInput>
                </IonItem>
                <FieldError className="field-error" value={__(this.state.formErrors.email)} />
                <IonItem lines="none" className="input-field-wrapper">
                  <PasswordInput
                    placeholder={__('Enter your password') + ' *'}
                    onIonChange={(e) => this.handleInput('password', e.target.value)}
                    value={password}
                  />
                </IonItem>
                <FieldError className="field-error" value={__(this.state.formErrors.password)} />
              </IonList>
              <Spacer />
              <div className="no-padding">
                <IonList>
                  <div lines="none">
                    <div tabIndex="-1"></div>
                    <div className="toggle">
                      <IonLabel>
                        <StrongText>{__('Terms & Conditions')}</StrongText>
                        <Spacer size={1} />
                        <div className="register-terms-wrapper">
                          <IonCheckbox
                            color="secondary"
                            checked={accepted_terms_and_conditions}
                            onIonChange={(e) =>
                              this.handleInput('accepted_terms_and_conditions', e.detail.checked)
                            }
                          />
                          <IonLabel className="ion-text-wrap">
                            <NormalText color="primary">
                              {__('By signing up you agree to') + ' '}{' '}
                              <span
                                className="pointer underlined secondary-color"
                                onClick={() => forwardTo('/terms', { fromRegisterPage: true })}
                              >
                                {__('our terms and conditions')}
                              </span>{' '}
                              {__('and')}{' '}
                              <span
                                className="secondary-color  pointer underlined"
                                onClick={() => forwardTo('/privacy', { fromRegisterPage: true })}
                              >
                                {__('privacy policy')}
                              </span>
                            </NormalText>
                          </IonLabel>
                        </div>
                      </IonLabel>
                      <FieldError
                        className="field-error"
                        value={__(this.state.formErrors.accepted_terms_and_conditions)}
                      />
                    </div>
                  </div>
                  <div lines="none">
                    <div tabIndex="-1"></div>
                    <div className="toggle">
                      <IonLabel>
                        <div className="register-terms-wrapper">
                          <IonCheckbox
                            color="secondary"
                            checked={is_subscribed}
                            onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked)}
                          />
                          <IonLabel className="ion-text-wrap">
                            <NormalText color="primary">
                              {__(
                                "I'd like to receive email updates that contain news, offers and promotions",
                              )}
                            </NormalText>
                          </IonLabel>
                        </div>
                      </IonLabel>
                    </div>
                  </div>
                </IonList>
              </div>
              <Spacer />
              <div className="top-medium">
                <IonButton
                  expand="block"
                  color="secondary"
                  className="register-button uppercase okx-font-secondary"
                  onClick={() => this.handleRegister()}
                >
                  {__('Register')}
                </IonButton>
              </div>
            </div>
          ) : (
            <div className="absolute-content flex-row-wrapper">
              <Title>{__('About You')}</Title>
              <NormalText>{__('Tell us some more details (optional)')}</NormalText>
              <Spacer size={2} />
              <IonList>
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('Enter your first name')}
                    onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={first_name}
                  ></IonInput>
                </IonItem>
                <Spacer size={1} />

                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('Enter your last name')}
                    onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={last_name}
                  ></IonInput>
                </IonItem>
                <Spacer size={1} />

                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('Enter your mobile number')}
                    onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                    required={true}
                    type="tel"
                    pattern="tel"
                    inputmode="tel"
                    value={mobile}
                  ></IonInput>
                </IonItem>
                <Spacer size={1} />
                {hasDOB && (
                  <>
                    <IonItem lines="none" className="input-field-wrapper">
                      <DatePicker
                        className="data-picker-input"
                        display="bottom"
                        max={yesterday}
                        defaultValue={defaultDateValue}
                        setText={__('Done')}
                        cancelText={__('Cancel')}
                        value={birthday}
                        onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                        dateFormat="dd-mm-yy"
                        placeholder={__('Enter your date of birth')}
                        // onBeforeShow={() => {
                        //   this.props.dispatch(beforeShowTimePicker());
                        // }}
                        // onClose={() => {
                        //   this.props.dispatch(beforeCloseTimePicker());
                        // }}
                      />
                    </IonItem>
                    <Spacer size={1} />
                  </>
                )}
              </IonList>
              <Spacer size={2} />

              <div className="flex-min">
                <IonButton
                  expand="block"
                  color="secondary"
                  onClick={() => {
                    const profile = {
                      first_name: first_name,
                      last_name: last_name,
                      mobile: mobile,
                      birthday: birthday,
                      is_subscribed,
                    };
                    this.props.dispatch(updateProfile(profile));
                    forwardTo(getDefaultRoute(this.props.navConfig).path, {
                      fromLogin: this.state.fromGiftVoucher,
                    });
                    dispatch({
                      type: CLEAR_REGISTER_FORM,
                    });
                  }}
                >
                  {__('Continue')}
                </IonButton>
                <IonButton
                  expand="block"
                  fill="clear"
                  color="secondary"
                  className="capitalized underlined link transparent"
                  onClick={() => {
                    forwardTo(getDefaultRoute(this.props.navConfig).path, {
                      fromLogin: this.state.fromGiftVoucher,
                    });
                    dispatch({
                      type: CLEAR_REGISTER_FORM,
                    });
                  }}
                >
                  {__('Skip & update later')}
                </IonButton>
              </div>
            </div>
          )}
        </Layout>
        <IonAlert
          isOpen={isRegisterModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
          header={__('Success')}
          message={__('Register processed. Please check your mail')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isRegisterModalOpen, registerFormData } = state.profile;
  const { navConfig } = state.common;
  return {
    auth,
    isRegisterModalOpen,
    registerFormData,
    navConfig,
  };
};

export default connect(stateToProps)(withTranslation(Register));
